import React, { useState, useEffect } from 'react';
import LabManagementSystem from './Experiment';
import { nanoid } from 'nanoid';
import { toast } from 'sonner';

const ExperimentEdit = ({
  geneData,
  codoneData,
  virtualCloneData,
  expLibraryData,
  strainEnggData,
  rows,
  selectedExperimentIndex,
  handleExperimentSelection,
  handleSaveModal,
  type,
  setType,
  handleAddRow,
  handleRemoveRow,
  handleInputChange,
  handleSelectedExperiment,
  handleAddMore,
  handleRemoveExpItem,
  handleDataChange,
  handleRemoveExperiment,
  setSelectedExperimentIndex,
  handleSetData,
  handleSave,
  handleSearch,
  geneOptionsEdit,
  handleSearchProtein

}) => {
  // Add state for selected experiment


  // Initialize data when experiment changes


  return (
    <LabManagementSystem
      rows={rows}
      selectedExperimentIndex={selectedExperimentIndex}
      handleExperimentSelection={handleExperimentSelection}
      geneData={geneData}
      codoneData={codoneData}
      virtualCloneData={virtualCloneData}
      expLibraryData={expLibraryData}
      strainEnggData={strainEnggData}
      handleSaveModal={handleSaveModal}
      type={type}
      setType={setType}
      handleAddRow={handleAddRow}
      handleRemoveExperiment={handleRemoveExperiment}
      handleInputChange={handleInputChange}
      handleSelectedExperiment={handleSelectedExperiment}
      handleAddMore={handleAddMore}
      handleRemoveExpItem={handleRemoveExpItem}
      handleDataChange={handleDataChange}
      setSelectedExperimentIndex={setSelectedExperimentIndex}
      handleSetData={handleSetData}
      handleSave={handleSave}
      isEdit={true}
      handleSearch={handleSearch}
      geneOptionsEdit={geneOptionsEdit}
      experiment={rows[selectedExperimentIndex]}
      handleSearchProtein={handleSearchProtein}
    // ... other props
    />
  );
};

export default ExperimentEdit;