import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Tabs, Tab, InputGroup, Card, Modal, Badge, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CalendarCheck2, CalendarCheck2Icon, CalendarCog, CheckCircle, ClipboardCheck, Dna, DraftingCompass, Dumbbell, Eye, FileSpreadsheet, GraduationCap, Save, Search, TrainTrack, Users, X } from 'lucide-react';
import tools from '../../assets/data/tools';
// import { Popup } from './popup';
import {
  ChevronRight,
  BookOpen,
  FlaskConical,
  Code,
  GitBranch,
  Database,
  FileText,
  Upload,
  Calendar,
  AlertTriangle,
  Image,
  Check,
  Plus,
  Trash2,
  Building2,
  CalendarCheck,
  Settings,
  BarChart2,
  CirclePercent,
  FileCode,
  GitMerge,
  Box,
  TextQuote,
  Ruler,
  Scissors,
} from 'lucide-react';
import axios from 'axios';
import AppConfig from '../../AppConfig';
import { isItemMatching } from '../../utils/nesting';


const tabStyles = {
  '--bs-nav-pills-link-active-bg': '#7FB541',
  '--bs-nav-pills-link-active-color': 'white',
  '--bs-nav-link-color': '#7FB541',
  '--bs-nav-pills-link-hover-bg': 'rgba(127, 181, 65, 0.1)',
  '--bs-link-hover-color': '#7FB541'
}

const primaryColor = "#7FB541";

const styles = {
  breadcrumb: {
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    padding: "10px 15px",
    background: "#fff"
  },
  breadcrumbItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
    color: "#555",
    cursor: "pointer"
  },
  activeItem: {
    color: primaryColor,
    fontWeight: 600
  },
  addButton: {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
    boxShadow: "0 2px 4px rgba(127, 181, 65, 0.3)",
    transition: "all 0.2s ease"
  },
  tableHeader: {
    backgroundColor: "#f8f9fa",
    // display: "flex",
    fontWeight: 600,
    fontSize: "12px", // Increased font size
    color: "#444",
    padding: "12px 8px",// Increased padding
    whiteSpace: 'nowrap',
  },
  tableCell: {
    // display: "flex",
    // flex-direction: "column",
    minWidth: "100px", // Set a minimum width for all columns
    // maxWidth: "150px",
    padding: "8px", // Increased padding
    fontSize: "12px" // Increased font size
  },
  actionButton: {
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    transition: "all 0.2s ease",
    fontSize: "13px"
  }
};

const buttonStyle = { backgroundColor: '#7FB541', border: "none" }
const addButtonStyle = {
  backgroundColor: "white",
  color: "#7FB541",
  border: "1px solid #7FB541",
  transition: "all 0.3s ease", // Add smooth transition
  ':hover': {
    backgroundColor: "#7FB541",
    color: "white"
  }
}

const LabManagementSystem = ({
  rows,
  handleAddRow,
  handleRemoveExperiment,
  handleInputChange,
  handleSelectedExperiment,
  geneData,
  codoneData,
  virtualCloneData,
  experiment,
  expLibraryData,
  strainEnggData,
  growthConditionData,
  handleSaveModal,
  handleDataChange,
  handleCloseModal,
  type,
  setType,
  handleAddMore,
  handleRemoveExpItem,
  setSelectedExperimentIndex,
  isEdit,
  handleSetData,
  handleSave,
  handleSearch,
  handleSearchProtein
}) => {
  const [activeTab, setActiveTab] = useState('experiments');
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedData, setSelectedData] = useState({})

  // const { rows, handleAddRow, handleDeleteRow } = useProductsNew()

  const handleSelectedData = (item) => {
    setSelectedData(item);
  }



  console.log(type, 'TYPEE')
  const [previewModal, setPreviewModal] = useState({ show: false, fileUrl: null });

  // const 

  console.log(rows, 'TheOws')



  return (
    <Container fluid className="px-2">
      {/* <WorkflowIndicator currentStep={activeTab} /> */}
      <Card className="border-0 shadow mb-4" style={{ maxWidth: '100%', margin: '0 auto' }}>
        <Card.Body className="p-0">
          {type === "" && <ExperimentTab
            setSelectedExperimentIndex={setSelectedExperimentIndex}
            rows={rows}
            handleAddRow={handleAddRow}
            handleDeleteRow={handleRemoveExperiment}
            handleInputChange={handleInputChange}
            handleSelectedExperiment={handleSelectedExperiment}
            type={type}
            setType={setType}
            handleSave={handleSave}
            handleSetData={handleSetData}
            isEdit={isEdit}
            handleSearchProtein={handleSearchProtein}
          />}
          {type === "genes" && <GeneTab
            setActiveTab={setActiveTab}
            geneData={geneData}
            handleDataChange={handleDataChange}
            handleSave={handleSave}
            handleAddMore={handleAddMore}
            setType={setType}
            handleRemoveExpItem={handleRemoveExpItem}
            experiment={experiment}
            handleSearch={handleSearch}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
          />}
          {type === "codone" && <CodonOptimizationTab
            setActiveTab={setActiveTab}
            codoneData={codoneData}
            handleDataChange={handleDataChange}
            handleSave={handleSave}
            setType={setType}
            handleAddMore={handleAddMore}
            handleRemoveExpItem={handleRemoveExpItem}
            geneData={geneData}
            isEdit={isEdit}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
          />}
          {type === "virtualCodeConstruct" && <VirtualCloneConstructTab
            setActiveTab={setActiveTab}
            setType={setType}
            handleSave={handleSave}
            virtualCloneData={virtualCloneData}
            handleDataChange={handleDataChange}
            handleAddMore={handleAddMore}
            handleRemoveExpItem={handleRemoveExpItem}
            codoneData={codoneData}
            previewModal={previewModal}
            setPreviewModal={setPreviewModal}
            isEdit={isEdit}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
          />}
          {type === "expLibrary" && <LibraryOfPlasmidTab
            handleSave={handleSave}
            setType={setType}
            setActiveTab={setActiveTab}
            setModalShow={setModalShow}
            modalShow={modalShow}
            handleDataChange={handleDataChange}
            handleAddMore={handleAddMore}
            handleRemoveExpItem={handleRemoveExpItem}
            expLibraryData={expLibraryData}
            virtualCloneData={virtualCloneData}
            setPreviewModal={setPreviewModal}
            previewModal={previewModal}
            isEdit={isEdit}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
          />}
          {type === "strainEngg" && <StrainEngineeringTab
            setActiveTab={setActiveTab}
            modalShow={modalShow}
            setModalShow={setModalShow}
            setType={setType}
            handleSave={handleSave}
            handleDataChange={handleDataChange}
            handleAddMore={handleAddMore}
            handleRemoveExpItem={handleRemoveExpItem}
            expLibraryData={expLibraryData}
            strainEnggData={strainEnggData}
            previewModal={previewModal}
            setPreviewModal={setPreviewModal}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
          />}

          {type === "growthCondition" && <GrowthConditionTab setActiveTab={setActiveTab} />}
        </Card.Body>
      </Card>
    </Container>
  );
};

// Workflow progress indicator
const WorkflowIndicator = ({ currentStep }) => {
  const steps = [
    { id: 'experiments', name: 'Experiments' },
    { id: 'genes', name: 'Genes' },
    { id: 'codon', name: 'Codon Optimization' },
    { id: 'clone', name: 'Virtual Clone' },
    { id: 'library', name: 'Library of Plasmid' },
    { id: 'strain', name: 'Strain Engineering' },
    { id: 'growth', name: 'Growth Condition' }
  ];

  const currentIndex = steps.findIndex(step => step.id === currentStep);

  return (
    <Card className="shadow-sm mb-4">
      <Card.Body>
        <h6 className="mb-3">Workflow Progress</h6>
        <div className="d-flex justify-content-between">
          {steps.map((step, index) => (
            <div key={step.id} className="text-center position-relative">
              <div className={`rounded-circle d-flex align-items-center justify-content-center mx-auto mb-2 ${index <= currentIndex ? 'bg-primary text-white' : 'bg-light'}`} style={{ width: '40px', height: '40px' }}>
                {index + 1}
              </div>
              <small className={index <= currentIndex ? 'text-primary' : 'text-muted'}>
                {step.name}
              </small>
              {index < steps.length - 1 && (
                <div className="position-absolute" style={{ top: '20px', right: '-50%', width: '100%', height: '2px', backgroundColor: index < currentIndex ? '#0d6efd' : '#dee2e6', zIndex: -1 }} />
              )}
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

// 1. Experiment Tab
const ExperimentTab = ({ setSelectedExperimentIndex,
  type, rows, handleAddRow,
  handleDeleteRow, handleInputChange, handleSelectedExperiment,
  setType, isEdit, handleSetData, handleSearch, handleSearchProtein }) => {
  const stages = [
    { value: 'development', label: 'Development' },
    { value: 'testing', label: 'Testing' },
    { value: 'production', label: 'Production' }
  ];

  const styles2 = {
    tableHeader: {
      backgroundColor: '#f8f9fa',
      fontWeight: 600,
      fontSize: '14px',
      color: '#444',
      padding: '12px'
    },
    tableCell: {
      padding: '12px',
      fontSize: '14px',
      minWidth: '150px'
    },
    button: {
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      transition: 'all 0.2s ease',
      fontSize: '13px'
    }
  };

  console.log(rows, 'REOWS')

  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col md={8}>
          <div style={styles.breadcrumb} className="d-flex align-items-center" >
            <div style={{ ...styles.breadcrumbItem, ...styles.activeItem }} className="me-2 cursor-pointer" onClick={() => {
              setType("");
            }}>
              <FileText size={16} className="me-1" /> Experiment
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
          </div>
        </Col>
        <Col md={4} className="text-end">
          <Button style={buttonStyle} variant="primary" onClick={handleAddRow}>
            <div className="flex gap-2">
              <Plus />Add Experiment
            </div>
          </Button>
        </Col>
      </Row>

      <Table responsive hover bordered>
        <thead className="bg-light">
          <tr>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <FileText size={16} className="me-2" />
                Experiment Name
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Users size={16} className="me-2" />
                Common Name
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <GraduationCap size={16} className="me-2" />
                Scientific Name
              </div>
            </th>
            {/* <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Dna size={16} className="me-2" />
                Gene
              </div>
            </th> */}
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Dna size={16} className="me-2" />
                Protein
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <GitBranch size={16} className="me-2" />
                Stage
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <ClipboardCheck size={16} className="me-2" />
                Exp Details
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Dumbbell size={16} className="me-2" />
                Gene
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Trash2 size={16} className="me-2" />
                Action
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((exp, index) => (
            <>
              {!exp.is_deleted && <tr key={index}>
                <td style={styles.tableCell}>
                  <Form.Control
                    name="experimentName"
                    value={exp.experimentName}
                    onChange={(e) => handleInputChange(exp.id, e)}
                    placeholder="Enter name"
                  />
                </td>
                <td style={styles.tableCell}>
                  <Form.Control
                    name="commonName"
                    value={exp.commonName}
                    onChange={(e) => handleInputChange(exp.id, e)}
                    placeholder="Enter common name"
                  />
                </td>
                <td style={styles.tableCell}>
                  <Form.Control
                    name="scientificName"
                    value={exp.scientificName}
                    onChange={(e) => handleInputChange(exp.id, e)}
                    placeholder="Enter Scientific Name"
                  />
                </td>

                <td style={styles.tableCell}>
                  <div className="flex gap-2 items-center">
                    <Form.Control
                      type="number"
                      name="protein"
                      value={exp.protien}
                      onChange={(e) => handleInputChange(exp.id, e)}
                      placeholder="Enter Protien sequence"
                    />
                    {/* <div className="bg-green-500 p-1.5 rounded-md cursor-pointer" onClick={() => handleSearchProtein(exp.id)}>
                      <Search className='text-white' size={14} />
                    </div> */}
                  </div>
                </td>
                <td style={styles.tableCell}>
                  <Form.Select
                    aria-label="Default select example"
                    name="exp_stage"
                    value={exp.exp_stage}
                    onChange={(e) => handleInputChange(exp.id, e)}
                  >
                    {stages.map((stg) => (
                      <option key={stg.value} value={stg.value}>
                        {stg.label}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td style={styles.tableCell}>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => handleSelectedExperiment(index)}
                    style={styles.button}
                  >
                    Add More
                  </Button>
                </td>
                <td style={styles.tableCell}>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => {
                      if (isEdit) {
                        setSelectedExperimentIndex(index)
                      }
                      handleSetData(index)
                      setType('genes')
                    }}
                    style={styles.button}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <Dna size={16} />
                      Add
                    </div>
                  </Button>
                </td>
                <td style={styles.tableCell}>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => handleDeleteRow(exp.id)}
                    style={styles.button}
                  >
                    <Trash2 size={16} />
                  </Button>
                </td>
              </tr>}
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

// 2. Gene Tab
const GeneTab = ({ setActiveTab, geneData,
  handleDataChange, handleSave, handleAddMore, handleRemoveExpItem, setType, experiment, handleSearch, selectedData,
  handleSelectedData }) => {

  console.log(geneData, 'GeneDataNew')
  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col md={8}>
          <div style={styles.breadcrumb} className="d-flex align-items-center">
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("")}>
              <FileText size={16} className="me-1" /> Experiment
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={{ ...styles.breadcrumbItem, ...styles.activeItem }} className="me-2" onClick={() => setType("genes")}>
              <Code size={16} className="me-1" /> Gene
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
          </div>
        </Col>
        <Col md={4} className="text-end">
          <Button style={buttonStyle} variant="primary" onClick={() => handleAddMore(experiment.id ? experiment.id : experiment.mapId)}>
            <div className="flex gap-2">
              <Plus />Add Gene
            </div>
          </Button>
        </Col>
      </Row>

      <Table responsive hover bordered>
        <thead className="bg-light">
          <tr>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Dna size={16} className="me-2" />
                Search By Gene Id
              </div>
            </th>

            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Dna size={16} className="me-2" />
                Gene Sequence
              </div>
            </th>

            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Building2 size={16} className="me-2" />
                Company
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Calendar size={16} className="me-2" />
                Order Date
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <CalendarCheck size={16} className="me-2" />
                Receipt Date
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <FlaskConical size={16} className="me-2" />
                Codon Optimization
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Settings size={16} className="me-2" />
                Action
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            geneData?.map((gene, index) => (
              <tr key={index}>
                <td style={styles.tableCell}>
                  <div className="flex gap-2 items-center">
                    <Form.Control
                      type="number"
                      name="gene"
                      // value={exp.gene}
                      value={gene?.gene}
                      // onChange={(e) => handleInputChange(exp.id, e)}
                      onChange={(e) => handleDataChange(e, false, index)}
                      placeholder="Enter Gene Id"
                    />
                    <div className="bg-green-500 p-1.5 rounded-md cursor-pointer" onClick={() => handleSearch(experiment.id, gene?.gene)}>
                      <Search className='text-white' size={14} />
                    </div>
                  </div>
                </td>
                {gene.gene_sequence ? (
                  <td style={styles.tableCell}>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder='Gene Sequence'
                      value={gene.gene_sequence}
                      readOnly // Make it read-only if you don't want direct editing
                    />
                  </td>
                ) : <td style={styles.tableCell}>
                  &nbsp;
                </td>}
                <td><Form.Control size="sm" type="text" placeholder='Enter Company' name='company'
                  value={gene.company}
                  onChange={(e) => handleDataChange(e, false, index)} /></td>
                <td>
                  <Form.Control size="sm" type="date" name='orderDate'
                    value={gene.orderDate}
                    onChange={(e) => handleDataChange(e, false, index)} />
                </td>
                <td>
                  <Form.Control size="sm" type="date" name='receiptDate'
                    value={gene.receiptDate}
                    onChange={(e) => handleDataChange(e, false, index)} />
                </td>
                <td>
                  <Button size="sm" variant="outline-success" onClick={() => {
                    handleSelectedData(gene);
                    handleSave()
                    setActiveTab('codone');
                    setType('codone')

                  }}>Save & Add Codone</Button>
                </td>
                <td><div className="flex gap-2">
                  <Button size="sm" variant="outline-success" onClick={handleSave}>Save</Button>
                  <Button size="sm" variant="outline-danger" onClick={() => handleRemoveExpItem(index)}>Delete</Button>
                </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
};


// 3. Codon Optimization Tab
const CodonOptimizationTab = ({ setActiveTab, codoneData, geneData,
  handleDataChange,
  handleSave,
  setType,
  handleAddMore,
  handleRemoveExpItem,
  isEdit,
  selectedData,
  handleSelectedData
}) => {

  console.log(codoneData, 'codoneDataNew', selectedData)

  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col md={8}>
          <div style={styles.breadcrumb} className="d-flex align-items-center">
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("")}>
              <FileText size={16} className="me-1" /> Experiment
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("genes")}>
              <Code size={16} className="me-1" /> Gene
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={{ ...styles.breadcrumbItem, ...styles.activeItem }} className="me-2" onClick={() => setType("codone")}>
              <FlaskConical size={16} className="me-1" /> Codone Optimize
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />

          </div>
        </Col>
        <Col md={4} className="text-end">
          <Button style={buttonStyle} variant="primary" onClick={() => handleAddMore(selectedData.id ? selectedData.id : selectedData.mapId)}>
            <div className="flex gap-2">
              <Plus />Add Codon Optimization
            </div>
          </Button>
        </Col>
      </Row>

      <Table responsive hover bordered>
        <thead className="bg-light">
          <tr>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <DraftingCompass size={16} className="me-2" />
                Parent Gene
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <DraftingCompass size={16} className="me-2" />
                Tools
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <BarChart2 size={16} className="me-2" />
                CAI Score
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <CirclePercent size={16} className="me-2" />
                GC %
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <FileCode size={16} className="me-2" />
                Optimized Sequence
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <GitMerge size={16} className="me-2" />
                Clone
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Settings size={16} className="me-2" />
                Action
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            codoneData.map((codone, index) => {
              const isMatchingGene = isItemMatching(
                selectedData,
                codone,
                ['id', 'geneId', 'mapId'],
                'geneId'
              );
              return <>
                {isMatchingGene && <tr key={index}>
                  <td>
                    <Form.Select size="sm" name='geneId'
                      value={codone.geneId}
                      onChange={(e) => handleDataChange(e, false, index)}>
                      <option>Select Parent</option>
                      {geneData.map((gene) => (
                        <option value={isEdit ? gene.id : gene.mapId}>{gene.company}</option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select size="sm" name="tool"
                      value={codone.tool}
                      onChange={(e) => handleDataChange(e, false, index)}>
                      <option>Select Tool</option>
                      {tools.map((tool) => (
                        <option value={tool.value}>{tool.label}</option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Control size="sm" type="number" name="caiScore"
                      value={codone.caiScore}
                      onChange={(e) => handleDataChange(e, false, index)} placeholder='Enter CAI Score' />
                  </td>
                  <td><Form.Control size="sm" type="number" name="gcPercentage"
                    value={codone.gcPercentage}
                    onChange={(e) => handleDataChange(e, false, index)} placeholder='Enter GC %' /></td>
                  <td>
                    <Form.Control size="sm" type="text" name='optimizedSequence'
                      value={codone.optimizedSequence}
                      onChange={(e) => handleDataChange(e, false, index)} placeholder='Paste Optimized sequence' />
                  </td>
                  <td>
                    <Button size="sm" variant="outline-success" onClick={() => {
                      handleSelectedData(codone)
                      handleSave()
                      setActiveTab('clone');
                      setType("virtualCodeConstruct")
                    }}>Save & Add Clone</Button>
                  </td>
                  <td>
                    <div className="flex gap-2">
                      <Button size="sm" variant="outline-success" onClick={handleSave}>Save</Button>
                      <Button size="sm" variant="outline-danger" onClick={() => handleRemoveExpItem(index)}>Delete</Button>
                    </div>

                  </td>
                </tr>}
              </>
            })
          }
        </tbody>
      </Table>
    </div>
  );
};

// 4. Virtual Clone Construct Tab
const VirtualCloneConstructTab = ({ setType, handleSave, virtualCloneData,
  handleDataChange,
  handleAddMore,
  handleRemoveExpItem, codoneData, previewModal,
  setPreviewModal, isEdit, selectedData,
  handleSelectedData }) => {

  console.log(virtualCloneData, 'vrtualClonDta', selectedData)

  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col md={8}>
          <div style={styles.breadcrumb} className="d-flex align-items-center">
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("")}>
              <FileText size={16} className="me-1" /> Experiment
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("genes")}>
              <Code size={16} className="me-1" /> Gene
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("codone")}>
              <FlaskConical size={16} className="me-1" /> Codone Optimize
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={{ ...styles.breadcrumbItem, ...styles.activeItem }} className="me-2" onClick={() => setType("virtualCodeConstruct")}>
              <GitBranch size={16} className="me-1" /> Virtual Clone
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
          </div>
        </Col>
        <Col md={4} className="text-end">
          <Button style={buttonStyle} variant="primary" onClick={() => handleAddMore(selectedData.id ? selectedData.id : selectedData.mapId)}>
            <div className="flex gap-2">
              <Plus />
              Add Clone Construct
            </div>
          </Button>
        </Col>
      </Row>

      <Table responsive hover bordered>
        <thead className="bg-light">
          <tr>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Box size={16} className="me-2" />
                Parent Codone
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Box size={16} className="me-2" />
                Vector Type
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <TextQuote size={16} className="me-2" />
                Vector Name
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Upload size={16} className="me-2" />
                Image Construct Upload
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Ruler size={16} className="me-2" />
                Clone Size
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Scissors size={16} className="me-2" />
                Clone Sites
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Database size={16} className="me-2" />
                Library of Plasmid
              </div>
            </th>
            <th style={styles.tableHeader}>
              <div className="d-flex align-items-center">
                <Settings size={16} className="me-2" />
                Action
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            virtualCloneData.map((clone, index) => {

              const isMatchingCodone = isItemMatching(
                selectedData,
                clone,
                ['id', 'codoneId', 'mapId'],
                'codoneId'
              );

              return <>
                {
                  isMatchingCodone && <tr key={index}>
                    <td>
                      <Form.Select size="sm" name='codoneId'
                        onChange={(e) => handleDataChange(e, false, index)} value={clone.codoneId || ''}>
                        <option>Select Parent</option>
                        {codoneData.map((codone) => (
                          <option value={isEdit ? codone.id : codone.mapId}>{codone.caiScore}</option>
                        ))}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select size="sm" name="vectorType"
                        value={clone.vectorType}
                        onChange={(e) => handleDataChange(e, false, index)}>
                        <option>Select Type</option>
                        <option>Type 1</option>
                        <option>Type 2</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control type='text' placeholder='Enter' name='vectorName'
                        value={clone.vectorName}
                        onChange={(e) => handleDataChange(e, false, index)}></Form.Control>

                    </td>
                    <td style={styles.tableCell}>
                      {clone.img_construct_upload_url ? (
                        <div className="d-flex gap-2">
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setPreviewModal({ show: true, fileUrl: clone.img_construct_upload_url })}
                            className="d-flex align-items-center"
                          >
                            <Eye size={20} className="me-1" /> view
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => {
                              handleDataChange({
                                target: {
                                  name: 'img_construct_upload_url',
                                  value: null
                                }
                              }, false, index);
                            }}
                          >
                            Change
                          </Button>
                        </div>
                      ) : (
                        <div className="position-relative">
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-flex align-items-center w-100 border-0 shadow-none bg-light text-start"
                          >
                            <Upload size={14} className="me-1" /> Choose PDF
                          </Button>
                          <Form.Control
                            type="file"
                            accept=".pdf"
                            className="position-absolute top-0 start-0 opacity-0 w-100 h-100"
                            onChange={async (e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const formData = new FormData();
                                formData.append('file', file);

                                try {
                                  const response = await axios.post(`${AppConfig.LAB_API_URL}/construct-pdf/upload`, formData, {
                                    headers: {
                                      'Content-Type': 'multipart/form-data'
                                    }
                                  });

                                  handleDataChange({
                                    target: {
                                      name: 'img_construct_upload_url',
                                      value: response.data.files[0].filename
                                    }
                                  }, false, index);
                                } catch (error) {
                                  console.error('Error uploading file:', error);
                                  // Handle error - show toast/alert
                                }
                              }
                            }}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <Form.Control type='text' placeholder='Enter Clone Size' name='cloneSize'
                        value={clone.cloneSize}
                        onChange={(e) => handleDataChange(e, false, index)}></Form.Control>
                    </td>
                    <td>
                      <Form.Control type='text' placeholder='Enter Clone Sites' name='cloneSites'
                        value={clone.cloneSites}
                        onChange={(e) => handleDataChange(e, false, index)}></Form.Control>
                    </td>
                    <td>
                      <Button size="sm" variant="outline-success" onClick={() => {
                        handleSelectedData(clone)
                        handleSave();
                        setType("expLibrary")
                      }}>Save & Next</Button>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <Button size="sm" variant="outline-success">Save</Button>
                        <Button size="sm" variant="outline-danger" onClick={() => handleRemoveExpItem(index)}>Delete</Button>
                      </div>
                    </td>
                  </tr>
                }
              </>
            })
          }
        </tbody>
      </Table>
      <PDFPreviewModal
        show={previewModal.show}
        handleClose={() => setPreviewModal({ show: false, fileUrl: null })}
        fileUrl={previewModal.fileUrl}
        folderName="image-constructs"
      />
    </div>
  );
};

// 5. Library of Plasmid Tab
const LibraryOfPlasmidTab = ({ setModalShow, modalShow, setType, handleSave, handleDataChange,
  handleAddMore,
  handleRemoveExpItem,
  expLibraryData,
  virtualCloneData, setPreviewModal, previewModal, isEdit, selectedData,
  handleSelectedData }) => {


  const [rowIndex, setRowIndex] = useState();
  const [currentElement, setCurrentElement] = useState();

  console.log(expLibraryData, 'expLibData', selectedData)


  return (
    <div className="py-4 px-3">
      <Row className="mb-4">
        <Col md={8}>
          <div style={styles.breadcrumb} className="d-flex align-items-center">
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("")}>
              <FileText size={16} className="me-1" /> Experiment
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("genes")}>
              <Code size={16} className="me-1" /> Gene
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("codone")}>
              <FlaskConical size={16} className="me-1" /> Codone Optimize
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("virtualCodeConstruct")}>
              <GitBranch size={16} className="me-1" /> Virtual Clone
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={{ ...styles.breadcrumbItem, ...styles.activeItem }} className="me-2" onClick={() => setType("expLibrary")}>
              <Database size={16} className="me-1" /> Library of Plasmid
            </div>
          </div>
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <Button
            variant="primary"
            style={styles.addButton}
            className="d-flex align-items-center"
            onClick={() => handleAddMore(selectedData.id ? selectedData.id : selectedData.mapId)}
          >
            <Plus size={18} className="me-1" /> Add Library of Plasmid
          </Button>
        </Col>
      </Row>

      <div className="border rounded shadow-sm bg-white overflow-hidden">
        <Table responsive hover className="mb-0">
          <thead>
            <tr style={styles.tableHeader}>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <BookOpen size={16} className="me-2" />Parent Clone
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <BookOpen size={16} className="me-2" />Library
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <FileText size={16} className="me-2" />Trial No.
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Upload size={16} className="me-2" />SOP
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <AlertTriangle size={16} className="me-2" />Any Deviation
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Image size={16} className="me-2" />Result Images
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Calendar size={16} className="me-2" />Date of Completion
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Check size={16} className="me-2" />Clone Confirmation
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <FlaskConical size={16} className="me-2" />Strain
                </div>
              </th>
              <th style={styles.tableCell}>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              expLibraryData.map((expLib, index) => {

                const isMatchingVirtualClone = isItemMatching(
                  selectedData,
                  expLib,
                  ['id', 'cloneConstructId', 'mapId'],
                  'cloneConstructId'
                );

                return <>
                  {
                    isMatchingVirtualClone && <tr key={index}>
                      <td>
                        <Form.Select size="sm" name='cloneConstructId'
                          onChange={(e) => handleDataChange(e, false, index)}
                          value={expLib.cloneConstructId || ''}>
                          <option>Select Parent</option>
                          {virtualCloneData.map((clone) => (
                            <option value={clone.id ? clone.id : clone.mapId}>{clone.cloneSize}</option>
                          ))}
                        </Form.Select>
                      </td>
                      <td style={styles.tableCell}>
                        <Form.Select size="sm" className="border-0 shadow-none bg-light" name='expLibaryType'
                          value={expLib.expType}
                          onChange={(e) => handleDataChange(e, false, index)}>
                          <option>Select Library</option>
                          <option>Plasmid</option>
                          <option>Expression</option>
                        </Form.Select>
                      </td>
                      <td style={styles.tableCell}>
                        <Form.Control size="sm" placeholder='Enter' name='trialNumber'
                          value={expLib.trialNumber}
                          onChange={(e) => handleDataChange(e, false, index)}
                          type="number" className="border-0 shadow-none bg-light" />
                      </td>
                      <td style={styles.tableCell}>
                        {expLib.sopUrl ? (
                          <div className="d-flex gap-2">
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => setPreviewModal({ show: true, fileUrl: expLib.sopUrl })}
                              className="d-flex align-items-center"
                            >
                              <Eye size={14} className="me-1" /> view
                            </Button>
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              onClick={() => {
                                handleDataChange({
                                  target: {
                                    name: 'sopUrl',
                                    value: null
                                  }
                                }, false, index);
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className="d-flex align-items-center w-100 border-0 shadow-none bg-light text-start"
                            >
                              <Upload size={14} className="me-1" /> Choose PDF
                            </Button>
                            <Form.Control
                              type="file"
                              accept=".pdf"
                              className="position-absolute top-0 start-0 opacity-0 w-100 h-100"
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const formData = new FormData();
                                  formData.append('file', file);

                                  try {
                                    const response = await axios.post(`${AppConfig.LAB_API_URL}/plasmid-sop-pdf/upload`, formData, {
                                      headers: {
                                        'Content-Type': 'multipart/form-data'
                                      }
                                    });

                                    handleDataChange({
                                      target: {
                                        name: 'sopUrl',
                                        value: response.data.files[0].filename
                                      }
                                    }, false, index);
                                  } catch (error) {
                                    console.error('Error uploading file:', error);
                                    // Handle error - show toast/alert
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                      </td>
                      <td style={styles.tableCell}>
                        <Form.Check
                          type="switch"
                          id={`deviation-switch-${index}`}
                          label={<span className="ms-1 small">Yes</span>}
                          className="mb-2"
                          name="anyDeviation"
                          checked={expLib.anyDeviation}
                          onChange={(e) => handleDataChange({
                            target: {
                              name: 'anyDeviation',
                              value: e.target.checked
                            }
                          }, false, index)}
                        />
                        {expLib.anyDeviation && (
                          <Form.Control
                            size="sm"
                            type="text"
                            name="deviationDetails"
                            placeholder="Deviation details..."
                            className="border-0 shadow-none bg-light"
                            onChange={(e) => handleDataChange(e, false, index)}
                            value={expLib.deviationDetails || ''}
                          />)}
                      </td>
                      <td style={styles.tableCell}>
                        {expLib.resultImages?.length > 0 ? (
                          <div className="d-flex gap-2">
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => setPreviewModal({
                                show: true,
                                type: 'images',
                                files: expLib.resultImages
                              })}
                              className="d-flex align-items-center"
                            >
                              <Eye size={14} className="me-1" /> view
                            </Button>
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              onClick={() => {
                                handleDataChange({
                                  target: {
                                    name: 'resultImages',
                                    value: null
                                  }
                                }, false, index);
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className="d-flex align-items-center w-100 border-0 shadow-none bg-light text-start"
                            >
                              <Image size={14} className="me-1" /> Choose Images
                            </Button>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              multiple
                              className="position-absolute top-0 start-0 opacity-0 w-100 h-100"
                              onChange={async (e) => {
                                const files = Array.from(e.target.files);
                                if (files.length > 0) {
                                  const formData = new FormData();
                                  files.forEach(file => {
                                    formData.append('resultImages', file);
                                  });

                                  try {
                                    const response = await axios.post(
                                      `${AppConfig.LAB_API_URL}/plasmid-result-images/upload`,
                                      formData,
                                      {
                                        headers: {
                                          'Content-Type': 'multipart/form-data'
                                        }
                                      }
                                    );

                                    handleDataChange({
                                      target: {
                                        name: 'resultImages',
                                        value: response?.data?.files?.map(f => f.filename)
                                      }
                                    }, false, index);
                                  } catch (error) {
                                    console.error('Error uploading images:', error);
                                    // Handle error - show toast/alert
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                      </td>
                      <td style={styles.tableCell}>
                        <InputGroup size="sm">
                          <InputGroup.Text className="bg-light border-0 border-end">
                            <Calendar size={14} />
                          </InputGroup.Text>
                          <Form.Control type="date" className="border-0 shadow-none bg-light"
                            value={expLib.date_of_completion}
                            name='date_of_completion'
                            onChange={(e) => handleDataChange(e, false, index)} />
                        </InputGroup>
                      </td>
                      <td style={styles.tableCell}>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => {
                            setRowIndex(index)
                            setCurrentElement(expLib)
                            setModalShow(true)
                          }}
                          style={styles.actionButton}
                          className="d-flex align-items-center mx-auto"
                        >
                          <Plus size={14} className="me-1" /> Add Details
                        </Button>
                      </td>
                      <td style={styles.tableCell}>
                        <Button
                          size="sm"
                          variant="outline-success"
                          onClick={() => {
                            handleSelectedData(expLib)
                            handleSave()
                            setType("strainEngg")
                          }}
                          style={{ ...styles.actionButton, borderColor: primaryColor, color: primaryColor }}
                          className="d-flex align-items-center mx-auto"
                        >
                          <Plus size={14} className="me-1" /> Add
                        </Button>
                      </td>
                      <td style={styles.tableCell}>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          style={styles.actionButton}
                          className="d-flex align-items-center mx-auto"
                          onClick={() => handleRemoveExpItem(index)}
                        >
                          <Trash2 size={14} className="me-1" /> Delete
                        </Button>
                      </td>
                    </tr>
                  }
                </>
              })
            }
          </tbody>
        </Table>
      </div>
      <Popup show={modalShow} onHide={() => setModalShow(false)} previewModal={previewModal} setPreviewModal={setPreviewModal}
        handleDataChange={handleDataChange} index={rowIndex} currentElement={currentElement}
      />
      <PDFPreviewModal
        show={previewModal.show}
        files={previewModal.files}
        type={previewModal.type}
        handleClose={() => setPreviewModal({ show: false, fileUrl: null })}
        fileUrl={previewModal.fileUrl}
        folderName="plasmids"
      />
    </div>
  );
};

// 6. Strain Engineering Tab
const StrainEngineeringTab = ({ modalShow,
  setModalShow, setType, handleDataChange,
  handleAddMore,
  handleRemoveExpItem,
  expLibraryData,
  handleSave,
  strainEnggData, previewModal, setPreviewModal, selectedData,
  handleSelectedData }) => {

  const [rowIndex, setRowIndex] = useState();
  const [currentElement, setCurrentElement] = useState();

  console.log(strainEnggData, 'strainEngg')

  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col md={12}>
          <div style={styles.breadcrumb} className="d-flex align-items-center">
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("")}>
              <FileText size={16} className="me-1" /> Experiment
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("genes")}>
              <Code size={16} className="me-1" /> Gene
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("codone")}>
              <FlaskConical size={16} className="me-1" /> Codone Optimize
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("virtualCodeConstruct")}>
              <GitBranch size={16} className="me-1" /> Virtual Clone
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={styles.breadcrumbItem} className="me-2" onClick={() => setType("expLibrary")}>
              <Database size={16} className="me-1" /> Library of Plasmid
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
            <div style={{ ...styles.breadcrumbItem, ...styles.activeItem }} className="me-2" onClick={() => setType("strainEngg")}>
              <TrainTrack size={16} className="me-1" /> Strain
            </div>
            <ChevronRight size={16} className="text-muted mx-1" />
          </div>
        </Col>
        <Col md={12} className="text-end justify-end">
          <Button style={buttonStyle} variant="primary" onClick={() => handleAddMore(selectedData.id ? selectedData.id : selectedData.mapId)}>
            <div className="flex gap-3">
              <Plus />
              Add Strain Engineering
            </div>
          </Button>
        </Col>
      </Row>

      <div className="border rounded shadow-sm bg-white overflow-hidden">
        <Table responsive hover className="mb-0">
          <thead>
            <tr style={styles.tableHeader}>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <FileText size={16} className="me-2" />Parent Plasmid
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <FileText size={16} className="me-2" />Trial No.
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <FileSpreadsheet size={16} className='me-2' />Plasmid Details
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Upload size={16} className="me-2" />SOP
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <AlertTriangle size={16} className="me-2" />Any Deviation
                </div>
              </th>
              {/* <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Image size={16} className="me-2" />Result Images
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <CalendarCog size={16} className="me-2" />Start Date
                </div>
              </th>
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <CalendarCheck2Icon size={16} className="me-2" />Date of Completion
                </div>
              </th> */}
              <th style={styles.tableCell}>
                <div className="flex items-center">
                  <Check size={16} className="me-2" />Clone Confirmation
                </div>
              </th>
              {/* <th style={styles.tableCell}>
                <div className="flex items-center">
                  <FlaskConical size={16} className="me-2" />Strain Engineering
                </div>
              </th> */}
              <th style={styles.tableCell}>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              strainEnggData.map((strain, index) => {

                const isMatchingExpLib = isItemMatching(
                  selectedData,
                  strain,
                  ['id', 'expLibId', 'mapId'],
                  'expLibId'
                );

                return <>
                  {
                    isMatchingExpLib && <tr key={index}>
                      <td style={styles.tableCell}>
                        <Form.Select size="sm" name='expLibId'
                          onChange={(e) => handleDataChange(e, false, index)} value={strain.expLibId || ''}>
                          <option>Select Parent</option>
                          {expLibraryData.map((expLib) => (
                            <option value={expLib.id ? expLib.id : expLib.mapId}>{expLib.trialNumber}</option>
                          ))}
                        </Form.Select>
                      </td>
                      <td style={styles.tableCell}>
                        <Form.Control size="sm" placeholder='Enter' type="number" name='trialNumber'
                          value={strain.trialNumber}
                          onChange={(e) => handleDataChange(e, false, index)}
                          className="border-0 shadow-none bg-light" />
                      </td>
                      <td style={styles.tableCell}>
                        <Form.Control size="sm" placeholder='Enter' type="text" name='plasmidName'
                          value={strain.plasmidName}
                          onChange={(e) => handleDataChange(e, false, index)}
                          className="border-0 shadow-none bg-light" />
                      </td>
                      <td style={styles.tableCell}>
                        {strain.sopUrl ? (
                          <div className="d-flex gap-2">
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => setPreviewModal({ show: true, fileUrl: strain.sopUrl })}
                              className="d-flex align-items-center"
                            >
                              <Eye size={14} className="me-1" /> view
                            </Button>
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              onClick={() => {
                                handleDataChange({
                                  target: {
                                    name: 'sopUrl',
                                    value: null
                                  }
                                }, false, index);
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className="d-flex align-items-center w-100 border-0 shadow-none bg-light text-start"
                            >
                              <Upload size={14} className="me-1" /> Choose PDF
                            </Button>
                            <Form.Control
                              type="file"
                              accept=".pdf"
                              className="position-absolute top-0 start-0 opacity-0 w-100 h-100"
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const formData = new FormData();
                                  formData.append('strainSopPdf', file);

                                  try {
                                    const response = await axios.post(`${AppConfig.LAB_API_URL}/strain-sop/upload`, formData, {
                                      headers: {
                                        'Content-Type': 'multipart/form-data'
                                      }
                                    });

                                    handleDataChange({
                                      target: {
                                        name: 'sopUrl',
                                        value: response.data.files[0].filename
                                      }
                                    }, false, index);
                                  } catch (error) {
                                    console.error('Error uploading file:', error);
                                    // Handle error - show toast/alert
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                      </td>
                      <td style={styles.tableCell}>
                        <Form.Check
                          type="switch"
                          id={`deviation-switch-${index}`}
                          label={<span className="ms-1 small">Yes</span>}
                          className="mb-2"
                          name="anyDeviation"
                          checked={strain.anyDeviation}
                          onChange={(e) => handleDataChange({
                            target: {
                              name: 'anyDeviation',
                              value: e.target.checked
                            }
                          }, false, index)}
                        />
                        {strain.anyDeviation && (
                          <Form.Control
                            size="sm"
                            type="text"
                            name="deviationDetails"
                            placeholder="Deviation details..."
                            className="border-0 shadow-none bg-light"
                            onChange={(e) => handleDataChange(e, false, index)}
                            value={strain.deviationDetails || ''}
                          />)}
                      </td>
                      {/* <td style={styles.tableCell}>
                  <div className="position-relative">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="d-flex align-items-center w-100 border-0 shadow-none bg-light text-start"
                    >
                      <Image size={14} className="me-1" /> Choose file
                    </Button>
                    <Form.Control
                      type="file"
                      className="position-absolute top-0 start-0 opacity-0 w-100 h-100"
                    />
                  </div>
                </td> */}
                      {/* <td style={styles.tableCell}>
                  <InputGroup size="sm">
                    <InputGroup.Text className="bg-light border-0 border-end">
                      <Calendar size={14} />
                    </InputGroup.Text>
                    <Form.Control type="date" className="border-0 shadow-none bg-light" />
                  </InputGroup>
                </td>
                <td style={styles.tableCell}>
                  <InputGroup size="sm">
                    <InputGroup.Text className="bg-light border-0 border-end">
                      <Calendar size={14} />
                    </InputGroup.Text>
                    <Form.Control type="date" className="border-0 shadow-none bg-light" />
                  </InputGroup>
                </td>*/}
                      <td style={styles.tableCell}>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => {
                            setCurrentElement(strain);
                            setRowIndex(index)
                            setModalShow(true)
                          }}
                          style={styles.actionButton}
                          className="d-flex align-items-center mx-auto"
                        >
                          <Plus size={14} className="me-1" /> Add Details
                        </Button>
                      </td>
                      {/* <td style={styles.tableCell}>
                        <Button
                          size="sm"
                          variant="outline-success"
                          onClick={() => {
                            handleSelectedData(strain)
                            handleSave();
                            setType("growthCondition")
                          }}
                          style={{ ...styles.actionButton, borderColor: primaryColor, color: primaryColor }}
                          className="d-flex align-items-center mx-auto"
                        >
                          <Plus size={14} className="me-1" /> Add Growth Condition
                        </Button>
                      </td> */}
                      <td style={styles.tableCell}>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          style={styles.actionButton}
                          className="d-flex align-items-center mx-auto"
                          onClick={() => handleRemoveExpItem(index)}
                        >
                          <Trash2 size={14} className="me-1" /> Delete
                        </Button>
                      </td>
                    </tr>
                  }
                </>
              })
            }
          </tbody>
        </Table>
      </div>
      <Popup show={modalShow} onHide={() => setModalShow(false)} previewModal={previewModal} setPreviewModal={setPreviewModal}
        handleDataChange={handleDataChange} index={rowIndex} currentElement={currentElement}
      />
      <PDFPreviewModal
        show={previewModal.show}
        files={previewModal.files}
        type={previewModal.type}
        handleClose={() => setPreviewModal({ show: false, fileUrl: null })}
        fileUrl={previewModal.fileUrl}
        folderName="strains"
      />
    </div>
  );
};

// 7. Growth Condition Tab (Placeholder - add actual fields based on requirements)
const GrowthConditionTab = () => {
  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col md={12}>
          <InputGroup>
            <InputGroup.Text className="bg-light">Product →</InputGroup.Text>
            <InputGroup.Text className="bg-light">Gene →</InputGroup.Text>
            <InputGroup.Text className="bg-light">Codone Optimize →</InputGroup.Text>
            <InputGroup.Text className="bg-light">Virtual Clone →</InputGroup.Text>
            <InputGroup.Text className="bg-light">Library of Plasmid →</InputGroup.Text>
            <InputGroup.Text className="bg-light">Strain Engg →</InputGroup.Text>
            <InputGroup.Text className="bg-light">Growth Condition</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md={12} className="text-end justify-end">
          <Button style={buttonStyle} variant="primary">Add Growth Condition</Button>
        </Col>
      </Row>

      <Table responsive hover bordered>
        <thead className="bg-light">
          <tr>
            <th>Media</th>
            <th>Temperature</th>
            <th>pH</th>
            <th>RPM</th>
            <th>Time</th>
            <th>OD Initial</th>
            <th>OD Final</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Select size="sm">
                <option>Select Media</option>
                <option>LB</option>
                <option>M9</option>
                <option>TB</option>
              </Form.Select>
            </td>
            <td>
              <Form.Control size="sm" type="number" placeholder="°C" />
            </td>
            <td>
              <Form.Control size="sm" type="number" step="0.1" />
            </td>
            <td>
              <Form.Control size="sm" type="number" />
            </td>
            <td>
              <Form.Control size="sm" type="text" placeholder="hrs" />
            </td>
            <td>
              <Form.Control size="sm" type="number" step="0.01" />
            </td>
            <td>
              <Form.Control size="sm" type="number" step="0.01" />
            </td>
            <td>
              <Button size="sm" variant="outline-danger">Delete</Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="mt-4">
        <Card className="shadow-sm">
          <Card.Header className="bg-light">Growth Results</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Growth Curve</Form.Label>
                  <Form.Control type="file" size="sm" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" rows={3} size="sm" />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

const ImagePreviewModal = ({ show, handleClose, images, folderName }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Image Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel>
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                src={`${AppConfig.LAB_API_URL}/uploads/${folderName}/${image}`}
                style={{ width: '100%', height: 'auto', maxHeight: '500px', objectFit: 'contain' }}
                alt={`Result ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};

const PDFPreviewModal = ({ show, handleClose, fileUrl, folderName, type, files }) => {
  const fullUrl = `${AppConfig.LAB_API_URL}/uploads/${folderName}/${fileUrl}`;

  if (type === 'images') {
    return (
      <ImagePreviewModal
        show={show}
        handleClose={handleClose}
        images={files}
        folderName={folderName}
      />
    );
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>PDF Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={fullUrl}
          style={{ width: '100%', height: '500px' }}
          title="PDF Preview"
        />
      </Modal.Body>
    </Modal>
  );
};

const Popup = (props) => {
  const primaryColor = "#7FB541";
  const { stageType, previewModal,
    setPreviewModal, handleDataChange, index, currentElement } = props;
  const [uploadedSequenceFile, setUploadedSequenceFile] = useState();
  const [previewType, setPreviewType] = useState("")
  const [uploadedGlycerolStock, setUploadedGlycerolStock] = useState();

  const [localElement, setLocalElement] = useState(currentElement || {});

  // Update local state when currentElement changes (when modal opens)
  useEffect(() => {
    if (currentElement) {
      setLocalElement(currentElement);
    }

    if (currentElement?.sequence_report_pdfs?.length > 0) {
      setUploadedSequenceFile(currentElement?.sequence_report_pdfs[0]);
    }
    if (currentElement?.glycerol_stock_pdfs?.length > 0) {
      setUploadedGlycerolStock(currentElement?.glycerol_stock_pdfs[0])
    }
  }, [currentElement]);

  // Handle local changes
  const handleLocalChange = (e, isCheckbox = false) => {
    const { name, value, checked } = e.target;
    const newValue = isCheckbox ? checked : value;

    // Update local state
    setLocalElement(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Also update parent state
    handleDataChange({
      target: {
        name,
        value: newValue
      }
    }, isCheckbox, index);
  };

  const customStyles = {
    headerTitle: {
      color: primaryColor,
      fontWeight: 600
    },
    formLabel: {
      color: "#4A4A4A",
      fontSize: "0.9rem",
      fontWeight: 500
    },
    inputIcon: {
      color: primaryColor
    },
    saveButton: {
      backgroundColor: primaryColor,
      borderColor: primaryColor
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="clone-confirmation-modal"
      centered
      className="clone-modal"
    >
      <Modal.Header className="border-0">
        <Modal.Title
          id="clone-confirmation-modal"
          className="w-100 text-center"
          style={customStyles.headerTitle}
        >
          Clone Confirmation Details
        </Modal.Title>
        <Button
          variant="link"
          onClick={props.onHide}
          className="p-0 ms-auto"
          style={{ color: "#6c757d" }}
        >
          <X size={20} />
        </Button>
      </Modal.Header>

      <Modal.Body className="pt-0 pb-4">
        <Container>
          <Row className="mb-4 justify-content-center">
            <Col md={10}>
              <Form.Group>
                <Form.Label style={customStyles.formLabel}>
                  Date of Sending DNA Sample
                </Form.Label>
                <InputGroup className="shadow-sm rounded">
                  <InputGroup.Text className="bg-white border-end-0">
                    <Calendar size={18} style={customStyles.inputIcon} />
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    className="border-start-0 shadow-none"
                    name='date_of_sending_dna'
                    value={localElement?.date_of_sending_dna || ''}
                    onChange={(e) => handleLocalChange(e, false)}
                    autoFocus
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4 justify-content-center">
            <Col md={10}>
              <Form.Group>
                <Form.Label style={customStyles.formLabel}>
                  Date of Result Receipt
                </Form.Label>
                <InputGroup className="shadow-sm rounded">
                  <InputGroup.Text className="bg-white border-end-0">
                    <Calendar size={18} style={customStyles.inputIcon} />
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name='date_of_result_receipt'
                    value={localElement?.date_of_result_receipt || ''}
                    onChange={(e) => handleLocalChange(e, false)}
                    className="border-start-0 shadow-none"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4 justify-content-center">
            <Col md={10}>
              <Form.Group>
                <Form.Label style={customStyles.formLabel}>
                  Sequence of Analysis Report
                </Form.Label>
                {uploadedSequenceFile ? (
                  <div className="d-flex gap-2">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        setPreviewType('sequence')
                        setPreviewModal({ show: true, fileUrl: uploadedSequenceFile })
                      }}
                      className="d-flex align-items-center"
                    >
                      <Eye size={14} className="me-1" /> Preview PDF
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => setUploadedSequenceFile(null)}
                    >
                      Change
                    </Button>
                  </div>
                ) : (
                  <>
                    <InputGroup className="shadow-sm rounded">
                      <InputGroup.Text className="bg-white border-end-0">
                        <Upload size={18} style={customStyles.inputIcon} />
                      </InputGroup.Text>
                      <Form.Control
                        type="file"
                        accept=".pdf,.fasta"
                        className="border-start-0 shadow-none pt-1"
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const formData = new FormData();
                            formData.append('sequencePDF', file);

                            try {
                              const response = await axios.post(
                                `${AppConfig.LAB_API_URL}/sequence-analysis/upload`,
                                formData,
                                {
                                  headers: {
                                    'Content-Type': 'multipart/form-data'
                                  }
                                }
                              );

                              setUploadedSequenceFile(response.data.files[0].filename);
                              handleDataChange({
                                target: {
                                  name: 'sequence_report_pdfs',
                                  value: response?.data?.files?.map(f => f.filename)
                                }
                              }, false, index);
                            } catch (error) {
                              console.error('Error uploading file:', error);
                              // Add error handling here
                            }
                          }
                        }}
                      />
                    </InputGroup>
                    <Form.Text className="text-muted fst-italic ps-1" style={{ fontSize: "0.8rem" }}>
                      Please upload sequence analysis files (PDF or FASTA format recommended)
                    </Form.Text>
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4 justify-content-center">
            <Col md={10}>
              <Form.Group>
                <Form.Label style={customStyles.formLabel}>
                  Clone Confirmation Conclusion
                </Form.Label>
                <InputGroup className="shadow-sm rounded">
                  <InputGroup.Text className="bg-white border-end-0">
                    <CheckCircle size={18} style={customStyles.inputIcon} />
                  </InputGroup.Text>
                  <Form.Select
                    className="border-start-0 shadow-none"
                    aria-label="Clone confirmation conclusion"
                    name='clone_confirmation'
                    value={localElement?.clone_confirmation}
                    onChange={(e) => handleLocalChange(e, false)}
                  >
                    <option value="">Select conclusion</option>
                    <option value="success" style={{ color: primaryColor }}>SUCCESS</option>
                    <option value="failure" style={{ color: "#dc3545" }}>FAILURE</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3 justify-content-center">
            <Col md={10}>
              <Form.Group>
                <Form.Label style={customStyles.formLabel}>
                  Glycerol Stock
                </Form.Label>
                {uploadedGlycerolStock ? (
                  <div className="d-flex gap-2">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        setPreviewType('glycerol')
                        setPreviewModal({ show: true, fileUrl: uploadedGlycerolStock })
                      }}
                      className="d-flex align-items-center"
                    >
                      <Eye size={14} className="me-1" /> Preview PDF
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => setUploadedGlycerolStock(null)}
                    >
                      Change
                    </Button>
                  </div>
                ) : (
                  <>
                    <InputGroup className="shadow-sm rounded">
                      <InputGroup.Text className="bg-white border-end-0">
                        <Upload size={18} style={customStyles.inputIcon} />
                      </InputGroup.Text>
                      <Form.Control
                        type="file"
                        accept=".pdf"
                        className="border-start-0 shadow-none pt-1"
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const formData = new FormData();
                            formData.append('glycerolStock', file);

                            try {
                              const response = await axios.post(
                                `${AppConfig.LAB_API_URL}/glycerol-stock/upload`,
                                formData,
                                {
                                  headers: {
                                    'Content-Type': 'multipart/form-data'
                                  }
                                }
                              );

                              setUploadedGlycerolStock(response.data.files[0].filename);
                              handleDataChange({
                                target: {
                                  name: 'glycerol_stock_pdfs',
                                  value: response?.data?.files?.map(f => f.filename)
                                }
                              }, false, index);
                            } catch (error) {
                              console.error('Error uploading file:', error);
                              // Add error handling here
                            }
                          }
                        }}
                      />
                    </InputGroup>
                    <Form.Text className="text-muted fst-italic ps-1" style={{ fontSize: "0.8rem" }}>
                      Upload glycerol stock documentation or images
                    </Form.Text>
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <PDFPreviewModal
          show={previewModal?.show}
          handleClose={() => setPreviewModal({ show: false, fileUrl: null })}
          fileUrl={previewType === 'sequence' ? uploadedSequenceFile : uploadedGlycerolStock}
          folderName="plasmids"
        />
      </Modal.Body>

      <Modal.Footer className="border-0 justify-content-center gap-2 pt-0 pb-4">
        <Button
          variant="light"
          onClick={props.onHide}
          className="px-4 py-2 shadow-sm"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="px-4 py-2 shadow-sm"
          style={customStyles.saveButton}
          onClick={props.onHide}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}



// {isGeneTableOpenEdit && (
//   <GeneTable genes={geneOptionsEdit}
//       selectedGene={selectedGeneEdit}
//       onSelectedGeneChange={(geneId) => handleSelectedGeneChangeEdit(geneId, currentRowIndexEdit)}
//       onClose={handleCloseGeneTableModalEdit}
//   />
// )}

export default LabManagementSystem;
