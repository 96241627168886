import React from 'react';
import { ChevronRight, Dna, Home } from 'lucide-react';

export const Breadcrumbs = ({ items = [], onBreadcrumbClick, type }) => {
  const isCurrentType = (item) => {
    const typeMap = {
      'Genes': 'genes',
      'Codones': 'codone',
      'Virtual Clone': 'virtualCodeConstruct',
      'Exp Library': 'expLibrary',
      'Strain': 'strainEngg',
      'Growth Condition': 'growthCondition'
    };
    return typeMap[item] === type;
  };
  return (
    <nav className="flex py-3" aria-label="Breadcrumb">
      <ol className="inline-flex items-center rounded-lg bg-gradient-to-r from-blue-50 to-indigo-50 p-2 shadow-md">
        {/* <li className="inline-flex items-center">
          <a href="/" className="inline-flex items-center px-3 py-1 text-sm font-medium text-gray-700 transition-all duration-200 rounded-md hover:bg-white hover:shadow-sm hover:text-blue-600">
            <Home className="w-4 h-4 mr-2 text-blue-500" />
            Home
          </a>
        </li> */}

        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index !== 0 && <ChevronRight className="w-4 h-4 text-indigo-300" />}
              <a
                href={item.href}
                onClick={() => onBreadcrumbClick(item)}
                className={`px-3 py-1 ml-1 text-sm font-medium rounded-md transition-all duration-200 ${isCurrentType(item)
                    ? 'bg-[#7FB541] text-white shadow-sm'
                    : 'text-gray-700 hover:bg-white hover:shadow-sm hover:text-[#7FB541]'
                  } flex gap-2`}
                aria-current={index === items.length - 1 ? 'page' : undefined}
              >
                {index == 0 && <Dna />}
                {item}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};