import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./components/login/login";
import PrivateRoute from './utils/privateRoutes';
import ProductPage from './components/ProductPage';
import ForgotPassword from "./components/login/ForgotPassword";
import VerifyOtp from "./components/login/VerifyOtp";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Provider } from 'react-redux';
import store from './redux/store';
import { pdfjs } from 'react-pdf';
import EditProductForm from "./components/EditProductForm";
import AddProductForm from "./components/AddProductForm";
import { Toaster } from 'sonner';
import ChangeProfilePassword from './components/login/ChangeProfilePassword';
import Experiments from "./components/NewUI/Experiment";
// import { ChakraProvider } from '@chakra-ui/react'
import ExperimentGeneManager from "./components/NewUI/Experiment";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function App() {


  return (
    <Provider store={store}>
      {/* <ChakraProvider> */}
        <div className="App">
          <Toaster position="top-center" richColors />
          <Router>
            <Navbar />

            <Routes>
              <Route path="/*" element={<SignIn />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/verifyotp" element={<VerifyOtp />} />
              <Route path="/change-password" element={<ChangeProfilePassword />} />
              {/* if CORS error occurs comment line no:35 and line no:39 do change in product page also*/}
              {/*           <Route path="/" element={<PrivateRoute />}>*/}
              <Route path="/edit/:productId" element={<EditProductForm />} />
              <Route path="/productPage" element={<ProductPage />} />
              <Route path="/add" element={<AddProductForm />} /> 
              {/* <Route path="/add" element={<ExperimentGeneManager />} /> */}
              {/*</Route>*/}
            </Routes>

          </Router>
        </div>
      {/* </ChakraProvider> */}
    </Provider>
  );
}

export default App;


