import { toast } from "sonner";
import { addProducts } from "../redux/productsSlice";
import apiService from "../services/apiService";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppConfig from "../AppConfig";
import { nanoid } from "nanoid";

const useProductForm = () => {
  const [productName, setProductName] = useState("");
  const [stage, setStage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedHost, setSelectedHost] = useState(null);
  const [objective, setObjective] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [isTestProduct, setIsTestProduct] = useState(false);
  const [files, setFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(false);
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);
  const pdfInputRef = useRef(null);
  const [tool, setTool] = useState("");
  const [gcPercentage, setGcPercentage] = useState('');
  const [caiScore, setCaiScore] = useState('');
  const [geneSequence, setGeneSequence] = useState('');
  const [optimizedSequence, setOptimizedSequence] = useState('');
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [currentPDF, setCurrentPDF] = useState(null);
  const users = useSelector((store) => store.users);
  const userId = localStorage.getItem('userId');
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [commonName, setcommonName] = useState("");
  const [scientificName, setscientificName] = useState("");
  const [experimentName, setexperimentName] = useState("");
  const [targetGene, setTargetGene] = useState("");
  const [proteinGene, setProteinGene] = useState("");
  const [geneOptions, setGeneOptions] = useState([]);
  const [proteinGeneOptions, setProteinGeneOptions] = useState([])
  const [selectedGene, setSelectedGene] = useState('');
  const [selectedProteinGene, setSelectedProteinGene] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneTableOpen, setIsGeneTableOpen] = useState(false);
  const [isProteinTableOpen, setIsProteinTableOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
  const [currentExperimentName, setCurrentExperimentName] = useState('');
  const [selectedExperiment, setSelectedExperiment] = useState({});
  const [isExpDetailsModal, setIsExpDetailsModal] = useState(false);
  const [type, setType] = useState('');
  const [selectedExpIndex, setSelectedExpIndex] = useState(0);

  let [geneData, setGeneData] = useState([{}]);
  let [codoneData, setCodoneData] = useState([{}]);
  let [virtualCloneData, setVirtualCloneData] = useState([{}]);
  let [expLibraryData, setExpLibraryData] = useState([{}]);
  let [strainEnggData, setStrainEnggData] = useState([{}]);
  let [growthConditionData, setGrowthConditionData] = useState([{}]);
  let [selectedExperimentIndex, setSelectedExperimentIndex] = useState(0);
  let [isGeneLoading, setIsGeneLoading] = useState(false);


  const handleSelectedExperiment = (index) => {
    setSelectedExperiment(rows[index]);
    setSelectedExpIndex(index);
    setIsExpDetailsModal(true);
  }

  const handleCloseExperimentDetailModal = () => {
    setIsExpDetailsModal(false);
  }

  useEffect(() => {
    if (selectedExperiment && selectedExperiment.id) {
      console.log(selectedExperiment, 'selectedExperiment', rows)
      const exps = [...rows];
      exps[selectedExpIndex] = selectedExperiment;
      setRows(exps);
    }

    // const selectedExpRow = rows.filter(row => row.id === selectedExperiment.id);
    // console.log(selectedExpRow, 'selectedExpRow')
  }, [selectedExperiment])


  // const handleDataChange = (e, isSelect = false, indx) => {
  //   const formData = type === "genes" ?  geneData[indx] : type === "virtualCodeConstruct" ? virtualCloneData[indx] : type === "expLibrary" ? expLibraryData[indx] : codoneData[indx];
  //   const setFormData = type === "genes" ?  setGeneData :  type === "virtualCodeConstruct" ? setVirtualCloneData : type === "expLibrary" ? setExpLibraryData : setCodoneData;
  //
  //   let name, value;
  //
  //   if (isSelect) {
  //     // Handle Select component
  //     name = e.name;
  //     value = e.value;
  //   } else {
  //     // Handle regular inputs
  //     name = e.target.name;
  //     value = e.target.type === 'date'
  //         ? new Date(e.target.value).toISOString().split('T')[0]
  //         : e.target.value;
  //   }
  //
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  const handleDataChange = (e, isSelect = false, indx = 0) => {
    const dataMap = {
      "genes": [geneData, setGeneData],
      "virtualCodeConstruct": [virtualCloneData, setVirtualCloneData],
      "expLibrary": [expLibraryData, setExpLibraryData],
      "strainEngg": [strainEnggData, setStrainEnggData],
      "growthCondition": [growthConditionData, setGrowthConditionData],
      "codone": [codoneData, setCodoneData]
    };

    const [currentData, setCurrentData] = dataMap[type] || [codoneData, setCodoneData];

    let name, value;
    if (isSelect) {
      name = e.name;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.type === 'date'
        ? new Date(e.target.value).toISOString().split('T')[0]
        : e.target.value;
    }

    console.log(e, 'runningHandleDataChange', e.target.name, e.target.value)

    setCurrentData(prevData => {
      const newData = [...prevData];
      newData[indx] = {
        ...(prevData[indx] || {}),
        mapId: nanoid(),
        [name]: value
      };
      return newData;
    });
  };

  const handleAddMore = (expId) => {
    const dataMap = {
      "genes": setGeneData,
      "codone": setCodoneData,
      "virtualCodeConstruct": setVirtualCloneData,
      "expLibrary": setExpLibraryData,
      "strainEngg": setStrainEnggData,
      "growthCondition": setGrowthConditionData
    };

    const setStateFunction = dataMap[type];
    if (!setStateFunction) return;

    setStateFunction(prevData => [
      ...prevData,
      { experiment_id: expId }  // Add the experiment ID to each new object
    ]);
  };

  const handleRemoveExpItem = (index) => {
    const dataMap = {
      "genes": [geneData, setGeneData],
      "codone": [codoneData, setCodoneData],
      "virtualCodeConstruct": [virtualCloneData, setVirtualCloneData],
      "expLibrary": [expLibraryData, setExpLibraryData],
      "strainEngg": [strainEnggData, setStrainEnggData],
      "growthCondition": [growthConditionData, setGrowthConditionData]
    };

    const [currentData, setCurrentData] = dataMap[type] || [];
    if (!setCurrentData || currentData.length <= 1) return;

    setCurrentData(prevData => prevData.filter((_, i) => i !== index));
  };

  const navigate = useNavigate();


  const dispatch = useDispatch();
  useEffect(() => {
    if (teamLead?.value != userId) {
      setIsApproved(false);
      setSelectedSequence(false);
    }
  }, [teamLead, userId]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Filter out files that are not jpeg, png, or webp
    const allowedFiles = selectedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp"
    );

    // Limit the number of files to a maximum of 10
    if (allowedFiles.length > 10) {
      alert("Maximum 10 files allowed.");
      return;
    }

    setFiles([...files, ...allowedFiles]);
    fileInputRef.current.value = null; // Clear the input value
  };

  const getContainerStyles = () => {
    return {
      width: "300px",
      height: imagesList.length > 0 ? "280px" : "0px",
      margin: "0 auto",
      transition: "height 0.3s ease",
    };
  };

  const handlePdfFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const allowedFiles = selectedFiles.filter((file) => file.type === "application/pdf");

    if (allowedFiles.length > 10) {
      alert("Maximum 10 PDF files allowed.");
      return;
    }
    setPdfFiles([...pdfFiles, ...allowedFiles]);
    pdfInputRef.current.value = null; // Clear the input value
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    // Filter out files that are not jpeg, png, or webp
    const allowedFiles = droppedFiles.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp"
    );

    // Limit the number of files to a maximum of 10
    if (files.length + allowedFiles.length > 10) {
      alert("Maximum 10 files allowed.");
      return;
    }

    setFiles([...files, ...allowedFiles]);
  };
  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handleDeletePdf = (index) => {
    setPdfFiles((prevPdfFiles) => prevPdfFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handlePdfBrowseClick = () => {
    pdfInputRef.current.click();
  };
  const openPdf = (pdf) => {
    const pdfUrl = URL.createObjectURL(pdf);
    setSelectedPdfUrl(pdfUrl);
    setShowPDFViewer(true); // Open PDF viewer modal
  };
  const handleHostChange = (selectedOption) => {
    setSelectedHost(selectedOption?.label);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const filenames = files.map((file) => file.name);
    const pdfFilenames = pdfFiles.map((file) => file.name);


    // let selectedTeamMembers = [];
    // selectedTeamMembers.push(localStorage.getItem('userId'));

    console.log(rows, 'expRows', localStorage.getItem('userId'));

    const userId = localStorage.getItem('userId');
    console.log('userId from localStorage:', userId);
    console.log('userId type:', typeof userId);

    let selectedTeamMembers = [];
    selectedTeamMembers.push(Number(userId));
    console.log('selectedTeamMembers array:', selectedTeamMembers);

    const experimentDetails = rows.map((row) => ({
      experimentName: row.experimentName,
      commonName: row.commonName,
      scientificName: row.scientificName,
      genes: row.geneData,
      codones: row.codoneData,
      virtualCloneConstuct: row.virtualCloneData,
      expLibrary: row.expLibraryData,
      any_deviation: row.any_deviation,
      deviation_description: row.deviation_description,
      strainEngg: row.strainEnggData,
      // growthCondition: row.growthConditionData,
      protein: row.protein,
      gene: row.gene,
      protein_sequence: row.protein_sequence,
      gene_sequence: row.gene_sequence,
      experiment_type: row.experiment_type,
      exp_stage: row.exp_type,
      date_of_sending_dna: row.date_of_sending_dna,
      date_of_result_receipt: row.date_of_result_receipt,
      date_of_completion: row.date_of_completion,
      clone_confirmation_conclusion: row.clone_confirmation_conclusion,
      clone_images: row.result_images,
      sequence_report_pdfs: row.sequence_report_pdfs,
      sop_pdfs: row.sop_pdfs,
      trial_number: row.trial_number,
      optimizedSequence: row.optimizedSequence,
      selectedTeamMembers: selectedTeamMembers
    }));
    // Handle form submission
    const addFormData = {
      productName,
      host: selectedHost ? selectedHost : "",
      stage,
      objective,
      teamLead: teamLead ? teamLead.value : "",
      isTestProduct,
      isApproved,
      selectedTeamMembers: selectedTeamMembers,
      filenames,
      pdfFilenames: pdfFilenames,
      experimentDetails
    };

    // Create form data
    const formData = new FormData();
    const photosFormData = new FormData(); // Separate FormData for photos
    const pdfFormData = new FormData();
    formData.append("productName", productName);
    formData.append("stage", stage);
    formData.append("objective", objective);
    formData.append("teamLead", teamLead ? teamLead.value : 0);
    formData.append("isTestProduct", isTestProduct ? 1 : 0);
    formData.append("isApproved", isApproved ? 1 : 0);
    selectedTeamMembers.forEach((member) => {
      formData.append("selectedTeamMembers[]", member.value);
    });

    selectedTeamMembers.forEach((member) => {

      formData.append("selectedTeamMembers[]", member);

    });


    files.forEach((file) => {
      formData.append("photos", file);
      photosFormData.append("photos", file);
    });
    pdfFiles.forEach((file) => {
      formData.append("pdfs", file);
      pdfFormData.append("pdfs", file);
    });

    setIsSubmitting(true);

    // Log experimentDetails before sending
    try {
      console.log(JSON.stringify(experimentDetails, null, 2), 'Circulr Data');
    } catch (err) {
      console.error("Circular structure detected in experimentDetails", err);
    }


    try {
      const productResponse = await apiService.post(
        `/product/add`,
        addFormData
      );
      const prod_id = productResponse.data.productId;
      addFormData.id = prod_id;

      // Check if upload was successful before proceeding with product submission
      if (productResponse.status === 201) {
        if (files.length > 0) {
          const uploadResponse = await apiService.post(
            `/photos/upload/${prod_id}`,
            photosFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Handle response
          if (uploadResponse.status === 200) {
            dispatch(addProducts(addFormData));
            // onProductAdded();
            // onClose();
          } else {
            throw new Error("Failed to upload photos");
          }
        } else {
          dispatch(addProducts(addFormData));
          // onProductAdded();
          // onClose();
        }
        if (pdfFiles.length > 0) {
          const uploadPdfResponse = await apiService.post(`/pdf/upload/${prod_id}`,
            pdfFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              },
            });

          if (uploadPdfResponse.status === 200) {
            dispatch(addProducts(addFormData));
            toast.success("Product added succesfully")
            navigate('/productPage');
            // onClose();
          } else {
            throw new Error("Failed to upload PDFs");
          }
        } else {
          dispatch(addProducts(addFormData));
          toast.success("Product added succesfully")
          navigate('/productPage');
          // onClose();
        }
      } else {
        throw new Error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product")
      // Handle error if needed
    } finally {
      // Set isSubmitting to false after submission is completed (success or failure)
      setIsSubmitting(false);
    }
  };


  const handleToggleTeamMember = (member, e) => {
    // Stop event propagation
    e.stopPropagation();

    if (selectedTeamMembers.some((m) => m.id === member.id)) {
      setSelectedTeamMembers(selectedTeamMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedTeamMembers([...selectedTeamMembers, member.id]);
    }
  };

  const handleSwitchChange = (checked) => {
    setIsTestProduct(checked ? 1 : 0);
  };
  const handleChange = (selectedOption) => {
    setTeamLead(selectedOption);
  };
  const handleTeamMembersChange = (selectedOptions) => {
    setSelectedTeamMembers(selectedOptions);
  };
  const handleStageChange = (selectedOption) => {
    setStage(selectedOption ? selectedOption.value : "");
  };
  const handleToolsChange = (selectedOption) => {
    setTool(selectedOption ? selectedOption.value : "");
  };
  const handleOpenPDFModal = (pdfFile) => {
    const fileUrl = URL.createObjectURL(pdfFile);
    setCurrentPDF(fileUrl);
    setShowPDFModal(true);
  };

  const handleClosePDFModal = () => {
    setShowPDFModal(false);
    setCurrentPDF(null);
  };
  const onDeletePdf = (index) => {
    const updatedPdfFiles = [...pdfFiles];
    updatedPdfFiles.splice(index, 1);
    setPdfFiles(updatedPdfFiles);
  };
  const handleClosePDFViewer = () => {
    setSelectedPdfUrl(null);
    setShowPDFViewer(false);
  };
  const imagesList = files.map((file) => ({
    url: URL.createObjectURL(file),
    title: file.name,
  }));
  const handleNextStep = () => {
    console.log("HandleNextTriggered")
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const toggleGeneTable = () => {
    setIsGeneTableOpen(prevState => !prevState);
  };

  const toggleProteinGeneTable = () => {
    setIsProteinTableOpen(prevState => !prevState);
  };
  const handleSearch = async (id, geneId) => {
    const index = rows.findIndex((value) => value.id === id);

    // const currentSelectedGene = rows[index].gene;

    // console.log(currentSelectedGene, 'chechererer', rows[index])
    setIsGeneTableOpen(true);
    if (geneId) {
      setIsGeneLoading(true)
      try {
        const response = await fetch(`${AppConfig.pfCharakaApiUrl}/gene?term=${encodeURIComponent(geneId)}`);
        const data = await response.json();

        if (data.status && data.data && data.data.rows) {
          const filteredRows = data.data.rows.filter(row =>
            row.data.id.label.trim() !== '' && row.data.description.label.trim() !== ''
          );
          setGeneOptions(filteredRows);
          setIsGeneLoading(false)
        } else {
          setGeneOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setGeneOptions([]);
      } finally {
        setIsLoading(false);
      }
      setCurrentRowIndex(index);
      // setIsGeneTableOpen(true);
    }
  };
  const handleSearchProtein = async (id) => {
    const index = rows.findIndex((value) => value.id === id);
    const currentSelectedProtein = rows[index].protein;
    setIsProteinTableOpen(true);
    if (currentSelectedProtein) {

      setIsLoading(true);
      try {
        const response = await fetch(`${AppConfig.pfCharakaApiUrl}/protein?term=${encodeURIComponent(currentSelectedProtein)}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        if (data.status && data.data && data.data.rows) {
          const filteredRows = data.data.rows.filter(row =>
            row.data.id.label.trim() !== '' &&
            row.data.title.label.trim() !== '' &&
            row.data.gi.label.trim() !== '' &&
            row.data.accessionVersion.label.trim() !== ''
          );
          setProteinGeneOptions(filteredRows);
        } else {
          setProteinGeneOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setProteinGeneOptions([]);
      } finally {
        setIsLoading(false);
      }

      setCurrentRowIndex(index);
    } else {
      setProteinGeneOptions([]);
    }
  };

  const fetchGeneSequence = async (geneId, index) => {
    try {
      // setIsGeneLoadOpen(true);
      // Step 1: Fetch nucleotide_id
      const toastId = toast.loading('Fetching Gene Sequence, Please Wait...')
      const responseNucleotide = await fetch(`https://pf.charaka.tech/gene?geneId=${geneId}`, {
        method: 'POST',
      });
      const dataNucleotide = await responseNucleotide.json();

      // Ensure the response is successful and contains the necessary data
      if (dataNucleotide.status && dataNucleotide.data) {
        const nucleotideId = dataNucleotide.data.nucleotide_id;

        console.log(nucleotideId, 'NEUTIDEID', dataNucleotide.data);

        if (nucleotideId && nucleotideId !== '') {
          const responseFasta = await fetch(`https://pf.charaka.tech/fasta?nucleotide_id=${nucleotideId}`, {
            method: 'POST',
          });
          const fastaData = await responseFasta.json(); // Assuming FASTA data is returned as plain text
          if (fastaData.status && fastaData.data) {
            const fastaSequence = fastaData.data.fasta;
            setGeneSequence(fastaSequence);
            setRows(prevRows =>
              prevRows.map((exp, idx) =>
                idx === index ? { ...exp, gene_sequence: fastaSequence } : exp
              )
            );
            let newGeneArr = [...geneData]
            let geneIndex = newGeneArr.findIndex((gene) => geneId == gene.gene)
            let newGene = { ...newGeneArr[geneIndex], gene: geneId, gene_sequence: fastaData.data.fasta }
            newGeneArr[geneIndex] = newGene;

            console.log(newGeneArr, 'gen1', geneIndex, newGene)
            setGeneData(newGeneArr);
            toast.dismiss(toastId)
            toast.success('Gene Sequence is set')
            setIsGeneLoading(false);
            setIsGeneTableOpen(false);
          } else {
            console.error("Error: fasta not found in the response.");
            setGeneSequence("No Gene Sequence Found");

          }
        } else {
          toast.dismiss(toastId);
          toast.error('Error: Nucleotide ID not found in the response.')
        }

        // Step 2: Fetch FASTA sequence using nucleotide_id

      } else {
        console.error("Error: Nucleotide ID not found in the response.");
      }
    } catch (error) {
      console.error("Error fetching gene sequence:", error);
    } finally {
      // setIsGeneLoadOpen(false);

    }
  };


  const handleSelectedGeneChange = (geneId, index) => {
    fetchGeneSequence(geneId, index)
    setRows(prevRows =>
      prevRows.map((row, i) => (i === index ? { ...row, gene: geneId } : row))
    );
    // setIsGeneTableOpen(false);
  };
  const handleSelectedProteinGeneChange = (proteinGeneId, index) => {
    setRows(prevRows =>
      prevRows.map((row, i) => (i === index ? { ...row, selectedProteinGene: proteinGeneId } : row))
    );
    setIsProteinTableOpen(false);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { id: nanoid(), experimentName: "", commonName: "", scientificName: "", selectedGene: "", selectedProteinGene: "" },
    ]);

    // setGeneData([{}]);
    // setCodoneData([{}])
    setShowFields(true);
  };

  useEffect(() => {
    if (isExperimentModalOpen) {
      console.log(rows[selectedExpIndex], 'experimentSlecetd')
      setGeneData(rows[selectedExpIndex].geneData ? rows[selectedExpIndex].geneData : [{}])
    }
  }, [currentRowId])

  const [isDataSet, setIsDataSet] = useState(false)

  const handleSetData = (expIndex) => {
    const experiment = rows[expIndex];

    console.log(experiment, 'selectedExp')

    // Set all experiment data states with default empty arrays if data doesn't exist
    setGeneData(experiment.geneData || [{}]);
    setCodoneData(experiment.codoneData || [{}]);
    setVirtualCloneData(experiment.virtualCloneData || [{}]);
    setExpLibraryData(experiment.expLibraryData || [{}]);
    setStrainEnggData(experiment.strainEnggData || [{}]);
    setGrowthConditionData(experiment.growthConditionData || [{}]);

    // setIsDataSet(true);
  };




  const handleRemoveExperiment = (id) => {
    setRows((prevRows) => prevRows.filter(row => row.id !== id));
    if (rows.length <= 1) setShowFields(false); // Hide fields if no rows are left
  };
  const handleInputChange = (id, event, isSelect = false) => {
    console.log("Event:", event);
    const { name, value } = event.target;
    setRows(prevRows =>
      prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
    );
  };
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleCloseGeneTableModal = () => {
    setIsGeneTableOpen(false);
  }
  const handleCloseProtienTableModal = () => {
    setIsProteinTableOpen(false);
  }
  const handleCodonOptimization = (rowId, experimentName, index) => {
    // Logic to open a modal or window
    setCurrentRowId(rowId);
    setCurrentRowIndex(index);
    setCurrentExperimentName(experimentName);
    const experiment = rows[index];

    console.log(experiment, 'selectedExp')

    // Set all experiment data states with default empty arrays if data doesn't exist
    setGeneData(experiment.geneData || [{}]);
    setCodoneData(experiment.codoneData || [{}]);
    setVirtualCloneData(experiment.virtualCloneData || [{}]);
    setExpLibraryData(experiment.expLibraryData || [{}]);
    setStrainEnggData(experiment.strainEnggData || [{}]);
    setGrowthConditionData(experiment.growthConditionData || [{}]);

    setIsDataSet(true);
    // Assuming you have a modal state
  };

  useEffect(() => {
    if (isDataSet) {
      setIsExperimentModalOpen(true);
    }
  }, [isDataSet])


  const handleCloseModal = () => {
    setIsExperimentModalOpen(false);
    setIsDataSet(false);
  };


  // const handleSaveModal = (data) => {
  //   const typeConfig = {
  //     genes: {
  //       key: 'geneData',
  //       dependency: null,
  //       errorMessage: null
  //     },
  //     codone: {
  //       key: 'codoneData',
  //       dependency: 'geneData',
  //       errorMessage: 'Please Enter a Gene first'
  //     },
  //     virtualCodeConstruct: {
  //       key: 'virtualCloneData',
  //       dependency: 'codoneData',
  //       errorMessage: 'Please Enter a Codone Optimization'
  //     },
  //     expLibrary: {
  //       key: 'expLibraryData',
  //       dependency: 'virtualCloneData',
  //       errorMessage: 'Please Enter a Virtual Clone Construct'
  //     },
  //     strainEngg: {
  //       key: 'strainEnggData',
  //       dependency: 'expLibraryData',
  //       errorMessage: 'Please Enter a Expression'
  //     }
  //   };
  //
  //   const config = typeConfig[type];
  //   if (!config) return;
  //
  //   const updatedRows = rows.map(row => {
  //     if (row.id !== currentRowId) return row;
  //
  //     // Check dependency if exists
  //     if (config.dependency && !row[config.dependency]) {
  //       toast.error(config.errorMessage);
  //       return row;
  //     }
  //
  //     // Update row with new data
  //     return {
  //       ...row,
  //       [config.key]: { ...data }
  //     };
  //   });
  //
  //   setRows(updatedRows);
  //   handleCloseModal();
  // };

  const handleSaveModal = (data, isMulti = false) => {
    console.log(data, "saveModalData")
    const typeConfig = {
      genes: {
        key: 'geneData',
        dependency: null,
        errorMessage: null
      },
      codone: {
        key: 'codoneData',
        dependency: 'geneData',
        errorMessage: 'Please Enter a Gene first'
      },
      virtualCodeConstruct: {
        key: 'virtualCloneData',
        dependency: 'codoneData',
        errorMessage: 'Please Enter a Codone Optimization'
      },
      expLibrary: {
        key: 'expLibraryData',
        dependency: 'virtualCloneData',
        errorMessage: 'Please Enter a Virtual Clone Construct'
      },
      strainEngg: {
        key: 'strainEnggData',
        dependency: 'expLibraryData',
        errorMessage: 'Please Enter a Expression'
      },
      growthCondition: {
        key: 'growthConditionData',
        dependency: 'strainEnggData',
        errorMessage: 'Please Enter a Strain'
      }
    };

    const config = typeConfig[type];
    if (!config) return;

    const updatedRows = [...rows];

    // Check if the row exists and if dependency data is available
    if (updatedRows[selectedExperimentIndex]) {
      if (config.dependency && !updatedRows[selectedExperimentIndex][config.dependency]) {
        toast.error(config.errorMessage);
        return;
      }

      // Update only this specific row
      updatedRows[selectedExperimentIndex] = {
        ...updatedRows[selectedExperimentIndex],
        [config.key]: [...data]
      };

      setRows(updatedRows);
    }

    // setRows(updatedRows);

    const stateUpdaters = {
      genes: setGeneData,
      codone: setCodoneData,
      virtualCodeConstruct: setVirtualCloneData,
      expLibrary: setExpLibraryData,
      strainEngg: setExpLibraryData
    };

    const setStateData = stateUpdaters[type];

    if (setStateData) {
      setStateData(prev => {
        console.log("Previous State:", prev);

        // Ensure `prev` is a flat array, not an array inside an array
        const flatPrev = Array.isArray(prev) && Array.isArray(prev[0]) ? prev[0] : prev;

        return [...data];  // ✅ Ensuring flat array structure
      });
    }
    if (!isMulti) {
      handleCloseModal();
    }

  };



  //For Debugging
  useEffect(() => {
    console.log(rows, 'updateROWSS')
  }, [rows])

  return {
    handleCloseModal, handleBack, handleSubmit,
    handleFileDrop,
    currentStep,
    productName,
    setProductName,
    stage,
    handleStageChange,
    objective,
    setObjective,
    teamLead,
    handleChange,
    users,
    selectedTeamMembers,
    handleTeamMembersChange,
    isTestProduct,
    handleSwitchChange,
    handleBrowseClick,
    fileInputRef,
    handleFileChange,
    files,
    getContainerStyles,
    imagesList,
    handleDeleteFile,
    selectedHost,
    handleHostChange,
    userId,
    isApproved,
    setIsApproved,
    handlePdfBrowseClick,
    pdfInputRef,
    handlePdfFileChange,
    pdfFiles,
    openPdf,
    handleDeletePdf,
    handleAddRow,
    showFields,
    rows,
    handleInputChange,
    handleSearchProtein,
    handleCodonOptimization,
    handleRemoveExperiment,
    isGeneTableOpen,
    geneOptions,
    currentRowIndex,
    handleSelectedGeneChange,
    handleCloseGeneTableModal,
    isLoading,
    isProteinTableOpen,
    proteinGeneOptions,
    handleSelectedProteinGeneChange,
    handleCloseProtienTableModal,
    handlePreviousStep,
    handleNextStep,
    isSubmitting,
    isExperimentModalOpen,
    handleSaveModal,
    type, setType,
    geneSequence, setGeneSequence,
    handleDataChange,
    geneData,
    codoneData,
    virtualCloneData,
    expLibraryData,
    strainEnggData,
    handleAddMore,
    handleRemoveExpItem,
    growthConditionData,
    isExpDetailsModal, setIsExpDetailsModal,
    handleSelectedExperiment,
    selectedExperiment,
    setSelectedExperiment,
    handleCloseExperimentDetailModal,
    setSelectedExpIndex,
    setSelectedSequence,
    handleSetData,
    setSelectedExperimentIndex,
    handleSearch,
    selectedProteinGene,
    selectedGene,
    setIsProteinTableOpen,
    setIsGeneTableOpen,
    setRows,
    isGeneLoading
  }

}

export default useProductForm;