import { nanoid } from "nanoid";

export function flattenNestedData(nestedData) {
  const productExperiments = [];
  const genes = [];
  const codonOptimizations = [];
  const virtualCloneConstructs = [];
  const expressionLibraries = [];
  const strainEngineerings = [];
  const growthConditions = [];

  if (!nestedData || nestedData.length === 0) {
    const experimentId = nanoid();
    const geneId = nanoid();
    const optimizationId = nanoid();
    const constructId = nanoid();
    const libraryId = nanoid();
    const strainId = nanoid();
    const growthId = nanoid();

    // Create template experiment
    productExperiments.push({
      id: experimentId,
      experiment_name: '',
      common_name: '',
      scientific_name: '',
      gene: '',
      protein: '',
      exp_stage: '',
      is_deleted: false
    });

    // Create template gene
    genes.push({
      id: geneId,
      experimentId,
      gene_sequence: '',
      company: '',
      order_date: '',
      receipt_date: ''
    });

    // Create template codon optimization
    codonOptimizations.push({
      id: optimizationId,
      gene_id: geneId,
      experiment_id: experimentId,
      tool: '',
      cai_score: '',
      gc_percentage: '',
      optimized_sequence: ''
    });

    // Create template virtual clone construct
    virtualCloneConstructs.push({
      id: constructId,
      codone_id: optimizationId,
      experimentId,
      cloneType: '',
      clone_size: '',
      cloning_sites: ''
    });

    // Create template expression library
    expressionLibraries.push({
      id: libraryId,
      clone_construct_id: constructId,
      experimentId,
      expType: '',
      plasmid_name: ''
    });

    // Create template strain engineering
    strainEngineerings.push({
      id: strainId,
      exp_library_id: libraryId,
      experimentId,
      strain_name: ''
    });

    // Create template growth condition
    growthConditions.push({
      id: growthId,
      strain_id: strainId,
      experimentId,
      growth_condition_name: ''
    });

    return {
      genes,
      codonOptimizations,
      virtualCloneConstructs,
      expressionLibraries,
      strainEngineerings,
      growthConditions
    };
  }

  // Generate unique IDs for each item
  let expId = 1, geneId = 1, optId = 1, vcId = 1, libId = 1, strainId = 1, growthId = 1;

  nestedData.forEach(experiment => {
    const experimentId = `exp${expId++}`;
    const experimentData = {
      id: experimentId,
      ...experiment, // Copy other experiment data
      Genes: undefined // Remove the nested array
    };
    productExperiments.push(experimentData);

    experiment.Genes?.forEach(gene => {
      const currentGeneId = `gene${geneId++}`;
      const geneData = {
        experimentId: experiment.id,
        ...gene, // Copy other gene data
        codoneOptimizations: undefined // Remove the nested array
      };
      genes.push(geneData);

      gene.codoneOptimizations?.forEach(optimization => {
        const currentOptId = `opt${optId++}`;
        const optData = {
          ...optimization, // Copy other optimization data
          virtualCloneConstructs: undefined // Remove the nested array
        };
        codonOptimizations.push(optData);

        optimization.virtualCloneConstructs?.forEach(construct => {
          const currentVcId = `vc${vcId++}`;
          const vcData = {
            ...construct,
            experimentId: experiment.id,
            expressionLibraries: undefined // Remove the nested array
          };
          virtualCloneConstructs.push(vcData);

          construct.expressionLibraries?.forEach(library => {
            const currentLibId = `lib${libId++}`;
            const libData = {
              ...library,
              experimentId: experiment.id,
              strainEngeneeerings: undefined // Remove the nested array
            };
            expressionLibraries.push(libData);

            library.strainEngineerings?.forEach(strain => {
              const currentStrainId = `strain${strainId++}`;
              const strainData = {
                ...strain,
                experimentId: experiment.id,
                growthConditions: undefined // Remove the nested array
              };
              strainEngineerings.push(strainData);

              strain.growthConditions?.forEach(growth => {
                const growthData = {
                  ...growth,
                  experimentId: experiment.id
                };
                growthConditions.push(growthData);
              });
            });
          });
        });
      });
    });
  });

  return {
    productExperiments,
    genes,
    codonOptimizations,
    virtualCloneConstructs,
    expressionLibraries,
    strainEngineerings,
    growthConditions
  };
}


export function reconstructNestedData(flatData) {
  const {
    product_experiments,
    genes,
    codonOptimizations,
    virtualCloneConstructs,
    expressionLibraries,
    strainEngineerings,
    growthConditions
  } = flatData;

  const nestedData = [];


  product_experiments.forEach(experiment => {
    console.log(genes, 'genesss')
    const experimentGenes = genes.filter(gene => gene.experimentId.toString() === experiment.id.toString());
    
    const nestedExperiment = {
      ...experiment,
      Genes: experimentGenes.map(gene => {
        const geneOptimizations = codonOptimizations.filter(opt => opt.geneId.toString() === gene.id.toString());

        // console.log(geneOptimizations, 'geneOptimizations', codonOptimizations, gene.id);
        // return

        return {
          ...gene,
          codoneOptimizations: geneOptimizations.map(opt => {
            const optConstructs = virtualCloneConstructs.filter(vc => vc.codoneId.toString() === opt.id.toString());
            console.log("constructs", optConstructs, '--', virtualCloneConstructs, opt);
        // return

            return {
              ...opt,
              virtualCloneConstructs: optConstructs.map(construct => {

                console.log(construct, 'companieson', expressionLibraries[0])
                const constructLibraries = expressionLibraries.filter(lib => lib.cloneConstructId.toString() === construct.id.toString());

                console.log(constructLibraries, 'expLibrariesss', construct);
                // return

                return {
                  ...construct,
                  expressionLibraries: constructLibraries.map(library => {

                    const libraryStrains = strainEngineerings.filter(strain => strain.expLibId.toString() === library.id.toString());

                    return {
                      ...library,
                      strainEngineerings: libraryStrains.map(strain => {
                        const strainGrowthConditions = growthConditions.filter(growth => growth.strain_id === strain.id);

                        return {
                          ...strain,
                          growthConditions: strainGrowthConditions
                        };
                      })
                    };
                  })
                };
              })
            };
          })
        };
      })
    };

    nestedData.push(nestedExperiment);
  });

  return nestedData;
}

export const isItemMatching = (
  selectedData, 
  itemToMatch, 
  matchKeys = ['id', 'geneId', 'mapId'], 
  targetKey = 'geneId'
) => {
  // If no selectedData or no itemToMatch, return false
  if (!selectedData || !itemToMatch) return false;

  // Check if any of the matchKeys in selectedData match the targetKey in itemToMatch
  return matchKeys.some(key => 
    selectedData[key] === itemToMatch[targetKey]
  );
};