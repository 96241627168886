import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConfig from "../AppConfig";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageLoader from "./ImageLoader";
import { addProducts } from "../redux/productsSlice";
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import apiService from "../services/apiService";
import '../styles/_dropdown.scss'
import stages from "../assets/data/stage";

import hosts from "../assets/data/host";
import Footer from "./Footer";
import ProductInfo from "./product/ProductInfo";
import ProductHost from "./product/ProductHost";
import ProductExperiments from "./product/ProductExperiments";
import CodonOptimization from "./product/CodonOptimization";
import ExperimentDetail from "./product/ExperimentDetail";
import VirtualCloneConstruct from "./product/VirtualCloneConstruct";
import GeneSynthesis from "./product/GeneSynthesis";
import Select from "react-select";

import ExperimentModal from "./Modal/ExperimentModal";
import useProductForm from "../hooks/useProductForm";
import GeneTable from "./GeneTable";
import ProteinGeneTable from "./ProteinGeneTable";
import tools from "../assets/data/tools";
import ExperimentGeneManager from "./NewUI/Experiment";
import LabManagementSystem from "./NewUI/Experiment";

const AddProductForm = () => {

  const { handleBack, handleSubmit,
    handleFileDrop,
    currentStep,
    productName,
    setProductName,
    stage,
    handleStageChange,
    objective,
    setObjective,
    teamLead,
    handleChange,
    users,
    selectedTeamMembers,
    handleTeamMembersChange,
    isTestProduct,
    handleSwitchChange,
    handleBrowseClick,
    fileInputRef,
    handleFileChange,
    files,
    getContainerStyles,
    imagesList,
    handleDeleteFile,
    selectedHost,
    handleHostChange,
    userId,
    isApproved,
    setIsApproved,
    handlePdfBrowseClick,
    pdfInputRef,
    handlePdfFileChange,
    pdfFiles,
    openPdf,
    handleDeletePdf,
    handleAddRow,
    showFields,
    rows,
    handleInputChange,
    handleSearchProtein,
    handleCodonOptimization,
    handleRemoveExperiment,
    isGeneTableOpen,
    geneOptions,
    currentRowIndex,
    handleSelectedGeneChange,
    handleCloseGeneTableModal,
    isLoading,
    isProteinTableOpen,
    proteinGeneOptions,
    handleSelectedProteinGeneChange,
    handleCloseProtienTableModal,
    handlePreviousStep,
    handleNextStep,
    isSubmitting,
    isExperimentModalOpen,
    handleSaveModal,
    handleCloseModal,
    type, setType,
    geneSequence, setGeneSequence,
    handleDataChange,
    geneData,
    codoneData,
    virtualCloneData,
    expLibraryData,
    strainEnggData,
    handleAddMore,
    handleRemoveExpItem,
    growthConditionData,
    isExpDetailsModal, handleSelectedExperiment,
    selectedExperiment,
    setSelectedExperiment,
    handleCloseExperimentDetailModal,
    setSelectedSequence,
    handleSetData,
    setSelectedExperimentIndex,
    handleSearch,
    selectedProteinGene,
    selectedGene,
    setIsProteinTableOpen,
    setIsGeneTableOpen,
    setRows,
    isGeneLoading
  } = useProductForm();

  const handleSave = (isMulti) => {
    if (type === "genes") {
      console.log('insideGeneSave')
      handleSaveModal(geneData, isMulti);
    } else if (type === "codone") {
      handleSaveModal(codoneData, isMulti);
    } else if (type === "virtualCodeConstruct") {
      handleSaveModal(virtualCloneData, isMulti);
    } else if (type === "expLibrary") {
      handleSaveModal(expLibraryData, isMulti)
    } else if (type === "strainEngg") {
      handleSaveModal(strainEnggData, isMulti);
    } else if (type === "growthCondition") {
      handleSaveModal(growthConditionData, isMulti);
    }
  }

  console.log(rows[currentRowIndex], 'rowIndwxRow')

  return (
    <>
      <div className="main-container mx-auto p-8 max-w-7xl">

        <button
          type="button"
          onClick={handleBack} // Add handler function for going back
          className="border-gray-400 border text-gray-600 px-2 py-1 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mb-4 text-sm w-auto"
          style={{ width: 'fit-content' }}
        >
          <ImageLoader name="left-arrow-active.svg" className="mr-1 h-4 w-3" />
          <span className="whitespace-nowrap">Back to products</span>
        </button>
        <h2 className="text-2xl font-semibold mb-4 sticky">Add Product</h2>

        <div className="flex flex-col min-h-screen">
          <form
            className="flex flex-col flex-grow"
            onSubmit={handleSubmit}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
          >
            <div className="flex-grow">
              {currentStep === 1 && (
                <ProductInfo
                  flag={0}
                  productNameAdd={productName}
                  setProductNameAdd={setProductName}
                  objectiveAdd={objective}
                  setObjectiveAdd={setObjective}
                  teamLeadAdd={teamLead}
                  handleChangeAdd={handleChange}
                  isTestProductAdd={isTestProduct}
                  getContainerStylesAdd={getContainerStyles}
                  imagesListAdd={imagesList}
                  usersAdd={users}
                  filesAdd={files}
                  handleStageChangeAdd={handleStageChange}
                  selectedTeamMembersAdd={selectedTeamMembers}
                  handleTeamMembersChangeAdd={handleTeamMembersChange}
                  handleSwitchChangeAdd={handleSwitchChange}
                  handleBrowseClickAdd={handleBrowseClick}
                  fileInputRefAdd={fileInputRef}
                  handleFileChangeAdd={handleFileChange}
                  handleDeleteFileAdd={handleDeleteFile}
                />

              )}
              {currentStep === 2 && (
                <ProductHost
                  flag={0}
                  teamLead={teamLead}
                  setIsApproved={setIsApproved}
                  hosts={hosts}
                  pdfFiles={pdfFiles}
                  selectedHostAdd={selectedHost}
                  handleHostChangeAdd={handleHostChange}
                  hostsAdd={hosts}
                  teamLeadAdd={teamLead}
                  userIdAdd={userId}
                  isApprovedAdd={isApproved}
                  setIsApprovedAdd={setIsApproved}
                  handlePdfBrowseClickAdd={handlePdfBrowseClick}
                  pdfInputRefAdd={pdfInputRef}
                  handlePdfFileChangeAdd={handlePdfFileChange}
                  pdfFilesAdd={pdfFiles}
                  openPdfAdd={openPdf}
                  handleDeletePdfAdd={handleDeletePdf}
                />
              )}


              {currentStep === 3 && (
                //           <div>
                //             <>
                //               {/* Buttons Section */}
                //               <div className="flex justify-end items-center mb-4">
                //                 <button
                //                   type="button"
                //                   onClick={handleAddRow}
                //                   className="text-green-600 hover:text-green-700 px-3 py-2 rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                //                 >
                //                   <ImageLoader name="plus-icon.svg" className="h-6 w-6 mr-2" alt="Add" title="Add" />
                //                   Add Experiment
                //                 </button>


                //               </div>
                //               {showFields && (
                //                 <div className="grid grid-cols-1 gap-4">
                //                   <div className="grid grid-cols-8 gap-4 items-center font-bold text-end">
                //                     <div className="flex items-center justify-center font-bold mb-1">Experiment Name</div>
                //                     <div className="flex items-center justify-center font-bold mb-1">Common Name</div>
                //                     <div className="flex items-center justify-center font-bold mb-1">Scientific Name</div>
                //                     {/*<div className="block font-bold mb-1">Gene</div>*/}
                //                     <div className="flex items-center justify-center font-bold mb-1">Protein</div>
                //                     <div className="flex items-center justify-center font-bold mb-1">Stage</div>
                //                     <div className="flex items-center justify-center font-bold mb-1">Experiment Details</div>
                //                     <div className="flex items-end justify-center font-bold mb-1">Gene</div>
                //                   </div>
                //                   {rows.map((row, index) => (
                //                     <div key={row.id} className="grid grid-cols-8 gap-4 items-center cursor-pointer hover:bg-gray-100 p-2 rounded">
                //                       {/* Experiment Name */}
                //                       <div className="input-container col-span-1">
                //                         {/* <label htmlFor={`experimentName-${row.id}`} className="block font-bold mb-1">
                //                           Experiment Name:
                //                         </label> */}
                //                         <input
                //                           type="text"
                //                           id={`experimentName-${row.id}`}
                //                           name="experimentName"
                //                           placeholder="Enter experiment name"
                //                           value={row.experimentName}
                //                           onChange={(e) => handleInputChange(row.id, e)}
                //                           required
                //                           className="border border-gray-300 p-2 rounded w-full"
                //                         />
                //                       </div>

                //                       {/* Common Name */}
                //                       <div className="input-container col-span-1">
                //                         {/* <label htmlFor={`commonName-${row.id}`} className="block font-bold mb-1">
                //                           Common Name:
                //                         </label> */}
                //                         <input
                //                           type="text"
                //                           id={`commonName-${row.id}`}
                //                           name="commonName"
                //                           placeholder="Enter common name"
                //                           value={row.commonName}
                //                           onChange={(e) => handleInputChange(row.id, e)}
                //                           required
                //                           className="border border-gray-300 p-2 rounded w-full"
                //                         />
                //                       </div>

                //                       {/* Scientific Name */}
                //                       <div className="input-container col-span-1">
                //                         {/* <label htmlFor={`scientificName-${row.id}`} className="block font-bold mb-1">
                //                           Scientific Name:
                //                         </label> */}
                //                         <input
                //                           type="text"
                //                           id={`scientificName-${row.id}`}
                //                           name="scientificName"
                //                           placeholder="Enter scientific name"
                //                           value={row.scientificName}
                //                           onChange={(e) => handleInputChange(row.id, e)}
                //                           required
                //                           className="border border-gray-300 p-2 rounded w-full"
                //                         />
                //                       </div>

                //                       {/* Target Gene */}
                //                       {/*<div className="input-container col-span-1 relative">*/}
                //                       {/*  /!* <label htmlFor={`targetGene-${row.id}`} className="block font-bold mb-1">*/}
                //                       {/*    Gene:*/}
                //                       {/*  </label> *!/*/}
                //                       {/*  /!*<div className="flex">*!/*/}
                //                       {/*  /!*  <input*!/*/}
                //                       {/*  /!*    type="text"*!/*/}
                //                       {/*  /!*    id={`targetGene-${row.id}`}*!/*/}
                //                       {/*  /!*    name="selectedGene"*!/*/}
                //                       {/*  /!*    placeholder="Search for a gene"*!/*/}
                //                       {/*  /!*    value={row.selectedGene}*!/*/}
                //                       {/*  /!*    onChange={(e) => handleInputChange(row.id, e)}*!/*/}
                //                       {/*  /!*    required*!/*/}
                //                       {/*  /!*    className="border border-gray-300 p-2 rounded-l w-full"*!/*/}
                //                       {/*  /!*  />*!/*/}
                //                       {/*  /!*  <button*!/*/}
                //                       {/*  /!*    type="button"*!/*/}
                //                       {/*  /!*    onClick={() => handleSearch(row.id)}*!/*/}
                //                       {/*  /!*    className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"*!/*/}
                //                       {/*  /!*    style={{*!/*/}
                //                       {/*  /!*      backgroundColor: '#6EB724',*!/*/}
                //                       {/*  /!*      borderRadius: '0 4px 4px 0',*!/*/}
                //                       {/*  /!*      width: '2.5rem',*!/*/}
                //                       {/*  /!*      height: '2.7rem',*!/*/}
                //                       {/*  /!*      marginTop: '1px'*!/*/}
                //                       {/*  /!*    }}*!/*/}
                //                       {/*  /!*  >*!/*/}
                //                       {/*  /!*    <ImageLoader*!/*/}
                //                       {/*  /!*      className="imgFirst img-fluid"*!/*/}
                //                       {/*  /!*      name="search-icon.svg"*!/*/}
                //                       {/*  /!*      alt="Search"*!/*/}
                //                       {/*  /!*      title="Search"*!/*/}
                //                       {/*  /!*      style={{ width: '1.25rem', height: '1.25rem' }}*!/*/}
                //                       {/*  /!*    />*!/*/}
                //                       {/*  /!*  </button>*!/*/}
                //                       {/*  /!*</div>*!/*/}
                //                       {/*</div>*/}

                //                       {/* Protein Gene */}
                //                       <div className="input-container col-span-1">
                //                         {/* <label htmlFor={`proteinGene-${row.id}`} className="block font-bold mb-1">
                //                           Protein:
                //                         </label> */}
                //                         <div className="flex items-center">
                //                           <div className="flex relative w-full">
                //                             <input
                //                               type="text"
                //                               id={`proteinGene-${row.id}`}
                //                               name="selectedProteinGene"
                //                               placeholder="Search for protein"
                //                               value={row.selectedProteinGene}
                //                               onChange={(e) => handleInputChange(row.id, e)}
                //                               required
                //                               className="border border-gray-300 p-2 rounded-l w-full"
                //                             />
                //                             <button
                //                               type="button"
                //                               onClick={() => handleSearchProtein(row.id)}
                //                               className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                //                               style={{
                //                                 backgroundColor: '#6EB724',
                //                                 borderRadius: '0 4px 4px 0',
                //                                 width: '2.5rem',
                //                                 height: '2.75rem',
                //                               }}
                //                             >
                //                               <ImageLoader
                //                                 className="imgFirst img-fluid"
                //                                 name="search-icon.svg"
                //                                 alt="Search"
                //                                 title="Search"
                //                                 style={{ width: '1.25rem', height: '1.25rem' }}
                //                               />
                //                             </button>
                //                           </div>

                //                         </div>
                //                       </div>
                //                       {/* Stage Exp */}
                //                       <div className="input-container col-span-1">
                //                         {/* <label htmlFor={`proteinGene-${row.id}`} className="block font-bold mb-1">
                //                           Protein:
                //                         </label> */}
                //                         <div className="flex items-center">
                //                           <div className="flex w-[150px]">
                //                             <select
                //                               id="exp_type"
                //                               name="exp_type"
                //                               className="border-none"
                //                               value={row.exp_type}
                //                               onChange={(e) => handleInputChange(row.id, e)}
                //                               required
                //                             >
                //                               {stages.map((stage) => (
                //                                 <option key={stage.value} value={stage.value}>
                //                                   {stage.label}
                //                                 </option>
                //                               ))}
                //                             </select>
                //                           </div>

                //                         </div>
                //                       </div>
                //                       {/* Exp Details*/}
                //                       <div className="input-container flex col-span-2 gap-3 ml-4">
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             handleSelectedExperiment(index);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-1.5 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Experiment Details
                //                         </a>
                //                         <a
                //                           href="#"
                //                           // onClick={(e) => {
                //                           //   e.preventDefault();
                //                           //   handleSelectedExperiment(index);
                //                           // }}
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('genes');
                //                             handleSetData(index)
                //                             handleCodonOptimization(row.id, row.experimentName, index);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Gene Details
                //                         </a>
                //                       </div>
                //                       {/* <div className="input-container col-span-6 flex gap-2 items-center justify-between">
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('genes');
                //                             handleCodonOptimization(row.id, row.experimentName);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Gene
                //                         </a>
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('codone');
                //                             handleCodonOptimization(row.id, row.experimentName);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Codone Optimizations
                //                         </a>
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('virtualCodeConstruct');
                //                             handleCodonOptimization(row.id, row.experimentName);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Virtual Code Construct
                //                         </a>
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('expLibrary');
                //                             handleCodonOptimization(row.id, row.experimentName);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Expression Library
                //                         </a>
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('strainEngg');
                //                             handleCodonOptimization(row.id, row.experimentName);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Strain Engineering
                //                         </a>
                //                         <a
                //                           href="#"
                //                           onClick={(e) => {
                //                             e.preventDefault();
                //                             setType('growthCondition');
                //                             handleCodonOptimization(row.id, row.experimentName);
                //                           }}
                //                           className="text-green-600 hover:text-green-700 w-full flex items-center justify-center px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                //                         >
                //                           Growth Condition
                //                         </a>
                //                         <button
                //                           type="button"
                //                           onClick={() => handleRemoveExperiment(row.id)}
                //                           className="p-2 border-none flex items-center justify-center"
                //                           style={{
                //                             backgroundColor: 'transparent',
                //                             color: '#FF0000',
                //                             width: '9.5rem',
                //                             height: '2.7rem',
                //                           }}
                //                         >
                //                           <ImageLoader
                //                             className="imgFirst img-fluid"
                //                             name="crossGray copy.svg"
                //                             alt="Remove"
                //                             title="Remove"
                //                             style={{ width: '1.25rem', height: '1.25rem' }}
                //                           />
                //                         </button>
                //                       </div> */}

                //                     </div>
                //                     // </div>
                //                   ))}

                //                   {/* Collapsible Gene Table */}
                //                   {isGeneTableOpen && (
                //                     <GeneTable
                //                       genes={geneOptions}
                //                       selectedGene={rows[currentRowIndex]?.selectedGene || ''}
                //                       onSelectedGeneChange={(geneId) => handleSelectedGeneChange(geneId, currentRowIndex)}
                //                       // onCloseTable={() => setIsGeneTableOpen(false)}
                //                       onClose={handleCloseGeneTableModal}
                //                       isLoading={isLoading}
                //                     />
                //                   )}

                //                   {/* {selectedProduct && (
                //   <EditProductForm
                //     product={selectedProduct}
                //     productPhotos={productPhotos}
                //     productPdfs={productPdfs}
                //     onClose={handleCloseEditModal}
                //     onProductEdited={onProductEdited} />
                // )} */}

                //                   {/* Collapsible Protein Gene Table */}
                //                   {isProteinTableOpen && (
                //                     <div className="relative">
                //                       <ProteinGeneTable
                //                         proteingenes={proteinGeneOptions}
                //                         selectedProteinGene={rows[currentRowIndex]?.selectedProteinGene || ''}
                //                         onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChange(proteinGeneId, currentRowIndex)}
                //                         onClose={handleCloseProtienTableModal}
                //                         isLoading={isLoading}
                //                       />
                //                     </div>
                //                   )}

                //                 </div>
                //               )}
                //             </>
                //           </div>
                <LabManagementSystem
                  rows={rows}
                  handleAddRow={handleAddRow}
                  handleRemoveExperiment={handleRemoveExperiment}
                  handleInputChange={handleInputChange}
                  handleSelectedExperiment={handleSelectedExperiment}
                  geneData={geneData}
                  codoneData={codoneData}
                  virtualCloneData={virtualCloneData}
                  experiment={selectedExperiment}
                  expLibraryData={expLibraryData}
                  strainEnggData={strainEnggData}
                  growthConditionData={growthConditionData}
                  handleSave={handleSave}
                  handleDataChange={handleDataChange}
                  handleCloseModal={handleCloseModal}
                  handleAddMore={handleAddMore}
                  type={type}
                  setType={setType}
                  handleRemoveExpItem={handleRemoveExpItem}
                  setSelectedExperimentIndex={setSelectedExperimentIndex}
                  handleSetData={handleSetData}
                  isEdit={false}
                  handleSearch={handleSearch}
                  handleSearchProtein={handleSearchProtein}
                />
              )}
            </div>

            {isExpDetailsModal && selectedExperiment && (
              <ExperimentDetail
                flag={1}
                selectedExperiment={selectedExperiment}
                setSelectedExperiment={setSelectedExperiment}
                handleCloseExperimentDetailModal={handleCloseExperimentDetailModal}
                rows={rows}
                setRows={setRows}
              />
            )}

            {/* Buttons Section at the Bottom */}
            <div className="sticky bottom-0 left-0 right-0 bg-white p-4 flex justify-end items-center">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mr-4"
                >
                  <ImageLoader name="left-arrow-active.svg" className="mr-2 h-6 w-4" />
                  Previous
                </button>
              )}

              {currentStep < 3 && (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className={`border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center ${currentStep > 1 ? 'mr-4' : ''}`}
                >
                  <ImageLoader name="right-arrow-active.svg" className="mr-2 h-6 w-4" />
                  Next
                </button>
              )}

              {currentStep === 3 && (
                <button
                  type="submit"
                  className="text-white px-4 py-2 rounded flex items-center"
                  disabled={isSubmitting}
                  style={{ background: '#7FB541', borderColor: 'transparent' }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>



          </form>

        </div>

      </div>
      <Footer></Footer>
      {isExperimentModalOpen && (
        <ExperimentModal
          type={type}
          geneData={geneData}
          rows={rows}
          codoneData={codoneData}
          virtualCloneData={virtualCloneData}
          experiment={selectedExperiment}
          expLibraryData={expLibraryData}
          strainEnggData={strainEnggData}
          growthConditionData={growthConditionData}
          handleSaveModal={handleSaveModal}
          handleDataChange={handleDataChange}
          handleCloseModal={handleCloseModal}
          handleAddMore={handleAddMore}
          handleRemoveExpItem={handleRemoveExpItem}
          geneSequnce={geneSequence}
          setGeneSequence={setGeneSequence}
          setSelectedSequence={setSelectedSequence}
          changeType={setType}
        />
      )}

      {
        isGeneTableOpen && <GeneTable
          selectedGene={selectedGene}
          isLoading={isGeneLoading}
          genes={geneOptions}
          onClose={() => setIsGeneTableOpen(false)}
          onSelectedGeneChange={(geneId) => handleSelectedGeneChange(geneId, currentRowIndex)}
        />
      }

      {
        isProteinTableOpen && <ProteinGeneTable proteingenes={proteinGeneOptions}
          // selectedProteinGene={selectedProtein}
          onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChange(proteinGeneId, currentRowIndex)}
          onClose={() => setIsProteinTableOpen(false)} />
      }


    </>

  );

};


export default AddProductForm;
