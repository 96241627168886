export const virtualCloneTypes = [
    { value: "forCloning", label: "For Cloning" },
    { value: "forExpression", label: "For Expression" },
];

export const constructTypes = [{
    value: "plasmid",
    label: "Plasmid",
},
{
    value: "expression",
    label: "Expression",
}
];


export const experimentTypes = [
    { value: "transformation for plasmid library", label: "Transformation For Plasmid Library" },
    { value: "transformation for expression vector library", label: "Transformation for Expression Vector Library" },
    { value: "transformation of the expression host", label: "Transformation of the Expression Host" },
]