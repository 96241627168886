import React, { useRef, useState } from 'react';
import Select from 'react-select';
import tools from '../../assets/data/tools';
import { constructTypes, experimentTypes, virtualCloneTypes } from "../../assets/data/cloneType";
import ImageLoader from "../ImageLoader";
import { IoCloseOutline } from 'react-icons/io5';
import { Breadcrumbs } from './Breadcrumbs';


const ExperimentModal = ({ type, currentExperimentName, handleSaveModal, tool, setTool, caiScore, setCaiScore, gcPercentage, setGcPercentage, optimizedSequence, setOptimizedSequence, teamLead,
    userId,
    rows,
    selectedSequence,
    changeType,

    setSelectedSequence, geneSequence, setGeneSequence,
    handleCloseModal, handleDataChange, geneData, codoneData, virtualCloneData, expLibraryData, handleAddMore, handleRemoveExpItem, strainEnggData, growthConditionData }) => {


    const [stages, setStages] = useState(["Genes", "Codones", "Virtual Clone", "Exp Library", "Strain", "Growth Condition"])

    const handleSave = (isMulti) => {
        if (type == "genes") {
            handleSaveModal(geneData, isMulti);
        } else if (type == "codone") {
            handleSaveModal(codoneData, isMulti);
        } else if (type == "virtualCodeConstruct") {
            handleSaveModal(virtualCloneData, isMulti);
        } else if (type == "expLibrary") {
            handleSaveModal(expLibraryData, isMulti)
        } else if (type == "strainEngg") {
            handleSaveModal(strainEnggData, isMulti);
        } else if (type == "growthCondition") {
            handleSaveModal(growthConditionData, isMulti);
        }
    }



    const handleNext = () => {
        handleSave(true)
        console.log("clickedNext")
        if (type === 'genes') {

            changeType("codone");
            // setStages([...stages, "Codone"])
        }
        if (type === "codone") {
            changeType("virtualCodeConstruct");
            // setStages([...stages, "Virtual Clone"])
        }
        if (type === "virtualCodeConstruct") {
            changeType("expLibrary");
            // setStages([...stages, "Exp Library"])
        }
        if (type === "expLibrary") {
            changeType("strainEngg");
            // setStages([...stages, "Strain"])
        }
        if (type === "strainEngg") {
            changeType("growthCondition");
            // setStages([...stages, "Growth Condition"])
        }
    }

    const handlePrev = () => {
        console.log("clickedPrev")
        if (type === "growthCondition") {
            changeType("strainEngg")
            // setStages(prev => prev.slice(0, -1)) // Remove last stage
        }
        if (type === "strainEngg") {
            changeType("expLibrary")
            // setStages(prev => prev.slice(0, -1))
        }
        if (type === "expLibrary") {
            changeType("virtualCodeConstruct")
            // setStages(prev => prev.slice(0, -1))
        }
        if (type === "virtualCodeConstruct") {
            changeType("codone")
            // setStages(prev => prev.slice(0, -1))
        }
        if (type === "codone") {
            changeType("genes")
            // setStages(prev => prev.slice(0, -1))
        }
    }

    const handleBreadcrumbClick = (clickedStage) => {
        // Map stages to their corresponding types
        const stageToType = {
            "Genes": "genes",
            "Codones": "codone",
            "Virtual Clone": "virtualCodeConstruct",
            "Exp Library": "expLibrary",
            "Strain": "strainEngg",
            "Growth Condition": "growthCondition"
        };

        // Find the index of clicked stage
        const clickedIndex = stages.findIndex(stage => stage === clickedStage);

        // Update stages array to include only up to clicked stage
        // setStages(prev => prev.slice(0, clickedIndex + 1));

        // Change type to corresponding type
        changeType(stageToType[clickedStage]);
    }




    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 py-10">
            <div className="bg-white h-full overflow-y-auto p-8 rounded-lg w-1/2">
                <h2 className="text-xl font-bold">{currentExperimentName}</h2>
                <Breadcrumbs items={stages} type={type} onBreadcrumbClick={handleBreadcrumbClick} />

                {type == 'genes' && geneData.map((gene, index) => (
                    <React.Fragment key={index}>

                        <div className="flex w-full justify-between">
                            <p className='font-bold text-xl border-b-2 border-black mb-3'>Gene Number - {index + 1}</p>
                            {index !== 0 && <button
                                type="button"
                                onClick={() => handleRemoveExpItem(index)}
                                className="border-none flex items-center justify-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF0000',
                                    width: '9.5rem',
                                    height: '0.7rem',
                                }}
                            >
                                <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="crossGray copy.svg"
                                    alt="Remove"
                                    title="Remove"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                            </button>}
                        </div>
                        <div className="mb-4">
                            <div className="col-span-2">
                                <label htmlFor="tool" className="block text-black-700 font-bold mb-1">
                                    Gene Sequence
                                </label>
                                <input
                                    type="number"
                                    name="geneSequence"
                                    className="border border-gray-300 p-2 rounded w-full"
                                    value={gene?.geneSequence}
                                    onChange={(e) => handleDataChange(e, false, index)}
                                    placeholder="Enter Gene Sequence"
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Company</label>
                            <input
                                type="text"
                                className="border border-gray-300 p-2 rounded w-full"
                                name="company"
                                value={gene?.company}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Company"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Order Date</label>
                            <input
                                type="date"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={gene?.orderDate}
                                name="orderDate"
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Order Date"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Receipt Date</label><input
                                type="date"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={gene?.receiptDate}
                                name="receiptDate"
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Receipt Date"
                            />
                            {/*<textarea*/}
                            {/*    className="border border-gray-300 p-2 rounded w-full"*/}
                            {/*    value={optimizedSequence}*/}
                            {/*    onChange={(e) => setOptimizedSequence(e.target.value)}*/}
                            {/*    placeholder="Paste optimised sequence"*/}
                            {/*    onKeyDown={(e) => {*/}
                            {/*      // Allow only Ctrl+V (paste), Ctrl+Z (undo), and navigation keys (e.g., arrows, Home, End)*/}
                            {/*      if (*/}
                            {/*          (e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'z') || // Allow Ctrl+V and Ctrl+Z*/}
                            {/*          e.key === 'Backspace' || e.key === 'Delete' || // Allow Backspace and Delete for clearing*/}
                            {/*          e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || // Allow arrow keys for navigation*/}
                            {/*          e.key === 'Home' || e.key === 'End' // Allow Home and End for navigation*/}
                            {/*      ) {*/}
                            {/*        return; // Allow these keys*/}
                            {/*      } else {*/}
                            {/*        e.preventDefault(); // Prevent all other typing*/}
                            {/*      }*/}
                            {/*    }}*/}
                            {/*    onPaste={(e) => {*/}
                            {/*      e.stopPropagation(); // Allow pasting*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                    </React.Fragment>
                ))
                }
                {type == 'codone' && codoneData?.map((codone, index) => (
                    <React.Fragment key={index}>
                        <div className="flex w-full justify-between">
                            <p className='font-bold text-xl border-b-2 border-black mb-3'>Codone Optimization Number - {index + 1}</p>
                            {index !== 0 && <button
                                type="button"
                                onClick={() => handleRemoveExpItem(index)}
                                className="border-none flex items-center justify-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF0000',
                                    width: '9.5rem',
                                    height: '0.7rem',
                                }}
                            >
                                <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="crossGray copy.svg"
                                    alt="Remove"
                                    title="Remove"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                            </button>}
                        </div>
                        <div className="mb-4">
                            <h2 className="text-xl font-bold">{currentExperimentName}</h2>
                            <div className="mb-4">
                                <label htmlFor={`gene-select-${index}`} className="block text-black-700 font-bold mb-1">
                                    Select Gene
                                </label>
                                <Select
                                    id={`gene-select-${index}`}
                                    name="geneId"
                                    placeholder="Select Gene"
                                    value={geneData.find(gene => gene.mapId === codone.geneId)
                                        ? {
                                            value: codone.geneId,
                                            label: geneData.find(gene => gene.mapId === codone.geneId)?.geneSequence
                                        }
                                        : null
                                    }
                                    onChange={(option) => handleDataChange({
                                        name: "geneId",
                                        value: option.value
                                    }, true, index)}
                                    options={geneData.map(gene => ({ value: gene.mapId, label: gene.geneSequence }))}
                                    required
                                />
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="tool" className="block text-black-700 font-bold mb-1">
                                    Tools
                                </label>
                                <Select
                                    id="tool"
                                    name="tool"
                                    value={tools.find(option => option.value === codoneData[index].tool)}
                                    onChange={(option) => handleDataChange({
                                        name: "tool",
                                        value: option.value
                                    }, true, index)}
                                    options={tools}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">CAI score</label>
                            <input
                                type="number"
                                name="caiScore"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={codone.caiScore}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter CAI score"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">GC%</label>
                            <input
                                type="number"
                                name="gcPercentage"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={codone.gcPercentage}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter GC percentage"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Optimised sequence</label>
                            <textarea
                                name="optimizedSequence"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={codone.optimizedSequence}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Paste optimised sequence"
                                type="text"
                                onKeyDown={(e) => {
                                    // Allow only Ctrl+V (paste), Ctrl+Z (undo), and navigation keys (e.g., arrows, Home, End)
                                    if (
                                        (e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'z') || // Allow Ctrl+V and Ctrl+Z
                                        e.key === 'Backspace' || e.key === 'Delete' || // Allow Backspace and Delete for clearing
                                        e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || // Allow arrow keys for navigation
                                        e.key === 'Home' || e.key === 'End' // Allow Home and End for navigation
                                    ) {
                                        return; // Allow these keys
                                    } else {
                                        e.preventDefault(); // Prevent all other typing
                                    }
                                }}
                                onPaste={(e) => {
                                    e.stopPropagation(); // Allow pasting
                                }}
                            />
                        </div>
                        {teamLead?.value == userId && (
                            <div className="mb-6">
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="selectedSequence"
                                        name="selectedSequence"
                                        checked={selectedSequence}
                                        onChange={(e) => setSelectedSequence(e.target.checked)}
                                    />
                                    <label htmlFor="selectedSequence" className="block text-gray-700 font-bold mb-2">Selected
                                        sequence</label>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))
                }
                {type == 'virtualCodeConstruct' && virtualCloneData.map((virtualClone, index) => (
                    <React.Fragment key={index}>
                        <div className="flex w-full justify-between">
                            <p className='font-bold text-xl border-b-2 border-black mb-3'>Virtual Clone Number - {index + 1}</p>
                            {index !== 0 && <button
                                type="button"
                                onClick={() => handleRemoveExpItem(index)}
                                className="border-none flex items-center justify-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF0000',
                                    width: '9.5rem',
                                    height: '0.7rem',
                                }}
                            >
                                <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="crossGray copy.svg"
                                    alt="Remove"
                                    title="Remove"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                            </button>}
                        </div>
                        <div className="mb-4">
                            <h2 className="text-xl font-bold">{currentExperimentName}</h2>
                            <div className="col-span-2">
                                <label htmlFor="tool" className="block text-black-700 font-bold mb-1">
                                    Clone Type
                                </label>

                                <Select
                                    id="cloneType"
                                    name="cloneType"
                                    value={virtualCloneTypes.find(option => option.value === tool)}
                                    onChange={(option) => handleDataChange({
                                        name: "cloneType",
                                        value: option.value
                                    }, true, index)}
                                    options={virtualCloneTypes}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-xl font-bold">{currentExperimentName}</h2>
                            <div className="mb-4">
                                <label htmlFor={`codone-select-${index}`} className="block text-black-700 font-bold mb-1">
                                    Select Codone Optimization
                                </label>
                                <Select
                                    id={`codone-select-${index}`}
                                    name="codoneId"
                                    placeholder="Select Codone"
                                    value={codoneData.find(codone => codone.mapId === virtualClone.codoneId)
                                        ? {
                                            value: virtualClone.codoneId,
                                            label: codoneData.find(codone => codone.mapId === virtualClone.codoneId)?.caiScore
                                        }
                                        : null
                                    }
                                    onChange={(option) => handleDataChange({
                                        name: "codoneId",
                                        value: option.value
                                    }, true, index)}
                                    options={codoneData.map(codone => ({ value: codone.mapId, label: codone.caiScore }))}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Clone Size</label>
                            <input
                                type="number"
                                name="cloneSize"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={virtualClone.cloneSize}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Clone Size"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Cloning Sites</label>
                            <input
                                type="text"
                                name="cloningSites"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={virtualClone.cloningSites}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Cloning Sites"
                            />
                        </div>
                    </React.Fragment>
                ))
                }
                {type == 'expLibrary' && expLibraryData?.map((expLib, index) => (
                    <>
                        <div className="flex w-full justify-between">
                            <p className='font-bold text-xl border-b-2 border-black mb-3'>Expression Number - {index + 1}</p>
                            {index !== 0 && <button
                                type="button"
                                onClick={() => handleRemoveExpItem(index)}
                                className="border-none flex items-center justify-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF0000',
                                    width: '9.5rem',
                                    height: '0.7rem',
                                }}
                            >
                                <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="crossGray copy.svg"
                                    alt="Remove"
                                    title="Remove"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                            </button>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor={`clone-select-${index}`} className="block text-black-700 font-bold mb-1">
                                Select Virtual Clone Construct
                            </label>
                            <Select
                                id={`clone-select-${index}`}
                                name="cloneConstructId"
                                value={virtualCloneData.find(clone => clone.mapId === expLib.cloneConstructId)
                                    ? {
                                        value: expLib.cloneConstructId,
                                        label: virtualCloneData.find(clone => clone.mapId === expLib.cloneConstructId)?.cloneSize
                                    }
                                    : null
                                }
                                onChange={(option) => handleDataChange({
                                    name: "cloneConstructId",
                                    value: option.value
                                }, true, index)}
                                options={virtualCloneData.map(clone => ({ value: clone.mapId, label: clone.cloneSize }))}
                                required
                            />

                        </div>
                        <div className="mb-4">
                            <h2 className="text-xl font-bold">{currentExperimentName}</h2>
                            <div className="col-span-2">
                                <label htmlFor="tool" className="block text-black-700 font-bold mb-1">
                                    Construct Type
                                </label>
                                <Select
                                    id="expType"
                                    name="expType"
                                    value={constructTypes.find(option => option.value === tool)}
                                    onChange={(option) => handleDataChange({
                                        name: "expType",
                                        value: option.value
                                    }, true, index)}
                                    options={constructTypes}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Plasmid Name</label>
                            <input
                                type="text"
                                name="plasmidName"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={expLib.plasmidName}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Plasmid Name"
                            />
                        </div>
                    </>
                ))
                }
                {type == "strainEngg" && strainEnggData.map((strEng, index) => (
                    <React.Fragment key={index}>
                        <div className="flex w-full justify-between">
                            <p className='font-bold text-xl border-b-2 border-black mb-3'>Strain Number - {index + 1}</p>
                            {index !== 0 && <button
                                type="button"
                                onClick={() => handleRemoveExpItem(index)}
                                className="border-none flex items-center justify-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF0000',
                                    width: '9.5rem',
                                    height: '0.7rem',
                                }}
                            >
                                <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="crossGray copy.svg"
                                    alt="Remove"
                                    title="Remove"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                            </button>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor={`expLib-select-${index}`} className="block text-black-700 font-bold mb-1">
                                Select Expression Library
                            </label>
                            <Select
                                id={`expLib-select-${index}`}
                                name="expLibId"
                                value={expLibraryData.find(expLib => expLib.mapId === strEng.expLibId)
                                    ? {
                                        value: strEng.expLibId,
                                        label: expLibraryData.find(expLib => expLib.mapId === strEng.expLibId)?.plasmidName
                                    }
                                    : null
                                }
                                onChange={(option) => handleDataChange({
                                    name: "expLibId",
                                    value: option.value
                                }, true, index)}
                                options={expLibraryData.map(expLib => ({ value: expLib.mapId, label: expLib.plasmidName }))}
                                required
                            />

                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Strain Name</label>
                            <input
                                type="text"
                                name="strain_name"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={strEng.strain_name}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Strain Name"
                            />
                        </div>
                    </React.Fragment>
                ))}

                {type == "growthCondition" && growthConditionData.map((grwCond, index) => (
                    <React.Fragment key={index}>
                        <div className="flex w-full justify-between">
                            <p className='font-bold text-xl border-b-2 border-black mb-3'>Growth Condition Number - {index + 1}</p>
                            {index !== 0 && <button
                                type="button"
                                onClick={() => handleRemoveExpItem(index)}
                                className="border-none flex items-center justify-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF0000',
                                    width: '9.5rem',
                                    height: '0.7rem',
                                }}
                            >
                                <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="crossGray copy.svg"
                                    alt="Remove"
                                    title="Remove"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                            </button>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor={`strain-select-${index}`} className="block text-black-700 font-bold mb-1">
                                Select Strain
                            </label>
                            <Select
                                id={`strain-select-${index}`}
                                name="strainId"
                                value={strainEnggData.find(strn => strn.mapId === grwCond.strainId)
                                    ? {
                                        value: grwCond.strainId,
                                        label: strainEnggData.find(strn => strn.mapId === grwCond.strainId)?.strain_name
                                    }
                                    : null
                                }
                                onChange={(option) => handleDataChange({
                                    name: "strainId",
                                    value: option.value
                                }, true, index)}
                                options={strainEnggData.map(strn => ({ value: strn.mapId, label: strn.strain_name }))}
                                required
                            />

                        </div>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Growth Condition</label>
                            <input
                                type="text"
                                name="grow_condition_name"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={grwCond.grow_condition_name}
                                onChange={(e) => handleDataChange(e, false, index)}
                                placeholder="Enter Growth Condition Name"
                            />
                        </div>
                    </React.Fragment>
                ))}
                <div className="flex justify-end">
                    {type !== 'expDetails' && <button
                        type="button"
                        className="bg-red-400 text-white py-2 px-4 rounded mr-2"
                        onClick={handleAddMore}
                    >
                        Add More
                    </button>}
                    <button
                        type="button"
                        className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => handleSave()}
                        className="p-2 w-24 font-bold text-white rounded mr-2"
                        style={{
                            background: '#7FB541',
                            borderColor: 'transparent',
                        }}
                    >
                        Save
                    </button>
                    {type !== 'genes' && <button
                        type="button"
                        className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                        onClick={handlePrev}
                    >
                        Prev
                    </button>}
                    {type !== 'growthCondition' && <button
                        type="button"
                        className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                        onClick={handleNext}
                    >
                        Next
                    </button>}
                </div>
            </div>
        </div>
    )
}

export default ExperimentModal