import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, updateProduct } from "../redux/productsSlice";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ImageLoader from "./ImageLoader";
import { PiTrash } from "react-icons/pi";
import { nanoid } from 'nanoid'
import { useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
import AppConfig from "../AppConfig";
import stages from "../assets/data/stage";
import hosts from "../assets/data/host";
import tools from "../assets/data/tools";
import { useLocation } from 'react-router-dom';
import Footer from "./Footer";
import { BiDotsVerticalRounded } from "react-icons/bi";
// import { toastNotifyError, toastNotifySucess } from '../slices/toast';
import { toast } from 'sonner';
import ProductInfo from "./product/ProductInfo";
import ProductHost from "./product/ProductHost";
import ProductExperiments from "./product/ProductExperiments";
import CodonOptimization from "./product/CodonOptimization";
import ExperimentDetail from "./product/ExperimentDetail";
import TeamMemberModal from "./product/TeamMemberModal";
import DeleteModal from "./product/DeleteModal";
import SelectedPdfUrl from "./product/SelectedPdfUrl";
import VirtualCloneConstruct from "./product/VirtualCloneConstruct";
import GeneSynthesis from "./product/GeneSynthesis";
import { reconstructNestedData } from "../utils/nesting";
import ExperimentEdit from "./NewUI/ExperimentEdit";
import GeneTable from "./GeneTable";
import ProteinGeneTable from "./ProteinGeneTable";

const EditProductForm = () => {
	const location = useLocation();
	const { product, productPhotos } = location.state || {};
	const [currentStep, setCurrentStep] = useState(1);
	const [editedProduct, setEditedProduct] = useState(product);
	const [selectedStage, setSelectedStage] = useState(null);
	const [selectedHost, setSelectedHost] = useState(product?.host || null);
	const [files, setFiles] = useState([]);
	const [virtualCloneConstructFiles, setVirtualCloneConstructFiles] = useState({});
	const [geneSynthesisFiles, setGeneSynthesisFiles] = useState({});
	const [pdfGeneFiles, setPdfGeneFiles] = useState([]);
	const [pdfFiles, setPdfFiles] = useState([]);
	const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete confirmation modal
	const [isApproved, setIsApproved] = useState(editedProduct.is_approved || true); // State to track product approval
	const [isSubmitting, setIsSubmitting] = useState(false);
	const fileInputRef = useRef(null);
	const pdfInputRef = useRef(null);
	const pdfInputRefGene = useRef(null);
	const users = useSelector((store) => store.users);
	const userId = localStorage.getItem('userId');
	const [selectedPdfUrl, setSelectedPdfUrl] = useState(null); // State for selected PDF URL
	const [geneOptions, setGeneOptions] = useState([]);
	const [geneSequence, setGeneSequence] = useState([]);
	const [isGeneTableOpen, setIsGeneTableOpen] = useState(false);
	const [isProteinTableOpen, setIsProteinTableOpen] = useState(false);
	const [proteinGeneOptions, setProteinGeneOptions] = useState([]);
	const [selectedGene, setSelectedGene] = useState(editedProduct.gene);
	const [currentRowIndex, setCurrentRowIndex] = useState(null);
	const [isOptionsVisible, setOptionsVisible] = useState(false);
	const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
	const [isExperimentDetailModalOpen, setIsExperimentDetailModalOpen] = useState(false);
	const [currentRowId, setCurrentRowId] = useState(null);
	const [currentExperimentName, setCurrentExperimentName] = useState('');
	const [selectedExperiment, setSelectedExperiment] = useState({});
	const [isGeneLoadOpen, setIsGeneLoadOpen] = useState(false);
	const [isVirtualCloneConstructModalOpen, setIsVirtualCloneConstructModalOpen] = useState(false);
	const [isGeneSynthesisModalOpen, setIsGeneSythesisModalOpen] = useState(false);


	//NEW iMPLEMENTAION
	const [selectedExperimentIndex, setSelectedExperimentIndex] = useState(0);
	const [rows, setRows] = useState(product.product_experiments);
	const [geneData, setGeneData] = useState([]);
	const [codoneData, setCodoneData] = useState([]);
	const [virtualCloneData, setVirtualCloneData] = useState([]);
	const [expLibraryData, setExpLibraryData] = useState([]);
	const [strainEnggData, setStrainEnggData] = useState([]);
	const [growthConditionData, setGrowthConditionData] = useState([])
	const [type, setType] = useState("");
	const [isGeneLoading, setIsGeneLoading] = useState(false);


	const navigate = useNavigate();
	const containerStyles = {
		width: "300px",
		height: "280px",
		margin: "0 auto",
	};
	const toggleOptions = () => {
		setOptionsVisible(!isOptionsVisible);
	};
	const openPdf = (pdf) => {
		const pdfUrl = `${AppConfig.LAB_API_URL}/pdfs/download/${pdf}`;
		setSelectedPdfUrl(pdfUrl);
	};
	const selectedOpenPdf = (pdf) => {
		const pdfUrl = URL.createObjectURL(pdf);
		setSelectedPdfUrl(pdfUrl);

	};

	const [selectedTeamMembers, setSelectedTeamMembers] = useState(product.teamMembers.map(id =>
		({ value: id, label: users.find(user => user.id === id)?.name })));
	const dispatch = useDispatch();
	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);

		// Filter out files that are not jpeg, png, or webp
		const allowedFiles = selectedFiles.filter(
			(file) =>
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/webp"
		);

		// Limit the number of files to a maximum of 10
		if (allowedFiles.length > 10) {
			alert("Maximum 10 files allowed.");
			return;
		}

		setFiles([...files, ...allowedFiles]);
		fileInputRef.current.value = null; // Clear the input value

	};

	const handlePdfFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		const allowedFiles = selectedFiles.filter((file) => file.type === "application/pdf");

		if (allowedFiles.length > 10) {
			alert("Maximum 10 PDF files allowed.");
			return;
		}
		setPdfFiles([...pdfFiles, ...allowedFiles]);
		pdfInputRef.current.value = null; // Clear the input value
	};

	const handleDeleteFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};
	const handleDeletePdf = (index) => {
		setPdfFiles((prevPdfFiles) => prevPdfFiles.filter((_, i) => i !== index));
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		const droppedFiles = Array.from(e.dataTransfer.files);

		// Filter out files that are not jpeg, png, or webp
		const allowedFiles = droppedFiles.filter(
			(file) =>
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/webp"
		);

		// Limit the number of files to a maximum of 10
		if (files.length + allowedFiles.length > 10) {
			alert("Maximum 10 files allowed.");
			return;
		}

		setFiles([...files, ...allowedFiles]);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
	};

	const handleBrowseClick = () => {
		fileInputRef.current.click();
	};

	const handlePdfBrowseClick = () => {
		if (pdfInputRef.current) {
			pdfInputRef.current.click();
		}
	};


	const handleSwitchChange = (checked) => {
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			test_product: checked ? 1 : 0,
		}));
	};
	const handleIsApproved = (e) => {
		const { checked } = e.target;
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			is_approved: checked,
		}));
		setIsApproved(checked);
	};


	const handleToggleTeamMember = (memberId, e) => {
		e.stopPropagation();

		setEditedProduct((prevProduct) => ({
			...prevProduct,
			teamMembers: prevProduct.teamMembers.includes(memberId)
				? prevProduct.teamMembers.filter((id) => id !== memberId)
				: [...prevProduct.teamMembers, memberId],
		}));
	};
	const handleTeamLeadChange = (selectedOption) => {
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			team_lead: selectedOption.value,
		}));
	};

	const handleTeamMembersChange = (selectedOptions) => {
		setSelectedTeamMembers(selectedOptions);
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			teamMembers: selectedOptions.map((option) => option.value),
		}));
	};
	useEffect(() => {
		const foundStage = stages.find((stage) => stage.value === editedProduct.stage);
		if (foundStage) {
			setSelectedStage(foundStage);
		}
		//..console.log("editedProduct", editedProduct);
	}, [editedProduct, stages]);

	useEffect(() => {

		try {
			let codonoptimizations = selectedExperiment?.codon_optimizations;
			const parsedCodonOptimization = JSON.parse(codonoptimizations) ?? [];
			setEditedProduct(e => e.codon_optimizations = parsedCodonOptimization);

			const parsedVirtualCloneImages = JSON.parse(selectedExperiment?.clone_images) ?? [];
			setVirtualCloneConstructFiles(parsedVirtualCloneImages);
		} catch (error) {

		}

	}, [selectedExperiment]);

	useEffect(() => {
		if (editedProduct.gene) {
			fetchGeneData(editedProduct.gene);
		} else {
			setGeneOptions([]);
		}

		if (editedProduct.protein) {
			fetchProteinGeneData(editedProduct.protein);
		} else {
			setProteinGeneOptions([]);
		}
	}, []);

	useEffect(() => {
		updateExperimentInProduct(selectedExperiment);
	}, [selectedExperiment]);

	const updateExperimentInProduct = (experiment) => {
		const updatedExperiments = editedProduct.product_experiments.map((exp) => {
			if (exp.id === experiment.id) {
				return { ...experiment };
			}
			return exp;
		});
		setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
	}



	const fetchGeneSequence = async (geneId, index) => {
		try {
			setIsGeneLoadOpen(true);
			const toastId = toast.loading('Fetching Gene Sequence, Please Wait...')
			// Step 1: Fetch nucleotide_id
			const responseNucleotide = await fetch(`https://pf.charaka.tech/gene?geneId=${geneId}`, {
				method: 'POST',
			});
			const dataNucleotide = await responseNucleotide.json();

			// Ensure the response is successful and contains the necessary data
			if (dataNucleotide.status && dataNucleotide.data) {
				const nucleotideId = dataNucleotide.data.nucleotide_id;

				if (nucleotideId && nucleotideId !== '') {
					const responseFasta = await fetch(`https://pf.charaka.tech/fasta?nucleotide_id=${nucleotideId}`, {
						method: 'POST',
					});
					const fastaData = await responseFasta.json();
					let newGeneArr = [...geneData]
					let geneIndex = newGeneArr.findIndex((gene) => geneId == gene.gene)
					let newGene = { ...newGeneArr[geneIndex], gene: geneId, gene_sequence: fastaData.data.fasta }
					newGeneArr[geneIndex] = newGene;

					console.log(newGeneArr, 'gen1', geneIndex, newGene)
					setGeneData(newGeneArr);
					toast.dismiss(toastId)
					toast.success('Gene Sequence is set')
					setIsGeneLoading(false);
					setIsGeneTableOpen(false);
				} else {
					toast.dismiss(toastId);
					toast.error("Error: Nucleotide ID not found in the response.")
				}

			} else {
				console.error("Error: Nucleotide ID not found in the response.");
			}
		} catch (error) {
			console.error("Error fetching gene sequence:", error);
		} finally {
			setIsGeneLoadOpen(false);

		}
	};

	const fetchGeneData = async (gene) => {

		try {
			const response = await fetch(`${AppConfig.pfCharakaApiUrl}/gene?term=${encodeURIComponent(gene)}`);
			const data = await response.json();
			if (data.status && data.data && data.data.rows) {
				const filteredRows = data.data.rows.filter(row =>
					row.data.id.label.trim() !== '' && row.data.description.label.trim() !== ''
				);
				setGeneOptions(filteredRows);
				setIsGeneLoading(false);

			} else {
				setGeneOptions([]);
			}
		}
		catch (error) {
			console.error('Error fetching gene data:', error);
		}
	};
	const fetchProteinGeneData = async (proteinGene) => {
		if (proteinGene) {
			try {
				const response = await fetch(`${AppConfig.pfCharakaApiUrl}/protein?term=${encodeURIComponent(proteinGene)}`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();


				if (data.status && data.data && data.data.rows) {
					const filteredRows = data.data.rows.filter(row =>
						row.data.id.label.trim() !== '' &&
						row.data.title.label.trim() !== '' &&
						row.data.gi.label.trim() !== '' &&
						row.data.accessionVersion.label.trim() !== ''
					);
					setProteinGeneOptions(filteredRows);

				} else {
					setProteinGeneOptions([]);
				}
			} catch (error) {
				console.error('Failed to fetch data:', error);
				setProteinGeneOptions([]);
			}
		} else {
			setProteinGeneOptions([]);
		}
	};

	const handleStageChange = (selectedOption) => {
		setSelectedStage(selectedOption);
		setEditedProduct({
			...editedProduct,
			stage: selectedOption ? selectedOption.value : '',
		});
	};

	const handleHostChange = (selectedOption) => {
		setSelectedHost(selectedOption?.label);
		setEditedProduct({ ...editedProduct, host: selectedOption?.label || null });
	};


	useEffect(() => {
		console.log(rows, 'imageUpdateRows')
	}, [rows])
	// NEW UI Implementation 

	useEffect(() => {
		if (editedProduct?.product_experiments) {
			// Set experiment rows data
			const experimentRows = editedProduct.product_experiments.map(exp => ({
				id: exp.id,
				experimentName: exp.experiment_name,
				Genes: exp.Genes,
				commonName: exp.common_name,
				scientificName: exp.scientific_name,
				selectedProteinGene: exp.protein,
				sop_pdfs: exp.sop_pdfs,
				clone_images: exp.clone_images,
				sequence_report_pdfs: exp.sequence_report_pdfs,
				is_deleted: exp.is_deleted ? exp.is_deleted : false,
				exp_stage: exp.exp_stage,
				any_deviation: exp.any_deviation,
				gene_sequence: exp.gene_sequence,
				gene: exp.gene,
				protien: exp.protein,
				protein_sequence: exp.protein_sequence,
				experiment_type: exp.experiment_type,
				trial_number: exp.trial_number,
				deviation_description: exp.deviation_description,
				date_of_completion: exp.date_of_completion,
				date_of_sending_dna: exp.date_of_sending_dna,
				date_of_result_receipt: exp.date_of_result_receipt,
				clone_confirmation_conclusion: exp.clone_confirmation_conclusion,
				isExisting: true
			}));
			setRows(experimentRows);

			// Load selected experiment data
			const selectedExperiment = rows[selectedExperimentIndex];
			console.log(selectedExperiment, 'selectedExpp')
			if (selectedExperiment) {
				loadExperimentData(selectedExperiment);
			}
		}
	}, [editedProduct, selectedExperimentIndex]);


	const loadExperimentData = (experiment) => {
		// Reset all data when loading new experiment
		setGeneData([]);
		setCodoneData([]);
		setVirtualCloneData([]);
		setExpLibraryData([]);
		setStrainEnggData([]);

		console.log(experiment, 'EXPERIMETTTTT', editedProduct.product_experiments)

		if (experiment.Genes?.length > 0) {
			// Set gene data
			const genes = experiment.Genes
				.filter(gene => !gene.is_deleted)
				.map(gene => ({
					id: gene.id,
					experimentId: experiment.id,
					gene: gene.gene_sequence_id,
					gene_sequence: gene.gene_sequence,
					company: gene.company,
					orderDate: gene.order_date?.split('T')[0],
					receiptDate: gene.receipt_date?.split('T')[0]
				}));
			setGeneData(genes);

			// Process each gene's data
			experiment.Genes.forEach(gene => {
				// Set codone data
				if (gene.codoneOptimizations?.length > 0) {
					const codones = gene.codoneOptimizations
						.filter(codone => !codone.is_deleted)
						.map(codone => ({
							id: codone.id,
							experiment_id: experiment.id,
							geneId: gene.id,
							tool: codone.tool,
							caiScore: codone.cai_score,
							gcPercentage: codone.gc_percentage,
							optimizedSequence: codone.optimized_sequence
						}));
					setCodoneData(prev => [...prev, ...codones]);

					// Process each codone's data
					gene.codoneOptimizations.forEach(codone => {
						// Set virtual clone data
						if (codone.virtualCloneConstructs?.length > 0) {
							const clones = codone.virtualCloneConstructs
								.filter(clone => !clone.is_deleted)
								.map(clone => ({
									id: clone.id,
									codoneId: clone.codone_id,
									experimentId: experiment.id,
									geneId: gene.id,
									vectorType: clone.vector_type,
									vectorName: clone.vector_name,
									img_construct_upload_url: clone.img_construct_upload_url,
									cloneSize: clone.clone_size,
									cloneSites: clone.cloning_sites
								}));
							setVirtualCloneData(prev => [...prev, ...clones]);

							// Process each clone's data
							codone.virtualCloneConstructs.forEach(clone => {
								// Set expression library data
								if (clone.expressionLibraries?.length > 0) {
									const libraries = clone.expressionLibraries
										.filter(lib => !lib.is_deleted)
										.map(lib => ({
											id: lib.id,
											cloneConstructId: clone.id,
											codoneId: codone.id,
											geneId: gene.id,
											experimentId: experiment.id,
											plasmidName: lib.plasmid_name,
											expType: lib.exp_type,
											trialNumber: lib.trial_number,
											sopUrl: lib.sop_pdfs,
											anyDeviation: lib.any_deviation,
											deviationDetails: lib.deviation_description,
											resultImages: lib.clone_images,
											date_of_completion: lib.date_of_completion?.split('T')[0],
											sequence_report_pdfs: lib.sequence_report_pdfs,
											glycerol_stock_pdfs: lib.glycerolStock,
											date_of_sending_dna: lib.date_of_sending_dna?.split('T')[0],
											date_of_result_receipt: lib.date_of_result_receipt?.split('T')[0],
											clone_confirmation: lib.clone_confirmation_conclusion
										}));
									setExpLibraryData(prev => [...prev, ...libraries]);

									// Process each library's data
									clone.expressionLibraries.forEach(lib => {
										// Set strain engineering data
										if (lib.strainEngineerings?.length > 0) {
											const strains = lib.strainEngineerings
												.filter(strain => !strain.is_deleted)
												.map(strain => ({
													id: strain.id,
													expLibId: lib.id,
													cloneConstructId: clone.id,
													experimentId: experiment.id,
													codoneId: codone.id,
													geneId: gene.id,
													strainName: strain.strain_name,
													trialNumber: strain.trial_number,
													plasmidName: strain.strain_name,
													sopUrl: strain.sop_pdfs,
													anyDeviation: strain.any_deviation,
													deviationDetails: strain.deviation_description,
													date_of_sending_dna: strain.date_of_sending_dna?.split('T')[0],
													date_of_result_receipt: strain.date_of_result_receipt?.split('T')[0],
													sequence_report_pdfs: strain.sequence_report_pdfs,
													glycerol_stock_pdfs: strain.glycerol_stock,
													clone_confirmation: strain.clone_confirmation_conclusion,
												}));

											setStrainEnggData(prev => [...prev, ...strains]);
										}
									});
								}
							});
						}
					});
				}
			});
		}
	};

	const handleExperimentSelection = (index) => {
		setSelectedExperimentIndex(index);
		setType(""); // Reset type to show experiment list
	};

	const handleAddRowEdit = () => {
		const newRow = {
			id: nanoid(), // Temporary ID until saved
			experimentName: '',
			commonName: '',
			scientificName: '',
			selectedProteinGene: '',
			exp_stage: '',
			isNew: true // Flag to identify new rows
		};
		setRows(prev => [...prev, newRow]);
	};

	// Add this function after handleAddRow
	const handleRemoveRow = async (experimentId) => {
		try {
			const rowToRemove = rows.find(row => row.id === experimentId);
			const rowIndex = rows.findIndex(row => row.id === experimentId);

			if (!rowToRemove) {
				toast.error("Experiment not found");
				return;
			}

			// If experiment has nested data, show warning
			const hasNestedData = rowToRemove.geneData?.length > 0 ||
				rowToRemove.codoneData?.length > 0 ||
				rowToRemove.virtualCloneData?.length > 0 ||
				rowToRemove.expLibraryData?.length > 0 ||
				rowToRemove.strainEnggData?.length > 0;

			if (hasNestedData) {
				const confirmed = window.confirm(
					"This experiment has associated data. Marking it as deleted will affect all related data. Are you sure?"
				);
				if (!confirmed) return;
			}

			// Update isDeleted flag instead of removing
			setRows(prev => prev.map(row =>
				row.id === experimentId
					? { ...row, is_deleted: true }
					: row
			));

			// Reset states if marked row was selected
			if (rowIndex === selectedExperimentIndex) {
				setSelectedExperimentIndex(0);
				setType("");
				setGeneData([]);
				setCodoneData([]);
				setVirtualCloneData([]);
				setExpLibraryData([]);
				setStrainEnggData([]);
				setGrowthConditionData([]);
			}

			toast.success("Experiment marked as deleted");

		} catch (error) {
			console.error('Error marking experiment as deleted:', error);
			toast.error("Failed to mark experiment as deleted");
		}
	};

	const handleSave = (isMulti) => {
		if (type === "genes") {
			console.log('insideGeneSave')
			handleSaveModal(geneData, isMulti);
		} else if (type === "codone") {
			handleSaveModal(codoneData, isMulti);
		} else if (type === "virtualCodeConstruct") {
			handleSaveModal(virtualCloneData, isMulti);
		} else if (type === "expLibrary") {
			handleSaveModal(expLibraryData, isMulti)
		} else if (type === "strainEngg") {
			handleSaveModal(strainEnggData, isMulti);
		} else if (type === "growthCondition") {
			handleSaveModal(growthConditionData, isMulti);
		}
	}

	const handleSaveModalEdit = (data, isMulti) => {
		if (type === "genes") {
			const updatedRows = rows.map((row, index) =>
				index === selectedExperimentIndex
					? { ...row, geneData: data }
					: row
			);
			setRows(updatedRows);
			setGeneData(data);
		} else if (type === "codone") {
			const updatedRows = rows.map((row, index) =>
				index === selectedExperimentIndex
					? { ...row, codoneData: data }
					: row
			);
			setRows(updatedRows);
			setCodoneData(data);
		} else if (type === "virtualCodeConstruct") {
			const updatedRows = rows.map((row, index) =>
				index === selectedExperimentIndex
					? { ...row, virtualCloneData: data }
					: row
			);
			setRows(updatedRows);
			setVirtualCloneData(data);
		} else if (type === "expLibrary") {
			const updatedRows = rows.map((row, index) =>
				index === selectedExperimentIndex
					? { ...row, expLibraryData: data }
					: row
			);
			setRows(updatedRows);
			setExpLibraryData(data);
		} else if (type === "strainEngg") {
			const updatedRows = rows.map((row, index) =>
				index === selectedExperimentIndex
					? { ...row, strainEnggData: data }
					: row
			);
			setRows(updatedRows);
			setStrainEnggData(data);
		} else if (type === "growthCondition") {
			const updatedRows = rows.map((row, index) =>
				index === selectedExperimentIndex
					? { ...row, growthConditionData: data }
					: row
			);
			setRows(updatedRows);
			setGrowthConditionData(data);
		}

		// if (!isMulti) {
		//   setIsExpDetailsModal(false);
		// }
	};

	const handleSelectedExperiment = (index) => {
		setSelectedExperiment(rows[index]);
		// setSelectedExperimentIndex(index);
		setIsExperimentDetailModalOpen(true);
	}

	const handleInputChange = (id, event, isSelect = false) => {
		console.log("Event:", event);
		const { name, value } = event.target;
		setRows(prevRows =>
			prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
		);
	};

	const handleAddMore = (id) => {
		const currentExperiment = rows[selectedExperimentIndex];

		const getNewItem = () => {
			switch (type) {
				case "genes":
					return {
						id: nanoid(),
						geneSequence: '',
						experimentId: currentExperiment.id,
						company: '',
						orderDate: '',
						receiptDate: '',
						isNew: true
					};

				case "codone":
					// Get selected gene's ID if exists
					const selectedGene = geneData.find(gene => gene.isSelected);
					return {
						id: nanoid(),
						geneId: id || '',
						experimentId: currentExperiment.id,
						tool: '',
						caiScore: '',
						gcPercentage: '',
						optimizedSequence: '',
						isNew: true
					};

				case "virtualCodeConstruct":
					// Get selected codone's ID if exists
					const selectedCodone = codoneData.find(codone => codone.isSelected);
					return {
						id: nanoid(),
						codoneId: id || '',
						geneId: selectedCodone?.geneId || '',
						experimentId: currentExperiment.id,
						vectorType: '',
						vectorName: '',
						cloneSize: '',
						cloneSite: '',
						isNew: true
					};

				case "expLibrary":
					// Get selected clone's ID if exists
					const selectedClone = virtualCloneData.find(clone => clone.isSelected);
					return {
						id: nanoid(),
						cloneConstructId: id || '',
						codoneId: selectedClone?.codoneId || '',
						experimentId: currentExperiment.id,
						plasmidName: '',
						expType: '',
						trialNumber: '',
						sopUrl: '',
						anyDeviation: false,
						deviationDetails: '',
						resultImages: [],
						date_of_completion: '',
						sequence_report_pdfs: [],
						glycerol_stock_pdfs: [],
						date_of_sending_dna: '',
						date_of_result_receipt: '',
						clone_confirmation: '',
						isNew: true
					};

				case "strainEngg":
					// Get selected library's ID if exists
					const selectedLibrary = expLibraryData.find(lib => lib.isSelected);
					return {
						id: nanoid(),
						expLibId: id || '',
						cloneConstructId: selectedLibrary?.cloneConstructId || '',
						experimentId: currentExperiment.id,
						strainName: '',
						trialNumber: '',
						plasmidName: '',
						sopUrl: '',
						anyDeviation: false,
						deviationDetails: '',
						date_of_sending_dna: '',
						date_of_result_receipt: '',
						sequence_report_pdfs: [],
						glycerol_stock_pdfs: [],
						clone_confirmation: '',
						isNew: true
					};

				case "growthCondition":
					return {
						mapId: nanoid(),
						growth_condition_name: '',
						isNew: true
					};

				default:
					return {};
			}
		};

		const dataMap = {
			"genes": setGeneData,
			"codone": setCodoneData,
			"virtualCodeConstruct": setVirtualCloneData,
			"expLibrary": setExpLibraryData,
			"strainEngg": setStrainEnggData,
			"growthCondition": setGrowthConditionData
		};

		const setStateFunction = dataMap[type];
		if (!setStateFunction) return;

		// Add new item with proper parent relationships
		const newItem = getNewItem();
		setStateFunction(prevData => [...prevData, newItem]);

		// Update rows data to maintain consistency
		const updatedRows = rows.map((row, index) => {
			if (index === selectedExperimentIndex) {
				const dataKey = `${type}Data`;
				return {
					...row,
					[dataKey]: [...(row[dataKey] || []), newItem]
				};
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handleSetData = (expIndex) => {
		const experiment = editedProduct.product_experiments[expIndex];

		console.log(experiment, 'selectedExp')

		// Set all experiment data states with default empty arrays if data doesn't exist
		loadExperimentData(experiment)
		// setIsDataSet(true);
	}


	const handleRemoveExpItem = (index) => {
		const dataMap = {
			"genes": [geneData, setGeneData],
			"codone": [codoneData, setCodoneData],
			"virtualCodeConstruct": [virtualCloneData, setVirtualCloneData],
			"expLibrary": [expLibraryData, setExpLibraryData],
			"strainEngg": [strainEnggData, setStrainEnggData],
			"growthCondition": [growthConditionData, setGrowthConditionData]
		};

		const [currentData, setCurrentData] = dataMap[type] || [];
		if (!setCurrentData || currentData.length <= 1) return;

		const itemToRemove = currentData[index];

		// Show confirmation for existing items
		if (!itemToRemove.isNew) {
			const hasChildData = () => {
				switch (type) {
					case "genes":
						return codoneData.some(item => item.geneId === itemToRemove.id);
					case "codone":
						return virtualCloneData.some(item => item.codoneId === itemToRemove.id);
					case "virtualCodeConstruct":
						return expLibraryData.some(item => item.cloneConstructId === itemToRemove.id);
					case "expLibrary":
						return strainEnggData.some(item => item.expLibId === itemToRemove.id);
					default:
						return false;
				}
			};

			if (hasChildData()) {
				const confirmed = window.confirm(
					"This item has associated data. Deleting it will remove all related data. Are you sure?"
				);
				if (!confirmed) return;

				// Remove child data if confirmed
				switch (type) {
					case "genes":
						// Remove related codone data
						const relatedCodones = codoneData.filter(item => item.geneId === itemToRemove.id);
						relatedCodones.forEach(codone => {
							// Remove related virtual clones
							const relatedClones = virtualCloneData.filter(clone => clone.codoneId === codone.id);
							relatedClones.forEach(clone => {
								// Remove related expression libraries
								const relatedLibraries = expLibraryData.filter(lib => lib.cloneConstructId === clone.id);
								relatedLibraries.forEach(lib => {
									// Remove related strain engineering data
									setStrainEnggData(prev =>
										prev.filter(strain => strain.expLibId !== lib.id)
									);
								});
								setExpLibraryData(prev =>
									prev.filter(lib => lib.cloneConstructId !== clone.id)
								);
							});
							setVirtualCloneData(prev =>
								prev.filter(clone => clone.codoneId !== codone.id)
							);
						});
						setCodoneData(prev =>
							prev.filter(item => item.geneId !== itemToRemove.id)
						);
						break;

					case "codone":
						// Remove related virtual clones and their children
						const codoneClones = virtualCloneData.filter(clone => clone.codoneId === itemToRemove.id);
						codoneClones.forEach(clone => {
							const cloneLibraries = expLibraryData.filter(lib => lib.cloneConstructId === clone.id);
							cloneLibraries.forEach(lib => {
								setStrainEnggData(prev =>
									prev.filter(strain => strain.expLibId !== lib.id)
								);
							});
							setExpLibraryData(prev =>
								prev.filter(lib => lib.cloneConstructId !== clone.id)
							);
						});
						setVirtualCloneData(prev =>
							prev.filter(clone => clone.codoneId !== itemToRemove.id)
						);
						break;

					case "virtualCodeConstruct":
						// Remove related expression libraries and their children
						const cloneLibraries = expLibraryData.filter(lib => lib.cloneConstructId === itemToRemove.id);
						cloneLibraries.forEach(lib => {
							setStrainEnggData(prev =>
								prev.filter(strain => strain.expLibId !== lib.id)
							);
						});
						setExpLibraryData(prev =>
							prev.filter(lib => lib.cloneConstructId !== itemToRemove.id)
						);
						break;

					case "expLibrary":
						// Remove related strain engineering data
						setStrainEnggData(prev =>
							prev.filter(strain => strain.expLibId !== itemToRemove.id)
						);
						break;
					default:
						break;
				}
			}
		}

		// Remove the item itself
		setCurrentData(prevData => prevData.filter((_, i) => i !== index));

		// Update rows data to maintain consistency
		const updatedRows = rows.map((row, idx) => {
			if (idx === selectedExperimentIndex) {
				const dataKey = `${type}Data`;
				return {
					...row,
					[dataKey]: row[dataKey]?.filter((_, i) => i !== index)
				};
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handleDataChange = (e, isSelect = false, indx = 0) => {
		try {
			const dataMap = {
				"genes": [geneData, setGeneData],
				"codone": [codoneData, setCodoneData],
				"virtualCodeConstruct": [virtualCloneData, setVirtualCloneData],
				"expLibrary": [expLibraryData, setExpLibraryData],
				"strainEngg": [strainEnggData, setStrainEnggData],
				"growthCondition": [growthConditionData, setGrowthConditionData]
			};

			const [currentData, setCurrentData] = dataMap[type] || [];
			if (!setCurrentData) return;

			let name, value;
			if (isSelect) {
				name = e.name;
				value = e.value;
			} else {
				name = e.target.name;
				value = e.target.type === 'checkbox'
					? e.target.checked
					: e.target.type === 'date'
						? new Date(e.target.value).toISOString().split('T')[0]
						: e.target.value;
			}

			setCurrentData(prevData => {
				const newData = [...prevData];
				const currentItem = newData[indx] || {};
				const updatedItem = {
					...currentItem,
					[name]: value
				};

				// For new items, add necessary IDs and flags
				if (!currentItem.mapId) {
					updatedItem.mapId = `temp-${nanoid()}`;
					updatedItem.isNew = true;

					// Add parent references based on type
					// switch (type) {
					// 	case "codone":
					// 		updatedItem.geneId = geneData.find(gene => gene.isSelected)?.mapId;
					// 		break;
					// 	case "virtualCodeConstruct":
					// 		const selectedCodone = codoneData.find(codone => codone.isSelected);
					// 		updatedItem.codoneId = selectedCodone?.mapId;
					// 		updatedItem.geneId = selectedCodone?.geneId;
					// 		break;
					// 	case "expLibrary":
					// 		const selectedClone = virtualCloneData.find(clone => clone.isSelected);
					// 		updatedItem.cloneConstructId = selectedClone?.mapId;
					// 		updatedItem.codoneId = selectedClone?.codoneId;
					// 		updatedItem.geneId = selectedClone?.geneId;
					// 		break;
					// 	case "strainEngg":
					// 		const selectedLib = expLibraryData.find(lib => lib.isSelected);
					// 		updatedItem.expLibId = selectedLib?.mapId;
					// 		updatedItem.cloneConstructId = selectedLib?.cloneConstructId;
					// 		updatedItem.codoneId = selectedLib?.codoneId;
					// 		updatedItem.geneId = selectedLib?.geneId;
					// 		break;
					// 	default:
					// 		break;
					// }
				}

				newData[indx] = updatedItem;
				return newData;
			});

			// Update rows data to maintain consistency
			const updatedRows = rows.map((row, idx) => {
				if (idx === selectedExperimentIndex) {
					const dataKey = `${type}Data`;
					return {
						...row,
						[dataKey]: row[dataKey]?.map((item, i) =>
							i === indx
								? { ...item, [name]: value }
								: item
						) || []
					};
				}
				return row;
			});
			setRows(updatedRows);

			// Handle special cases for certain fields
			if (name === 'isSelected') {
				// Reset child selections when parent selection changes
				switch (type) {
					case "genes":
						setCodoneData(prev => prev.map(item => ({ ...item, isSelected: false })));
						setVirtualCloneData(prev => prev.map(item => ({ ...item, isSelected: false })));
						setExpLibraryData(prev => prev.map(item => ({ ...item, isSelected: false })));
						setStrainEnggData(prev => prev.map(item => ({ ...item, isSelected: false })));
						break;
					case "codone":
						setVirtualCloneData(prev => prev.map(item => ({ ...item, isSelected: false })));
						setExpLibraryData(prev => prev.map(item => ({ ...item, isSelected: false })));
						setStrainEnggData(prev => prev.map(item => ({ ...item, isSelected: false })));
						break;
					case "virtualCodeConstruct":
						setExpLibraryData(prev => prev.map(item => ({ ...item, isSelected: false })));
						setStrainEnggData(prev => prev.map(item => ({ ...item, isSelected: false })));
						break;
					case "expLibrary":
						setStrainEnggData(prev => prev.map(item => ({ ...item, isSelected: false })));
						break;
					default:
						break;
				}
			}

		} catch (error) {
			console.error('Error updating data:', error);
			toast.error("Failed to update data");
		}
	};



	const handleEditSubmit = (e) => {
		e.preventDefault();
		const expPayload = {
			product_experiments: rows,
			genes: geneData,
			codonOptimizations: codoneData,
			virtualCloneConstructs: virtualCloneData,
			expressionLibraries: expLibraryData,
			strainEngineerings: strainEnggData,
			growthConditions: growthConditionData
		}



		const restructed = reconstructNestedData(expPayload);
		setEditedProduct({ ...editedProduct, host: selectedHost || null, product_experiments: restructed })
		console.log(restructed, 'RESTRUCTURED')
		handleSubmit();

	}

	const handleSubmit = async (e) => {
		//..console.log("editedProduct in submit",editedProduct)
		// e.preventDefault();

		const formData = new FormData();
		const photosFormData = new FormData(); // Separate FormData for photos
		const pdfFormData = new FormData();
		files.forEach((file) => {
			formData.append("photos", file);
			photosFormData.append("photos", file); // Add files to photosFormData
		});
		pdfFiles.forEach((file) => {
			formData.append("pdfs", file);
			pdfFormData.append("pdfs", file); // Add files to photosFormData
		});
		setIsSubmitting(true);
		// Dispatch action to update the product in Redux store
		try {
			const expPayload = {
				product_experiments: rows,
				genes: geneData,
				codonOptimizations: codoneData,
				virtualCloneConstructs: virtualCloneData,
				expressionLibraries: expLibraryData,
				strainEngineerings: strainEnggData,
				growthConditions: growthConditionData
			}

			console.log(editedProduct, 'EDITED_products')
			const restructed = reconstructNestedData(expPayload);
			console.log(restructed, 'RestructedXXXX')
			// return

			// return 
			// Make an HTTP PUT request to delete the product
			const productResponse = await apiService.put(
				`/product/${product.id}`,
				{ ...editedProduct, host: selectedHost || null, product_experiments: restructed }
			);

			// Handle success, e.g., close the modal and update UI
			if (productResponse.status === 200) {
				if (files.length > 0) {
					const uploadResponse = await apiService.post(
						`/photos/upload/${editedProduct.id}`,
						photosFormData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);

					// Handle response
					if (uploadResponse.status === 200) {
						dispatch(updateProduct(editedProduct));
						// onProductEdited();
						// onClose();
					} else {
						throw new Error("Failed to upload photos");
					}
				} else {
					dispatch(updateProduct(editedProduct));
					// onProductEdited();
					// onClose();
				}
				if (pdfFiles.length > 0) {
					const uploadPdfResponse = await apiService.post(
						`/pdf/upload/${editedProduct.id}`,
						pdfFormData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);

					if (uploadPdfResponse.status === 200) {
						dispatch(updateProduct(editedProduct));
						// onClose();
					} else {
						throw new Error("Failed to upload photos");
					}
				} else {
					dispatch(updateProduct(editedProduct));
					// onClose();
				}
				// dispatch(toastNotifySucess("Product updated successfully!"));
				toast.success("Product updated successfully!")
				navigate("/productpage");
			} else {
				throw new Error("Failed to update product");
			}
		} catch (error) {
			// Handle error
			console.error("Error updating product:", error);
			toast.error("An error occurred while updating the product.")
			// dispatch(toastNotifyError("An error occurred while updating the product.", error.response?.data?.errors || []));
		} finally {
			// Set isSubmitting to false after submission is completed (success or failure)
			setIsSubmitting(false);
			// setCodoneData([]);
			// setGeneData([]);
			// setExpLibData([]);
			// setVirtualCloneData([]);
			// window.location.reload();
		}
	};

	const handleOpenDeleteModal = () => {
		setShowDeleteModal(true);
	};

	// Function to handle closing the delete confirmation modal
	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	// Function to handle deleting the product
	const handleDeleteProduct = async () => {
		try {
			// Make an HTTP DELETE request to delete the product

			await apiService.delete(`/product/delete/${product.id}`);

			// Handle success, e.g., close the modal and update UI
			dispatch(deleteProduct(product.id));
			toast.success('Product deleted successfully');
			// onClose();
			navigate('/productPage');
		} catch (error) {
			// Handle error
			toast.error('failed to delete the product');
			console.error("Error deleting product:", error);
		}
	};
	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const handlePreviousStep = () => {
		setCurrentStep(currentStep - 1);
	};
	const handleSearch = (id, geneId) => {
		console.log(id, 'EXPIDD___')
		const index = editedProduct.product_experiments.findIndex((value) => value.id === id);

		const gene = rows[index].gene;
		console.log("indexgene", index, geneId);
		setIsGeneLoading(true)
		// if (geneId) {
		fetchGeneData(geneId);
		//fetchGeneSequence(gene);
		// }
		setIsGeneTableOpen(true);
		setCurrentRowIndex(index);
	};
	const handleSearchProtein = (id) => {
		const index = rows.findIndex((value) => value.id === id);
		const proteinGene = rows[index].protein;
		console.log('PRTEINFDATA', proteinGene, rows[index])
		if (proteinGene) {
			fetchProteinGeneData(proteinGene);
		}
		setIsProteinTableOpen(true);
		setCurrentRowIndex(index);
	};

	const handleSelectedGeneChange = (geneId, index) => {
		setSelectedGene(geneId);
		fetchGeneSequence(geneId, index);
		setEditedProduct(prevState => {
			const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
				if (idx === index) {
					return { ...exp, gene: geneId }; // Update the target_gene field for the specific row
				}
				return exp;
			});
			return {
				...prevState,
				product_experiments: updatedExperiments,
			};
		});
		setRows(prevRows =>
			prevRows.map((row, idx) =>
				idx === index ? { ...row, gene: geneId } : row
			)
		);


	};
	const handleSelectedProteinGeneChange = (proteinId, index) => {
		setEditedProduct(prevState => {
			const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
				if (idx === index) {
					return { ...exp, protein: proteinId };
				}
				return exp;
			});
			return {
				...prevState,
				product_experiments: updatedExperiments,
			};
		});
		setRows(prevRows =>
			prevRows.map((row, idx) =>
				idx === index ? { ...row, protein: proteinId } : row
			)
		);
		//..console.log("Selected Protein Gene ID in Parent:", proteinId);
	};
	const handleDeleteExperimentRow = (index) => {
		//..console.log(index, "index");

		// Mark the row as deleted
		const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
			if (idx === index) {
				return { ...exp, is_deleted: true }; // Soft delete the row
			}
			return exp;
		});

		// Update the state with filtered experiments (hide deleted ones)
		const visibleExperiments = updatedExperiments.filter(exp => !exp.is_deleted);

		setEditedProduct({ ...editedProduct, product_experiments: visibleExperiments });
	};

	const handleRemoveCodonOptimization = (experimentId, codonRowId) => {
		setEditedProduct((prevProduct) => {
			const updatedExperiments = prevProduct.product_experiments.map((exp) => {
				if (exp.id === experimentId) {
					// Filter out the codon optimization to remove it by its unique id (not index)
					const updatedCodonOptimizations = exp?.codon_optimizations.filter((codonRow) => codonRow.id !== codonRowId);
					return {
						...exp,
						codon_optimizations: updatedCodonOptimizations, // Update with the new array
					};
				}
				return exp; // Return the experiment unchanged if it doesn't match
			});

			return {
				...prevProduct,
				product_experiments: updatedExperiments, // Update the state with the modified experiments
			};
		});
	};


	const handleAddRow = () => {
		setEditedProduct(prevProduct => {
			//..console.log("Current product_experiments:", prevProduct.product_experiments);

			return {
				...prevProduct,
				product_experiments: [
					...(prevProduct.product_experiments || []),
					{
						id: nanoid(),
						experiment_name: '',
						common_name: '',
						scientific_name: '',
						gene: '',
						protein: '',
						is_deleted: false,
						Genes: []
					},
				],
			};
		});
	};
	const handleAddRowCodon = (currentRowId) => {
		setEditedProduct((prevProduct) => {
			const updatedExperiments = prevProduct.product_experiments.map((exp) => {

				return exp;
			});

			return {
				...prevProduct,
				product_experiments: updatedExperiments,
			};
		});
	};



	const handleBack = () => {
		navigate(-1); // Go back to the previous page
	};
	const handleCloseGeneTableModal = () => {

		setIsGeneTableOpen(false);

	}
	const handleCloseProtienTableModal = () => {
		setIsProteinTableOpen(false);
	}
	const handleCodonOptimization = (experiment, experimentName) => {
		setSelectedExperiment(experiment);
		//..console.log("handle codon optimization", experiment);

		setCurrentRowId(experiment.id);
		setCurrentExperimentName(experimentName);
		setIsExperimentModalOpen(true);

	};

	const handleExperimentDetails = (experiment) => {
		setSelectedExperiment(experiment);
		setIsExperimentDetailModalOpen(true);
	};
	const handleVirtualCloneConstruct = (experiment) => {
		setSelectedExperiment(experiment);
		setIsVirtualCloneConstructModalOpen(true);
	};
	const handleCloseVirtualCloneConstructModal = () => {
		setIsVirtualCloneConstructModalOpen(false);
		//setCurrentRowId(null);
		setCurrentExperimentName('');

	};

	const handleGeneSynthesis = (experiment) => {
		setSelectedExperiment(experiment);
		//setCurrentRowId(experiment.id);
		setIsGeneSythesisModalOpen(true);
	};
	const handleCloseGeneSynthesisModal = () => {
		setIsGeneSythesisModalOpen(false);
		//setCurrentRowId(null);
		setCurrentExperimentName('');

	};


	const handleCloseExperimentDetailModal = () => {
		setIsExperimentDetailModalOpen(false);
		//setCurrentRowId(null);
		setCurrentExperimentName('');

	};
	const handleCloseModal = () => {
		setIsExperimentModalOpen(false);
		setCurrentRowId(null);
		setCurrentExperimentName('');

	};
	const handleSaveModal = () => {
		// Logic to save the data

		handleCloseModal();
	};
	const handleSaveDetailModal = () => {
		// Logic to save the data

		handleCloseExperimentDetailModal();
	};



	return (
		<>
			<div className="main-container mx-auto p-8 max-w-7xl">

				<div className="flex items-center justify-between mb-4">
					<button
						type="button"
						onClick={handleBack}
						className="border-gray-400 border text-gray-600 px-2 py-1 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center text-sm"
						style={{ width: 'fit-content' }}
					>
						<ImageLoader name="left-arrow-active.svg" className="mr-1 h-4 w-3" />
						<span className="whitespace-nowrap">Back to products</span>
					</button>

					<div className="relative">
						<button
							type="button"
							onClick={toggleOptions}
							className="text-gray-600 hover:text-gray-800 flex items-center justify-center"
						>
							<BiDotsVerticalRounded size={20} />
						</button>

						{isOptionsVisible && (
							<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-50">
								<button
									type="button"
									onClick={handleOpenDeleteModal}
									className="w-full px-4 py-2 text-left text-gray-600 hover:text-red-600 hover:bg-gray-100 flex items-center"
								>
									<PiTrash className="mr-2 h-4 w-4" />
									<span>Delete Product</span>
								</button>
							</div>
						)}
					</div>
				</div>
				<h2 className="text-2xl font-semibold mb-4">{editedProduct.name} - Edit</h2>

				<div className="flex flex-col min-h-screen">
					{/* <div className="w-full flex justify-end"> */}
					{/* <button className="hover:text-red-600" onClick={() => onClose()}>
						<IoCloseOutline size={25} />
					</button> */}
					{/* </div> */}

					<form
						className="flex flex-col flex-grow"
						onSubmit={handleEditSubmit}
						onDrop={handleFileDrop}
						onDragOver={handleDragOver}
						onDragEnter={handleDragEnter}
						onDragLeave={handleDragLeave}

					>
						{/* Form inputs */}
						<div className={`${currentStep !== 2 ? 'flex-grow' : ''}`}>
							{currentStep === 1 && (
								<ProductInfo
									flag={1}
									editedProduct={editedProduct}
									setEditedProduct={setEditedProduct}
									selectedStage={selectedStage}
									handleStageChange={handleStageChange}
									//stages={/* stage options */}
									users={users}
									handleTeamLeadChange={handleTeamLeadChange}
									selectedTeamMembers={selectedTeamMembers}
									handleTeamMembersChange={handleTeamMembersChange}
									handleSwitchChange={handleSwitchChange}
									handleBrowseClick={handleBrowseClick}
									fileInputRef={fileInputRef}
									handleFileChange={handleFileChange}
									productPhotos={productPhotos ? productPhotos : []}
									files={files}
									containerStyles={containerStyles}
									handleDeleteFile={handleDeleteFile}
								/>
							)}

							{/* Right column */}
							{currentStep === 2 && (
								<ProductHost
									flag={1}
									selectedHost={selectedHost}
									handleHostChange={handleHostChange}
									hosts={hosts}
									userId={userId}
									editedProduct={editedProduct}
									isApproved={isApproved}
									handleIsApproved={handleIsApproved}
									handlePdfBrowseClick={handlePdfBrowseClick}
									pdfInputRef={pdfInputRef}
									handlePdfFileChange={handlePdfFileChange}
									pdfFiles={pdfFiles}
									openPdf={openPdf}
									selectedOpenPdf={selectedOpenPdf}
									handleDeletePdf={handleDeletePdf}
								/>
							)}
							{currentStep === 3 && (
								<ExperimentEdit
									productData={editedProduct}
									setEditedProduct={setEditedProduct}
									setSelectedExperiment={setSelectedExperiment}
									setIsExperimentDetailModalOpen={setIsExperimentDetailModalOpen}
									geneData={geneData}
									codoneData={codoneData}
									virtualCloneData={virtualCloneData}
									expLibraryData={expLibraryData}
									strainEnggData={strainEnggData}
									growthConditionData={growthConditionData}
									rows={rows}
									selectedExperimentIndex={selectedExperimentIndex}
									handleExperimentSelection={handleExperimentSelection}
									handleSaveModal={handleSaveModalEdit}
									type={type}
									setType={setType}
									handleAddRow={handleAddRowEdit}
									handleRemoveExperiment={handleRemoveRow}
									handleInputChange={handleInputChange}
									handleSelectedExperiment={handleSelectedExperiment}
									handleAddMore={handleAddMore}
									handleRemoveExpItem={handleRemoveExpItem}
									handleDataChange={handleDataChange}
									setSelectedExperimentIndex={setSelectedExperimentIndex}
									handleSetData={handleSetData}
									handleSave={handleSave}
									handleSearch={handleSearch}
									handleSearchProtein={handleSearchProtein}
								/>


								// <ProductExperiments
								// 	flag={1}
								// 	editedProductEdit={editedProduct}
								// 	setEditedProductEdit={setEditedProduct}
								// 	handleSearchEdit={handleSearch}
								// 	handleSearchProteinEdit={handleSearchProtein}
								// 	currentRowIndexEdit={currentRowIndex}
								// 	stagesEdit={stages}
								// 	handleAddRowEdit={handleAddRow}
								// 	handleExperimentDetailsEdit={handleExperimentDetails}
								// 	handleCodonOptimizationEdit={handleCodonOptimization}
								// 	handleDeleteExperimentRowEdit={handleDeleteExperimentRow}
								// 	geneOptionsEdit={geneOptions}
								// 	selectedGeneEdit={selectedGene}
								// 	handleSelectedGeneChangeEdit={handleSelectedGeneChange}
								// 	handleCloseGeneTableModalEdit={handleCloseGeneTableModal}
								// 	isGeneTableOpenEdit={isGeneTableOpen}
								// 	proteinGeneOptionsEdit={proteinGeneOptions}
								// 	selectedProteinGeneEdit={editedProduct.protein}
								// 	handleSelectedProteinGeneChangeEdit={handleSelectedProteinGeneChange}
								// 	handleCloseProtienTableModalEdit={handleCloseProtienTableModal}
								// 	isProteinTableOpenEdit={isProteinTableOpen}
								// 	isGeneLoadOpenEdit={isGeneLoadOpen}
								// 	handleVirtualCloneConstruct={handleVirtualCloneConstruct}
								// 	handleGeneSynthesisEdit={handleGeneSynthesis}

								// 	//new implementation
								// 	geneData={geneData}
								// 	setGeneData={setGeneData}
								// 	codoneData={codoneData}
								// 	setCodoneData={setCodoneData}
								// 	virtualCloneData={virtualCloneData}
								// 	setVirtualCloneData={setVirtualCloneData}
								// 	// expLibData={expLibData}
								// 	// setExpLibData={setExpLibData}
								// 	// strainEnggData={strainEnggData}
								// 	// setStrainEnggData={setStrainEnggData}
								// 	// growthConditionData={growthConditionData}
								// 	// setGrowthConditionData={setGrowthConditionData}
								// 	// expRows={expRows}
								// 	// setExpRows={setExpRows}

								// />

							)}
						</div>
						{/* Buttons Section at the Bottom */}
						<div className="sticky bottom-0 left-0 right-0 bg-white p-4 flex justify-end items-center">
							{currentStep > 1 && (
								<button
									type="button"
									onClick={handlePreviousStep}
									className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mr-4"
								>
									<ImageLoader name="left-arrow-active.svg" className="mr-2 h-6 w-4" />
									Previous
								</button>
							)}

							{currentStep < 3 && (
								<button
									type="button"
									onClick={handleNextStep}
									className={`border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center ${currentStep > 1 ? 'mr-4' : ''}`}
								>
									<ImageLoader name="right-arrow-active.svg" className="mr-2 h-6 w-4" />
									Next
								</button>
							)}

							{currentStep === 3 && (
								<>
									{/* <button
										type="button"
										onClick={handleOpenDeleteModal}
										className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-red-600 hover:border-red-600 rounded-full flex items-center mr-4"
									>
										<PiTrash className="h-6 w-4" />
									</button> */}

									<button
										type="submit"
										className="text-white px-4 py-2 rounded flex items-center"
										disabled={isSubmitting}
										style={{ background: '#7FB541', borderColor: 'transparent' }}
									>
										{isSubmitting ? "Submitting..." : "Submit"}
									</button>
								</>
							)}
						</div>


					</form>
				</div>
				{/* Team member selection modal */}
				{showTeamMemberModal && (
					<TeamMemberModal
						users={users}
						editedProduct={editedProduct}
						handleToggleTeamMember={handleToggleTeamMember}
						setShowTeamMemberModal={setShowTeamMemberModal}
					/>
				)}
				{/* Delete confirmation modal */}
				{showDeleteModal && (
					<DeleteModal
						handleCloseDeleteModal={handleCloseDeleteModal}
						handleDeleteProduct={handleDeleteProduct}
					/>
				)}
				{selectedPdfUrl && (
					<SelectedPdfUrl
						selectedPdfUrl={selectedPdfUrl}
						setSelectedPdfUrl={setSelectedPdfUrl}
					/>
				)}

				{isExperimentModalOpen && selectedExperiment && (
					<CodonOptimization
						flag={1}

						selectedExperimentEdit={selectedExperiment}
						editedProductEdit={editedProduct}
						userIdEdit={userId}
						toolsEdit={tools}
						handleAddRowCodonEdit={handleAddRowCodon}
						handleRemoveCodonOptimizationEdit={handleRemoveCodonOptimization}
						setEditedProductEdit={setEditedProduct}
						handleCloseModalEdit={handleCloseModal}
						handleSaveModalEdit={handleSaveModal}
					/>
				)}

				{
					isGeneTableOpen && <GeneTable
						selectedGene={selectedGene}
						isLoading={isGeneLoading}
						genes={geneOptions}
						onClose={() => setIsGeneTableOpen(false)}
						onSelectedGeneChange={(geneId) => handleSelectedGeneChange(geneId, selectedExperimentIndex)}
					/>
				}

				{
					isProteinTableOpen && <ProteinGeneTable proteingenes={proteinGeneOptions}
						// selectedProteinGene={selectedProtein}
						onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChange(proteinGeneId, selectedExperimentIndex)}
						onClose={() => setIsProteinTableOpen(false)} />
				}


				{isExperimentDetailModalOpen && selectedExperiment && (
					<ExperimentDetail
						flag={1}
						selectedExperiment={selectedExperiment}
						setSelectedExperiment={setSelectedExperiment}
						handleCloseExperimentDetailModal={handleCloseExperimentDetailModal}
						setRows={setRows}
						rows={rows}
						selectedExperimentIndex={selectedExperimentIndex}
					/>
				)}

				{isVirtualCloneConstructModalOpen && selectedExperiment && (
					<VirtualCloneConstruct
						selectedExperiment={selectedExperiment}
						setSelectedExperiment={setSelectedExperiment}
						handleCloseVirtualCloneConstructModal={handleCloseVirtualCloneConstructModal}
					/>
				)}
				{isGeneSynthesisModalOpen && (
					<GeneSynthesis
						selectedExperiment={selectedExperiment}
						setSelectedExperiment={setSelectedExperiment}
						handleCloseGeneSynthesisModal={handleCloseGeneSynthesisModal}
					/>
				)}

			</div >
			<Footer></Footer>
		</>
	);
};

export default EditProductForm;