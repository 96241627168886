import AppConfig from "../AppConfig";

export const fetcher = (endpoint, params) => {
    return fetch(`${AppConfig.CHARAKA_API_URL}/api/V1${endpoint}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      // mode: 'cors',
      // credentials: 'include',
      ...params,
    });
  };

export const fetcherPython = (endpoint, params) => {
    return fetch(`${process.env.PYTHON_API_URL}/${endpoint}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      mode: 'cors',
      ...params,
    });
};
