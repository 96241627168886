/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";

const ImageSlider = ({ imagesList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageError, setImageError] = useState(false);

  if (!imagesList || imagesList.length === 0) {
    return null; // Return early if imagesList is undefined or empty
  }

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? imagesList.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === imagesList.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  console.log(imagesList, 'imagesListe')
  const defaultImage = require("../assets/images/image-not-available.jpg");

  return (
    <div style={sliderStyles}>
      <div style={slideStyles} key={currentIndex}>
        <img
          src={imagesList[currentIndex].url}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = defaultImage;
            setImageError(true);
          }}
          alt="Slider image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            // borderRadius: "10px",
            // opacity: imageError ? 0.7 : 1 // Optional: reduce opacity for error state
          }}
        />
      </div>
      {imagesList.length > 1 && (  // Conditionally render arrows and dots
        <>
          <div style={leftArrowStyles} onClick={goToPrevious}>
            ❰
          </div>
          <div style={rightArrowStyles} onClick={goToNext}>
            ❱
          </div>
          <div style={dotsContainerStyles}>
            {imagesList.map((image, index) => (
              <span
                key={index}
                style={{
                  ...dotStyle,
                  color: index === currentIndex ? "#7FB541" : "#ccc",
                }}
                onClick={() => handleDotClick(index)}
              >
                ●
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const slideStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const rightArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: "32px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  left: "32px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};

export default ImageSlider;
