import React from 'react';
import Select from 'react-select';
import ImageLoader from '../ImageLoader';

const CodonOptimization = ({

	flag,


	currentExperimentNameAdd,
	handleAddRowCodonAdd,
	currentRowIdAdd,
	rowsAdd,
	toolsAdd,
	handleCodonInputChangeAdd,
	teamLeadAdd,
	userIdAdd,
	handleRemoveCodonOptimizationAdd,
	handleCloseModalAdd,
	handleSaveModalAdd,


	selectedExperimentEdit,
	editedProductEdit,
	userIdEdit,
	toolsEdit,
	handleAddRowCodonEdit,
	handleRemoveCodonOptimizationEdit,
	setEditedProductEdit,
	handleCloseModalEdit,
	handleSaveModalEdit,
}) => {
	return (
		<>
			< div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
				<div className="bg-white p-8 rounded-lg w-3/4 max-h-screen overflow-y-auto">
					<h2 className="text-xl font-bold mb-4">{flag === 0 ? currentExperimentNameAdd : selectedExperimentEdit.experiment_name}</h2>

					{/* Add button for new codon optimization entries */}
					<div className="flex justify-end mb-4">
						<button
							type="button"
							onClick={() => flag === 0 ? handleAddRowCodonAdd(currentRowIdAdd) : handleAddRowCodonEdit(selectedExperimentEdit.id)}
							className="text-green-600 hover:text-green-700 px-3 py-2 rounded-full flex items-center border border-green-600 hover:border-green-700"
						>
							<ImageLoader name="plus-icon.svg" className="h-6 w-6 mr-2" alt="Add" title="Add" />
							Codon Optimization
						</button>
					</div>

					{/* Dynamic Fields Table for Codon Optimization */}
					<div className="grid grid-cols-6 gap-3 items-center font-bold mb-4">
						<div className="block font-bold mb-1">Tool</div>
						<div className="block font-bold mb-1">CAI Score</div>
						<div className="block font-bold mb-1">GC%</div>
						<div className="block font-bold mb-1">Optimized Sequence</div>

						{flag === 0 ? (
							teamLeadAdd?.value == userIdAdd && (
								<div className="block font-bold mb-1">Selected Sequencdddde</div>
							)
						) : (
							userIdEdit == editedProductEdit.team_lead && (
								<div className="block font-bold mb-1">Selected Sequence</div>
							)
						)}


						<div className="block font-bold mb-1">Action</div>
					</div>
					{/* Map over the codon optimizations for the selected experiment */}
					<div>
						{flag === 0 ? (<>

							{rowsAdd.find(row => row.id === currentRowIdAdd)?.codonOptimizations?.map((codonRow, index) => (
								<div key={codonRow.id} className="grid grid-cols-6 gap-3 items-start cursor-pointer hover:bg-gray-100 p-2 rounded">
									{/* Tool Field */}
									<div className="input-container col-span-1">
										<Select
											id={`tool-${codonRow.id}`}
											name="tool"
											value={toolsAdd.find(toolOption => toolOption.value === codonRow.tool)}
											onChange={selectedOption => handleCodonInputChangeAdd(currentRowIdAdd, codonRow.id, { target: { name: 'tool', value: selectedOption.value } })}
											options={toolsAdd}
											required
										/>
									</div>

									{/* CAI Score Field */}
									<div className="input-container col-span-1">
										<input
											type="number"
											className="border border-gray-300 p-2 rounded w-full"
											value={codonRow.caiScore}
											onChange={(e) => handleCodonInputChangeAdd(currentRowIdAdd, codonRow.id, { target: { name: 'caiScore', value: e.target.value } })}
											placeholder="Enter CAI score"
										/>
									</div>

									{/* GC% Field */}
									<div className="input-container col-span-1">
										<input
											type="number"
											className="border border-gray-300 p-2 rounded w-full"
											value={codonRow.gcPercentage}
											onChange={(e) => handleCodonInputChangeAdd(currentRowIdAdd, codonRow.id, { target: { name: 'gcPercentage', value: e.target.value } })}
											placeholder="Enter GC percentage"
										/>
									</div>

									{/* Optimized Sequence Field */}
									<div className="input-container col-span-1">
										<textarea
											className="border border-gray-300 p-2 rounded w-full max-h-36"
											value={codonRow.optimizedSequence}
											onChange={(e) => handleCodonInputChangeAdd(currentRowIdAdd, codonRow.id, { target: { name: 'optimizedSequence', value: e.target.value } })}
											placeholder="Paste optimized sequence"
											onKeyDown={(e) => {
												// Allow only Ctrl+V (paste), Ctrl+Z (undo), and navigation keys (e.g., arrows, Home, End)
												if (
													(e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'z') || // Allow Ctrl+V and Ctrl+Z
													e.key === 'Backspace' || e.key === 'Delete' || // Allow Backspace and Delete for clearing
													e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || // Allow arrow keys for navigation
													e.key === 'Home' || e.key === 'End' // Allow Home and End for navigation
												) {
													return; // Allow these keys
												} else {
													e.preventDefault(); // Prevent all other typing
												}
											}}
											onPaste={(e) => {
												e.stopPropagation(); // Allow pasting
											}}
										/>
									</div>

									{/* Selected Sequence Checkbox */}
									{teamLeadAdd?.value == userIdAdd && (
										<div className="input-container col-span-1 flex items-center justify-center">
											<div className="flex items-center">

												<input
													type="checkbox"
													id={`selectedSequence-${codonRow.id}`}
													name="selectedSequence"
													checked={codonRow.selectedSequence}
													onChange={(e) => handleCodonInputChangeAdd(currentRowIdAdd, codonRow.id, { target: { name: 'selectedSequence', value: e.target.checked } })}
													className="checkboxnew" // Apply custom class for styling
												/>
												<label htmlFor={`selectedSequence-${codonRow.id}`} className="block text-gray-700 font-bold ml-2 checkboxnew-label"></label>
											</div>
										</div>
									)}

									{/* Actions */}
									<div className="input-container col-span-1 flex items-center justify-between">
										<button
											type="button"
											onClick={() => handleRemoveCodonOptimizationAdd(currentRowIdAdd, codonRow.id)}
											className="text-red-600 hover:text-red-700 px-1.5 py-0.75 text-sm rounded-full border border-red-600 hover:border-red-700"
										>
											Delete
										</button>
									</div>
								</div>
							))}

						</>


						) : (<>
							{
								editedProductEdit.product_experiments
									.find(exp => exp.id === selectedExperimentEdit.id)?.codon_optimizations &&
								editedProductEdit.product_experiments.find(exp => exp.id === selectedExperimentEdit.id)?.codon_optimizations
									.map((codonRow, index) => (

										<div key={codonRow.id} className="grid grid-cols-6 gap-3 items-start p-2 rounded">

											{/* Tool Field */}
											<div className="input-container col-span-1">
												<Select
													id={`tool - ${codonRow.id}`}
													name={`tool - ${codonRow.id}`}
													value={toolsEdit.find((toolOption) => toolOption.value === codonRow.tool)}
													onChange={(selectedOption) => {

														const updatedExperiments = editedProductEdit.product_experiments.map((exp) => {
															if (exp.id === selectedExperimentEdit.id) {
																let codonOptimizations = exp?.codon_optimizations;

																const updatedCodonOptimizations = codonOptimizations.map((row) => {

																	if (row.id === codonRow.id) {

																		return { ...row, tool: selectedOption.value };
																	}
																	return row;
																});
																return { ...exp, codon_optimizations: updatedCodonOptimizations };
															}
															return exp;
														});
														setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
													}}
													options={toolsEdit}
													required
												/>
											</div>

											{/* CAI Score Field */}
											<div className="input-container col-span-1">
												<input
													type="number"
													id={`CAIScore - ${codonRow.id}`}
													name={`CAIScore - ${codonRow.id}`}
													placeholder="Enter CAI score"
													value={codonRow.caiScore}
													onChange={(e) => {
														const updatedExperiments = editedProductEdit.product_experiments.map((exp) => {
															if (exp.id === selectedExperimentEdit.id) {
																let codonOptimizations = exp?.codon_optimizations;
																const updatedCodonOptimizations = codonOptimizations.map((row) => {
																	if (row.id === codonRow.id) {
																		return { ...row, caiScore: e.target.value };
																	}
																	return row;
																});
																return { ...exp, codon_optimizations: updatedCodonOptimizations };
															}
															return exp;
														});
														setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
													}}
													required
													className="border border-gray-300 p-2 rounded w-full"
												/>
											</div>

											{/* GC% Field */}
											<div className="input-container col-span-1">
												<input
													type="number"
													id={`gcPercentage - ${codonRow.id}`}
													name={`gcPercentage - ${codonRow.id}`}
													placeholder="Enter GC percentage"
													value={codonRow.gcPercentage}
													onChange={(e) => {
														const updatedExperiments = editedProductEdit.product_experiments.map((exp) => {
															if (exp.id === selectedExperimentEdit.id) {
																let codonOptimizations = exp?.codon_optimizations;
																const updatedCodonOptimizations = codonOptimizations.map((row) => {
																	if (row.id === codonRow.id) {
																		return { ...row, gcPercentage: e.target.value };
																	}
																	return row;
																});
																return { ...exp, codon_optimizations: updatedCodonOptimizations };
															}
															return exp;
														});
														setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
													}}
													required
													className="border border-gray-300 p-2 rounded w-full"
												/>
											</div>

											{/* Optimized Sequence Field */}
											<div className="input-container col-span-1">
												<textarea
													className="border border-gray-300 p-2 rounded w-full max-h-36"
													value={codonRow.optimizedSequence}
													onChange={(e) => {
														const updatedExperiments = editedProductEdit.product_experiments.map((exp) => {
															if (exp.id === selectedExperimentEdit.id) {
																let codonOptimizations = exp?.codon_optimizations;
																const updatedCodonOptimizations = codonOptimizations.map((row) => {
																	if (row.id === codonRow.id) {
																		return { ...row, optimizedSequence: e.target.value };
																	}
																	return row;
																});
																return { ...exp, codon_optimizations: updatedCodonOptimizations };
															}
															return exp;
														});
														setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
													}}
													onKeyDown={(e) => {
														// Allow only Ctrl+V (paste), Ctrl+Z (undo), and navigation keys (e.g., arrows, Home, End)
														if (
															(e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'z') || // Allow Ctrl+V and Ctrl+Z
															e.key === 'Backspace' || e.key === 'Delete' || // Allow Backspace and Delete for clearing
															e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || // Allow arrow keys for navigation
															e.key === 'Home' || e.key === 'End' // Allow Home and End for navigation
														) {
															return; // Allow these keys
														} else {
															e.preventDefault(); // Prevent all other typing
														}
													}}
													onPaste={(e) => {
														e.stopPropagation(); // Allow pasting
													}}
													required
													placeholder="Paste optimized sequence"
												/>
											</div>

											{/* Selected Sequence Checkbox */}
											{userIdEdit == editedProductEdit.team_lead && (
												<div className="input-container col-span-1 flex items-center justify-center">
													<input
														type="checkbox"
														id={`selectedSequence - ${codonRow.id}`}
														checked={codonRow.selectedSequence}
														className="checkboxnew" // Apply custom class for styling
														onChange={(e) => {
															const updatedExperiments = editedProductEdit.product_experiments.map((exp) => {
																if (exp.id === selectedExperimentEdit.id) {
																	let codonOptimizations = exp?.codon_optimizations;
																	const updatedCodonOptimizations = codonOptimizations.map((row) => {
																		if (row.id === codonRow.id) {
																			return { ...row, selectedSequence: e.target.checked };
																		}
																		return row;
																	});
																	return { ...exp, codon_optimizations: updatedCodonOptimizations };
																}
																return exp;
															});
															setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
														}}
													/>
													<label htmlFor={`selectedSequence - ${codonRow.id}`} className="ml-2 block text-black-700 font-bold mb-2 checkboxnew-label"></label>
												</div>
											)}

											{/* Action Buttons */}
											<div className="input-container col-span-1 flex items-center justify-between">
												<button
													type="button"
													onClick={() => handleRemoveCodonOptimizationEdit(selectedExperimentEdit.id, codonRow.id)} // Pass the current codon row id
													className="text-red-600 hover:text-red-700 px-1.5 py-0.75 text-sm rounded-full border border-red-600 hover:border-red-700"
												>
													Delete
												</button>
											</div>
										</div>
									))
							}
						</>
						)}

					</div>
					{/* Modal Buttons */}
					<div className="flex justify-end">
						<button
							type="button"
							className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
							onClick={flag === 0 ? handleCloseModalAdd : handleCloseModalEdit}
						>
							Cancel
						</button>
						<button
							type="button"
							onClick={flag === 0 ? handleSaveModalAdd : handleSaveModalEdit}
							className="p-2 w-24 font-bold text-white rounded"
							style={{
								background: '#7FB541',
								borderColor: 'transparent',
							}}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default CodonOptimization;